import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Tag,
  Input,
  Form,
  Switch,
  Space,
  Select,
  Popover,
  Popconfirm,
  InputNumber,
  DatePicker,
  message,
  notification,
  Divider,
} from "antd";
import { EllipsisOutlined, EyeFilled } from "@ant-design/icons";
import Loading from "../../../Common/Loading";
import moment from "moment";
import SearchFunction from "../../../Common/SearchFunction";
import { MdDelete, MdModeEditOutline } from "react-icons/md";
import BaseModal from "../../../Common/BaseModal";
import BaseTable from "../../../Common/BaseTable";
import { add, deleteRecord, update } from "../../../api/Apis";
import { withAppContext } from "../../../Store/AppContext";
import { withRouter } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { BsArrowLeftShort } from "react-icons/bs";
import "../../../Stylesheet/_finance.scss";
import { PDFViewer } from "react-view-pdf";
import {
  TransformWrapper,
  TransformComponent,
  useControls,
} from "react-zoom-pan-pinch";
import { connect } from "react-redux";
import { getTickets } from "../../../Redux/Actions/actions";

const { Option } = Select;
const { TextArea } = Input;
const ticketStatusData = [
  { label: "Data Input", value: "Open" },
  { label: "Pending Review", value: "Review" },
  { label: "Approved", value: "Approved" },
];
class DataInputTickets extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      isModalVisible: false,
      showInvoiceModal: false,
      ticketLoading: false,
      showNotes: false,
      pickupTimeErr: false,
      signCustomer: false,
      ticketDate: "",
      leadsData: "",
      ticketUploaded: "",
      singlePdfTicket: "",
      quantityType: "",
      invoiceDate: "",
      ticketStatus: "",
      showApproved: "",
      showProcesssed: true,
      reviewNotes: "",
      selectedCarrierId: "",
      selectedMaterialId: "",
      pickUpTime: "",

      selectedTickets: [],
      filterData: [],
      totalTicketsData: [],
      ticketIndex: 0,
      minValue: 0,
      currentPage: 1,
      maxValue: 5,
      perpageSize: 5,
      allTruckNumbers: [],
      allTruckTypes: [],
      managerReview: false,
      ticketToggle: true,
      totalTrucksData: [],
      showTruckModal: false,
      showMultpleTrucks: [],
      selectedTruck: "",
      startTime: "",
      startTimeErr: false,
      endTime: "",
      endTimeErr: false,
      loadTicket: false,
      dailySummary: false,
    };
    this.transformRef = React.createRef();
  }

  static getDerivedStateFromProps(props, state) {
    if (
      JSON.stringify(props?.totalTrucksData) !==
      JSON.stringify(state?.totalTrucksData)
    ) {
      return {
        totalTrucksData: props.totalTrucksData,
      };
    }
  }

  componentDidMount = () => {
    document.title = "Twisted Nail - Ticket Processing";
    this.init();
    AOS.init({
      duration: 2000,
    });
  };

  compareLength = (a, b) => {
    return b.length - a.length;
  };

  init = () => {
    let jobInfo = [];
    const groupedData = this.showGrouppedData(this.props.jobInfo);
    Object.values(groupedData)?.forEach((element) => jobInfo.push(element));
    const finalData = jobInfo.sort(this.compareLength)?.flatMap((job) => job);
    this.setState({
      totalTicketsData: finalData,
      filterData: finalData,
      loading: false,
      selectedTickets: [],
    });
  };

  showGrouppedData = (data) => {
    const groupedData = data.reduce((acc, currentValue) => {
      const key =
        currentValue.companyId +
        "-" +
        currentValue.jobId +
        "-" +
        currentValue.material[0].assignTrucks[0];

      (acc[key] = acc[key] || []).push(currentValue);
      return acc;
    }, {});

    return groupedData;
  };

  nextData = (record, currentIndexValue) => {
    record?.map((item, index) => {
      if (index === currentIndexValue) {
        const updatedNewTrucks = [];
        item?.material.map((m) =>
          m?.assignTrucksInfo?.forEach((element) => {
            if (element?.secondTruckNo?.length > 0) {
              updatedNewTrucks.push(element?.secondTruckNo);
            }
            updatedNewTrucks.push(element?.truckId);
          })
        );
        this.setState({
          leadsData: item,
          quantityType: item?.quantityType ? item?.quantityType : "Tons",
          ticketUploaded: item.ticketUploadPicture,
          singlePdfTicket: item.singlePdfTicket,
          ticketIndex: currentIndexValue + 1,
          selectedMaterialId: item?.material[0]?.id,
          selectedCarrierId: item?.selectedCarrierId || "",
          pickupTimeErr: false,
          signCustomer: false,
          allTruckNumbers: updatedNewTrucks?.flat(),
          allTruckTypes: item?.material[0]?.truckType,
          loading: false,
          dailySummary: item?.othersData?.ticketCategory === "dailySummary",
          loadTicket: item?.othersData?.ticketCategory === "loadTicket",
        });

        this.formRef.current.setFieldsValue({
          ticketNumber: item?.ticketNumber ? item?.ticketNumber : "",
          truckNumber: item?.truckNumber ? item?.truckNumber : "",
          quantity: item?.quantity ? item?.quantity : "",
          pickupTime: item?.pickupTime ? item["pickupTime"] : "",
          startTime: item?.othersData?.startTime
            ? item?.othersData?.startTime
            : "",
          endTime: item?.othersData?.endTime ? item?.othersData?.endTime : "",
          pickupVal: item?.pickupVal ? item["pickupVal"] : "",
          ticketDate: item?.ticketDate ? moment(item?.ticketDate) : "",
          ticketVerification: item?.ticketVerification
            ? item?.ticketVerification
            : "",
          ticketPrice: item?.ticketPrice ? item?.ticketPrice : "",
          material: "",
          reviewNotes: item.reviewNotes ? item.reviewNotes : "",
        });
      }
      return true;
    });
  };

  searchResult = (searchValue) => {
    const { totalTicketsData } = this.state;
    let searchFilter = [];
    if (typeof searchValue === "number") {
      searchFilter = totalTicketsData.filter(
        (e) => parseInt(e["jobNumber"]) === searchValue
      );
    } else {
      searchFilter = totalTicketsData.filter(
        (e) => e["companyName"]?.toLowerCase().indexOf(searchValue) > -1
      );
    }
    this.setState({
      filterData: searchFilter,
    });
  };

  onResetData = () => {
    this.init();
  };

  handleChangeTicket = (e) => {
    this.setState({
      ticketStatus: e,
    });
  };

  showModal = (record) => {
    const { totalTicketsData } = this.state;
    let fIndex = totalTicketsData?.findIndex((e) => e.id === record.id);
    const updatedNewTrucks = [];
    record?.material.map((m) =>
      m?.assignTrucksInfo?.forEach((element) => {
        if (record?.secondTruckNo?.length > 0) {
          updatedNewTrucks.push(element?.secondTruckNo);
        }
        updatedNewTrucks.push(element?.truckId);
      })
    );
    const ticketCategory = record?.othersData?.ticketCategory;
    this.setState({
      loading: false,
      isModalVisible: true,
      ticketUploaded: record.ticketUploadPicture,
      singlePdfTicket: record.singlePdfTicket,
      quantityType: record.quantityType,
      leadsData: record,
      ticketIndex: fIndex + 1,
      selectedMaterialId: record?.material[0]?.id,
      reviewNotes: record.reviewNotes || "",
      selectedCarrierId: record?.selectedCarrierId || "",
      allTruckNumbers: updatedNewTrucks?.flat(),
      allTruckTypes: record?.material[0]?.truckType,
      dailySummary: ticketCategory === "dailySummary",
      loadTicket: ticketCategory === "loadTicket",
    });
  };

  handleToDeleteTicket = (record) => {
    this.setState({ loading: true });
    deleteRecord("ticketProcess", record.id)
      .then((deleteRes) => {
        if (deleteRes.success) {
          this.init();
        }
      })
      .catch((error) => {
        console.log("catchEror", error);
        this.setState({ loading: false });
      });
  };

  handleTimeChange = (e, pickUpTime, pickupTimeErr) => {
    let checkData = e?.target.value;
    let value = "";

    if (
      parseInt(checkData.slice(0, 2)) >= 10 &&
      parseInt(checkData.slice(0, 2)) <= 12
    ) {
      value = checkData;
    } else if (parseInt(checkData.slice(0, 2)) >= 13) {
      value = 0 + checkData;
    } else {
      value = checkData;
    }
    let hours = parseInt(value.slice(0, 2)); // extract hours from the input
    let minutes = parseInt(value.slice(2, 4)); // extract minutes from the input
    const type = value.slice(4);
    if (parseInt(value.length) > 5 || minutes >= 60) {
      this.setState({
        [pickupTimeErr]: true,
      });
    } else if (parseInt(value.length) === 3 || parseInt(value.length) >= 5) {
      if (parseInt(value.slice(2, 4)) <= 9) {
        minutes = "0" + minutes;
      }
      // extract minutes from the input
      let formattedTime = "";
      formattedTime =
        type === "a"
          ? hours + ":" + minutes + " AM"
          : hours + ":" + minutes + " PM";
      this.setState({
        [pickUpTime]: formattedTime,
        [pickupTimeErr]: false,
      });
    } else {
      this.setState({ [pickUpTime]: "", [pickupTimeErr]: false });
    }
  };

  handleTableChage = (props, type) => {
    const tableSizeRange =
      JSON.parse(localStorage.getItem("tableSizeRange")) || 0;
    localStorage.setItem(
      "tableSizeRange",
      JSON.stringify({ ...tableSizeRange, [type]: props })
    );
  };

  openNotification = () => {
    const { updatedTruckNumber } = this.state;
    const btn = (
      <div>
        <Button
          style={{
            borderRadius: "5px",
            height: "30px",
            marginRight: "10px",
          }}
          size="small"
          onClick={() => {
            notification.destroy();
            this.setState({ showNotes: true, managerReview: true });
          }}
        >
          Manager Review
        </Button>
        <Button
          style={{
            borderRadius: "5px",
            height: "30px",
          }}
          size="small"
          onClick={() => {
            notification.destroy();
          }}
        >
          Cancel
        </Button>
      </div>
    );
    notification.warning({
      message: `TNB${updatedTruckNumber} is not in the owner operator database. Please verify you have input the correct truck number.`,
      btn,
      placement: "topRight",
    });
  };

  truckNumberValidation = () => {
    const { leadsData, updatedTruckNumber, totalTrucksData } = this.state;

    const findTruckNumber = totalTrucksData?.filter(
      (element) =>
        element.truckId === updatedTruckNumber ||
        (element.secondTruckNo?.length > 0 &&
          element.secondTruckNo.includes(updatedTruckNumber))
    );

    let updatedData = { ...leadsData };

    if (findTruckNumber?.length > 1) {
      this.setState({
        showMultpleTrucks: findTruckNumber,
        showTruckModal: true,
      });
    } else if (updatedTruckNumber && findTruckNumber?.length === 1) {
      const existingTruck = updatedData.material[0].assignTrucksInfo.filter(
        (e) => e.id === findTruckNumber[0]?.id
      );
      if (
        updatedData.material[0].assignTrucksInfo?.length > 0 &&
        existingTruck?.length === 0
      ) {
        updatedData.material[0].assignTrucksInfo.push(findTruckNumber[0]);
      }
      this.setState({
        selectedMaterialId: updatedData.material[0]?.id,
        selectedCarrierId: findTruckNumber[0]?.carrierId,
        showTruckModal: false,
        leadsData: updatedData,
      });
    } else {
      this.openNotification();
      return true;
    }
  };

  handleTruckFinsih = () => {
    const { showMultpleTrucks, leadsData, selectedTruck } = this.state;
    let updatedData = { ...leadsData };
    const selectedItem = showMultpleTrucks.find((e) => e.id === selectedTruck);
    const existingTruck = updatedData.material[0].assignTrucksInfo.filter(
      (e) => e?.id === selectedItem?.id
    );
    if (
      updatedData.material[0].assignTrucksInfo?.length > 0 &&
      existingTruck?.length === 0
    ) {
      updatedData.material[0].assignTrucksInfo.push(selectedItem);
    }

    this.setState({
      selectedCarrierId: selectedItem?.carrierId,
      showTruckModal: false,
      leadsData: updatedData,
      selectedMaterialId: updatedData?.material[0]?.id,
    });
  };

  setTimeStampVal = (defaultTime, userTime, ticketDate) => {
    const timeVal = userTime || defaultTime;
    const [month, day, year] = moment(ticketDate).format("L").split("/") || [];
    const [hour, minute] = timeVal.split(":");
    const isPM = timeVal.toLowerCase().includes("pm");

    // Convert hour to 24-hour format if needed
    const adjustedHour = isPM ? parseInt(hour) + 12 : parseInt(hour);

    return new Date(
      year,
      month - 1,
      day,
      adjustedHour,
      parseInt(minute)
    ).toISOString();
  };

  handleTimestamp = (time, leadsTime, errorKey, ticketDate) => {
    if (time) {
      return this.setTimeStampVal(leadsTime, time, ticketDate);
    } else {
      this.setState({ [`${errorKey}Err`]: true });
      return true;
    }
  };

  validateFields = () => {
    const {
      ticketStatus,
      reviewNotes,
      pickupTimeErr,
      startTimeErr,
      endTimeErr,
      signCustomer,
      leadsData,
      dailySummary,
      loadTicket,
    } = this.state;
    const hourlyJob = leadsData && this.findHourlyJob(leadsData);

    if (leadsData?.ticketVerification === "Open" && !signCustomer) {
      message.error("Ticket is not signed by Customer!");
      return false;
    }
    if (
      hourlyJob &&
      leadsData?.ticketVerification === "Open" &&
      !dailySummary &&
      !loadTicket
    ) {
      message.error("Please enable ticket category!");
      return false;
    }
    if (ticketStatus === "Open" && reviewNotes === "") {
      message.error("Please enter notes!");
      return false;
    }
    if (pickupTimeErr || startTimeErr || endTimeErr) {
      message.error("Please enter valid time");
      return false;
    }
    return true;
  };

  calculateTimeDifferences = (startTimeStamp, endTimeStamp) => {
    const currentTime = new Date(moment(startTimeStamp));
    const nextTime = new Date(moment(endTimeStamp));
    let differenceInSeconds;
    if (nextTime > currentTime) {
      differenceInSeconds = (nextTime - currentTime) / 1000; // Convert milliseconds to seconds
    } else {
      differenceInSeconds = (currentTime - nextTime) / 1000; // Convert milliseconds to seconds
    }

    return differenceInSeconds;
  };

  formatTimeDifference = (differenceInSeconds) => {
    return differenceInSeconds / 60; // convert into mintues;
  };

  onFinish = (values) => {
    let userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const {
      ticketUploaded,
      leadsData,
      selectedMaterialId,
      selectedCarrierId,
      ticketStatus,
      pickUpTime,
      quantityType,
      ticketToggle,
      totalTicketsData,
      startTime,
      endTime,
      dailySummary,
    } = this.state;
    let pickUpTimeStamp = leadsData?.pickUpTimeStamp || "";
    let startTimeStamp = leadsData?.othersData?.startTimeStamp || "";
    let endTimeStamp = leadsData?.othersData?.endTimeStamp || "";
    let calMinutes = leadsData?.othersData?.calMinutes || "";

    if (leadsData?.ticketVerification === "Open") {
      pickUpTimeStamp = this.handleTimestamp(
        dailySummary ? startTime : pickUpTime,
        leadsData?.pickUpTime,
        "pickupTime",
        values["ticketDate"]
      );
      if (pickUpTimeStamp === true) return true; // Early exit on error

      if (dailySummary) {
        startTimeStamp = this.handleTimestamp(
          startTime,
          leadsData?.othersData?.startTime,
          "startTime",
          values["ticketDate"]
        );
        if (startTimeStamp === true) return true; // Early exit on error

        endTimeStamp = this.handleTimestamp(
          endTime,
          leadsData?.othersData?.endTime,
          "endTime",
          values["ticketDate"]
        );
        calMinutes = this.formatTimeDifference(
          this.calculateTimeDifferences(startTimeStamp, endTimeStamp)
        );
        if (endTimeStamp === true) return true; // Early exit on error
      }
    }
    let data = {
      entityData: {},
    };
    if (!this.validateFields()) return;
    if (selectedMaterialId !== "" && selectedCarrierId !== "") {
      this.setState({
        loading: true,
      });
      if (
        leadsData.selectedMaterial?.length > 0 &&
        JSON.stringify(leadsData.selectedMaterial[0].assignTrucksInfo) !==
          JSON.stringify(leadsData?.material[0]["assignTrucksInfo"])
      ) {
        leadsData.selectedMaterial[0].assignTrucksInfo =
          leadsData?.material[0]["assignTrucksInfo"];
      }
      try {
        values["ticketDate"] = values["ticketDate"]
          ? moment(values["ticketDate"]).format("L")
          : "";
        values["quantityType"] = quantityType || leadsData.quantityType;
        values["pickupTime"] = leadsData?.pickupTime
          ? leadsData?.pickupTime
          : dailySummary
          ? startTime
          : pickUpTime;
        values["pickupVal"] = values["pickupVal"] || leadsData?.pickupVal;
        values["pickUpTimeStamp"] = pickUpTimeStamp;
        values["othersData"] = {
          ...leadsData?.othersData,
          startTime: leadsData?.othersData?.startTime || startTime,
          startTimeStamp: startTimeStamp || leadsData?.others?.startTimeStamp,
          endTime: leadsData?.othersData?.endTime || endTime,
          endTimeStamp: endTimeStamp || leadsData?.others?.endTimeStamp,
          ticketCategory: dailySummary ? "dailySummary" : "loadTicket",
          calMinutes,
        };
        values["material"] = leadsData?.material;
        values["selectedMaterial"] =
          leadsData.selectedMaterial?.length > 0
            ? leadsData.selectedMaterial
            : leadsData?.material?.filter((e) => e.id === selectedMaterialId);
        values["selectedCarrierId"] =
          leadsData["selectedCarrierId"] || selectedCarrierId;

        values["totalPrice"] =
          values["selectedMaterial"]?.length > 0
            ? values["selectedMaterial"][0]["landedPrice"] !== "N/A"
              ? values["selectedMaterial"][0]["landedPrice"]
              : leadsData?.material[0]?.totalPrice +
                leadsData?.material[0]?.truckingPrice
            : 0;

        values["ticketVerification"] =
          ticketStatus !== ""
            ? ticketStatus
            : leadsData["ticketVerification"] === "Open"
            ? "Review"
            : leadsData["ticketVerification"] === "Review"
            ? "Approved"
            : leadsData["ticketVerification"];

        if (leadsData["ticketVerification"] === "Review") {
          values["ticketDate"] = leadsData?.ticketDate;
        }
        if (ticketUploaded !== "" && leadsData.id !== "") {
          values["updatedBy"] = userDetails?.id;
          data["entityData"] = values;
          if (
            (leadsData?.ticketVerification === "Open" &&
              userDetails?.userProfile === "Admin" &&
              !ticketToggle) ||
            (leadsData?.ticketVerification === "Open" &&
              userDetails?.userProfile !== "Admin")
          ) {
            data["entityData"]["dataInputUsers"] = userDetails?.id;
            data["entityData"]["ticketId"] = leadsData.id;
            add("dataProcess1", data).then((updateRes) => {
              let result = updateRes.data.data;
              let indexTicket;
              totalTicketsData?.map((item, index) => {
                if (item.id === leadsData.id) {
                  item["dataInputUsers"] = result["dataInputUsers"];
                  indexTicket = index;
                }
                return true;
              });

              if (updateRes.success) {
                this.setState(
                  {
                    selectedMaterialId: "",
                    reviewNotes: "",
                    ticketStatus: "",
                    pickUpTime: "",
                    signCustomer: false,
                  },
                  () => {
                    if (indexTicket === totalTicketsData?.length) {
                      this.setState({
                        isModalVisible: false,
                        loading: false,
                      });
                      message.info("There are no more tickets to review!");
                      return true;
                    } else {
                      this.nextData(totalTicketsData, indexTicket + 1);
                    }
                  }
                );
              }
            });
          } else {
            update("ticketProcess", leadsData.id, data).then((updateRes) => {
              let result = updateRes.data.data;
              let indexTicket;
              totalTicketsData?.map((item, index) => {
                if (item.id === result.id) {
                  item["ticketDate"] = result["ticketDate"];
                  item["pickupVal"] = result["pickupVal"];
                  item["pickupTime"] = result["pickupTime"];
                  item["ticketVerification"] = result["ticketVerification"];
                  item["ticketNumber"] = result["ticketNumber"];
                  item["selectedMaterial"] = result["selectedMaterial"];
                  item["truckNumber"] = result["truckNumber"];
                  item["quantity"] = result["quantity"];
                  item["reviewNotes"] = result["reviewNotes"];
                  indexTicket = index;
                }
                return true;
              });

              const ticketData = totalTicketsData?.filter(
                (e) => e.ticketVerification === "Open"
              );

              if (updateRes.success) {
                this.setState(
                  {
                    totalTicketsData: ticketData,
                    filterData: ticketData,
                    selectedMaterialId: "",
                    reviewNotes: "",
                    ticketStatus: "",
                    pickUpTime: "",
                    signCustomer: false,
                  },
                  () => {
                    if (indexTicket === ticketData?.length) {
                      this.setState({
                        isModalVisible: false,
                        loading: false,
                      });
                      message.info("There are no more tickets to review!");
                      return true;
                    } else {
                      this.nextData(ticketData, indexTicket);
                    }
                  }
                );
              }
            });
          }
        }
      } catch (error) {
        console.log("catchError", error);
      }
    } else {
      this.truckNumberValidation();
      return true;
    }
  };

  onReject = (values, jobData) => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const { leadsData } = this.state;
    let data = {
      entityData: {
        uploadStatus: "Open",
        updatedBy: userDetails?.id,
        ticketNotes: values["ticketNotes"],
      },
    };

    update("ticketProcess", leadsData.id, data).then((updateRes) => {
      let result = updateRes.data.data;
      Object.keys(jobData).map((e) => {
        if (e === result.orderId) {
          Object.values(jobData[result.orderId]).map((item, index) => {
            if (item.id === result.id) {
              item["ticketDate"] = result["ticketDate"];
              item["pickupVal"] = result["pickupVal"];
              item["pickupTime"] = result["pickupTime"];
              item["ticketVerification"] = result["ticketVerification"];
              item["ticketNumber"] = result["ticketNumber"];
              item["selectedMaterial"] = result["selectedMaterial"];
              item["truckNumber"] = result["truckNumber"];
              item["quantity"] = result["quantity"];
              item["reviewNotes"] = result["reviewNotes"];
              item["uploadStatus"] = result["uploadStatus"];
            }
            return true;
          });
        }
        return true;
      });

      if (updateRes.success) {
        this.setState({
          loading: false,
          selectedMaterialId: "",
          reviewNotes: "",
          ticketStatus: "",
          pickUpTime: "",
          showNotes: false,
          isModalVisible: false,
        });
      }
    });
  };

  findHourlyJob = (leadsData) => {
    if (
      leadsData?.material[0]?.truckingCostType === "Hour" ||
        leadsData.material[0]?.truckingPriceType === "Hour"
    ) {
      return true;
    }
    return false;
  };

  render() {
    const {
      showTruckModal,
      showMultpleTrucks,
      ticketToggle,
      showNotes,
      ticketIndex,
      pickUpTime,
      loading,
      leadsData,
      isModalVisible,
      ticketUploaded,
      singlePdfTicket,
      ticketLoading,
      filterData,
      reviewNotes,
      ticketStatus,
      quantityType,
      totalTicketsData,
      signCustomer,
      allTruckNumbers,
      allTruckTypes,
      managerReview,
      selectedRowKeys,
      pickupTime,
      startTime,
      pickupTimeErr,
      startTimeErr,
      endTime,
      endTimeErr,
      dailySummary,
      loadTicket,
    } = this.state;

    const hourlyJob = leadsData && this.findHourlyJob(leadsData);
    const disabledBtn = hourlyJob
      ? !signCustomer || (!loadTicket && !dailySummary)
      : false;

    const rowSelection = {
      selectedRowKeys,
      onChange: (e) => this.setState({ selectedTruck: e[0] }),
    };

    const equipmentColumns = [
      {
        title: "Primary Truck#",
        dataIndex: "truckId",
        key: "truckId",
        sorter: false,
        className: "col_style_quarry",
        fixed: "left",
        onCell: (record) => ({
          onClick: () =>
            this.setState({
              selectedTruck: record.id,
              selectedRowKeys: [record.id],
            }),
        }),
        render: (truckId) => {
          return <span className="col_style_quarry">{truckId}</span>;
        },
      },
      {
        title: "Secondary Truck#",
        dataIndex: "secondTruckNo",
        key: "secondTruckNo",
        sorter: false,
        className: "col_style_quarry",
        onCell: (record) => ({
          onClick: () =>
            this.setState({
              selectedTruck: record.id,
              selectedRowKeys: [record.id],
            }),
        }),
        render: (secondTruckNo, record) => {
          return (
            <div className="col_style_quarry">
              {record?.secondTruckNo?.length > 0
                ? record?.secondTruckNo.join(", ")
                : secondTruckNo}
            </div>
          );
        },
      },
      {
        title: "Type",
        dataIndex: "truck_type",
        key: "truck_type",
        sorter: false,
        className: "col_style_quarry",
        onCell: (record) => ({
          onClick: () =>
            this.setState({
              selectedTruck: record.id,
              selectedRowKeys: [record.id],
            }),
        }),
        render: (truck_type, record) => {
          return (
            <Row
              style={{
                display: "flex",
                alignItems: "center",
                minWidth: "fit-content",
              }}
            >
              <span className="col_style_quarry">{truck_type}</span>
            </Row>
          );
        },
      },
      {
        title: "Location",
        dataIndex: "parking_location",
        key: "parking_location",
        sorter: false,
        className: "col_style_quarry",
        onCell: (record) => ({
          onClick: () =>
            this.setState({
              selectedTruck: record.id,
              selectedRowKeys: [record.id],
            }),
        }),
        render: (parking_location) => {
          return <span className="col_style_quarry">{parking_location}</span>;
        },
      },
      {
        title: "Dispatcher",
        dataIndex: "dispatcher",
        key: "dispatcher",
        sorter: false,
        className: "col_style_quarry",
        onCell: (record) => ({
          onClick: () =>
            this.setState({
              selectedTruck: record.id,
              selectedRowKeys: [record.id],
            }),
        }),
        render: (dispatcher) => {
          return <span className="col_style_quarry">{dispatcher}</span>;
        },
      },
    ];

    const Controls = () => {
      const { resetTransform } = useControls();

      return (
        <div className="tools">
          {/* <span class="custom-button" onClick={() => zoomIn()}>
            Zoom In
          </span>
          <span class="custom-button1" onClick={() => zoomOut()}>
            Zoom Out
          </span> */}
          <span class="custom-button2" onClick={() => resetTransform()}>
            Reset
          </span>
        </div>
      );
    };

    const { trucksTypesData } = this.props;
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const { userRoleAccess } = this.props.context;
    const roleData = userRoleAccess?.otherData?.filter(
      (e) => e.title === "Finance"
    );
    let loginType;
    const finaceInfo =
      roleData &&
      roleData[0]?.multiPannel?.filter((m) => m.title === "Ticket Processing");
    let tableData = filterData;
    if (finaceInfo && finaceInfo[0]?.selectedOptions?.length > 0) {
      if (finaceInfo && finaceInfo[0]?.selectedOptions?.includes("Edit")) {
        if (userDetails?.userProfile === "Admin") {
          loginType = "Admin";
          if (!ticketToggle) {
            tableData = tableData?.filter((element) => {
              if (element["dataInputUsers"]?.length > 0) {
                return (
                  element["ticketVerification"] === "Open" &&
                  !element["dataInputUsers"].includes(userDetails?.id)
                );
              } else {
                return element;
              }
            });
          }
        }
      } else if (
        finaceInfo &&
        finaceInfo[0]?.selectedOptions?.includes("Review")
      ) {
        tableData = filterData;
      } else if (
        finaceInfo &&
        finaceInfo[0]?.selectedOptions?.includes("Data Input")
      ) {
        tableData = tableData?.filter((element) => {
          if (element["dataInputUsers"]?.length > 0) {
            return (
              element["ticketVerification"] === "Open" &&
              !element["dataInputUsers"].includes(userDetails?.id)
            );
          } else {
            return element["ticketVerification"] === "Open";
          }
        });
      }
    }

    const columns = [
      {
        title: "UPLOAD DATE",
        dataIndex: "uploadDate",
        key: "uploadDate",
        sorter: false,
        showTable: true,
        className: "col_styling",
        render: (uploadDate) => {
          return (
            <span
              className="col_styling table-font-mobile"
              style={{
                textTransform: "capitalize",
                width: "10px",
              }}
            >
              <Row style={{ color: "#383838" }}>
                {moment(uploadDate).format("l")}
              </Row>
            </span>
          );
        },
      },
      {
        title: "JOB #",
        dataIndex: "jobNumber",
        key: "jobNumber",
        sorter: false,
        showTable: true,
        className: "col_styling",
        render: (jobNumber) => {
          return (
            <span
              className="col_styling table-font-mobile"
              style={{
                textTransform: "capitalize",
                width: "10px",
              }}
            >
              <Row style={{ color: "#383838" }}>{jobNumber}</Row>
            </span>
          );
        },
      },
      {
        title: "JOB NAME",
        dataIndex: "jobName",
        key: "jobName",
        sorter: false,
        showTable: true,
        className: "col_styling",
        render: (jobName) => {
          return (
            <span
              className="col_styling table-font-mobile"
              style={{
                textTransform: "capitalize",
                width: "10px",
              }}
            >
              <Row style={{ color: "#383838" }}>{jobName}</Row>
            </span>
          );
        },
      },
      {
        title: "VERIFICATION",
        dataIndex: "ticketVerification",
        key: "ticketVerification",
        sorter: false,
        className: "col_styling",
        showTable: true,
        render: (tag2, record) => {
          return (
            <Tag
              style={{
                background: "transparent",
                border: "none",
                height: "40px",
                fontSize: "16px",
                borderRadius: "5px",
                padding: "0px",
                color: "#0058e0",
              }}
              className="col_styling table-font-mobile "
              key={tag2}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  height: "40px",
                }}
              >
                <Button
                  type="button"
                  onClick={() => this.showModal(record)}
                  style={{
                    height: "40px",
                    backgroundColor: "#f78d8d",
                    borderRadius: "5px",
                    border: "none",
                    cursor: "pointer",
                    width: "fit-content",
                    color: "#ffffff",
                  }}
                  className="ticket-processing-tags-button"
                >
                  <span
                    style={{
                      fontWeight: "600",
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "16px",
                    }}
                  >
                    {`${
                      record?.dataInputUsers?.length > 0
                        ? record?.dataInputUsers?.length
                        : 0
                    }/${this.props.ticketVerifiedCount} - Data Input`}
                  </span>
                </Button>
              </div>
            </Tag>
          );
        },
      },
      {
        title: "ACTION",
        dataIndex: "id",
        key: "",
        sorter: false,
        // width: "75px",
        fixed: "right",
        showTable: true,
        render: (action, record) => {
          return (
            <>
              <Col>
                <Popover
                  placement="bottom"
                  content={
                    <>
                      {record.ticketVerification === "Invoiced" ? (
                        <Row className="popovergrid">
                          <Col span={24}>
                            <Button
                              style={{
                                fontSize: "15px",
                                cursor: "pointer",
                                border: "none",
                                padding: "0px",
                                textAlign: "left",
                                width: "100%",
                                height: "auto",
                              }}
                              onClick={() => this.showModal(record)}
                            >
                              <span
                                style={{
                                  color: "black",
                                  display: "flex",
                                  flexWrap: "wrap",
                                  alignItems: "center",
                                  justifyContent: "left",
                                }}
                              >
                                <EyeFilled style={{ marginRight: "5px" }} />
                                View
                              </span>
                            </Button>
                          </Col>
                        </Row>
                      ) : (
                        <Row className="popovergrid">
                          <Col span={24}>
                            <Button
                              style={{
                                fontSize: "15px",
                                cursor: "pointer",
                                border: "none",
                                padding: "0px",
                                textAlign: "left",
                                width: "100%",
                                height: "auto",
                              }}
                              onClick={() => this.showModal(record)}
                            >
                              <span
                                style={{
                                  color: "black",
                                  display: "flex",
                                  flexWrap: "wrap",
                                  alignItems: "center",
                                  justifyContent: "left",
                                }}
                              >
                                <MdModeEditOutline
                                  style={{ marginRight: "5px" }}
                                />
                                Edit
                              </span>
                            </Button>
                          </Col>
                          <Col span={24}>
                            <Button
                              style={{
                                fontSize: "15px",
                                cursor: "pointer",
                                border: "none",
                                padding: "0px",
                                textAlign: "left",
                                width: "100%",
                                height: "auto",
                              }}
                            >
                              <Popconfirm
                                title="Are you sure？"
                                okText="Yes"
                                cancelText="No"
                                onConfirm={() =>
                                  this.handleToDeleteTicket(record)
                                }
                              >
                                <span
                                  style={{
                                    color: "black",
                                    display: "flex",
                                    flexWrap: "wrap",
                                    alignItems: "center",
                                    justifyContent: "left",
                                  }}
                                >
                                  <MdDelete style={{ marginRight: "5px" }} />{" "}
                                  Delete
                                </span>
                              </Popconfirm>
                            </Button>
                          </Col>
                        </Row>
                      )}
                    </>
                  }
                >
                  <EllipsisOutlined
                    style={{ fontSize: "35px", color: "grey" }}
                    className="action-ellipsis-button"
                  />
                </Popover>
              </Col>
            </>
          );
        },
      },
    ].filter((e) => e.showTable === true);

    const selectAfter = (val) => (
      <Select
        value={val || quantityType}
        className="select-after"
        onChange={(e) => {
          this.setState({ quantityType: e });
        }}
        disabled={hourlyJob}
      >
        <Option value="Tons">Tons</Option>
        <Option value="Loads">Loads</Option>
        {/* <Option value="Hours">Hours</Option> */}
      </Select>
    );
    return (
      <div
        style={{
          background: "#fafafa",
        }}
      >
        <Loading enableLoading={loading} />
        <Row style={{ marginBottom: "1vw" }}>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 14 }}
            xl={{ span: 14 }}
            xxl={{ span: 14 }}
          >
            <Row>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 10 }}
                xl={{ span: 10 }}
                xxl={{ span: 10 }}
                // span={10}
                className="quarrytoolgrid"
              >
                <SearchFunction
                  tableName={"ticketProcess"}
                  getSearchData={this.searchResult}
                  onResetData={this.onResetData}
                />
              </Col>
            </Row>
          </Col>
          {loginType === "Admin" && (
            <Col
              s={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 10 }}
              xl={{ span: 10 }}
              xxl={{ span: 10 }}
              // span={12}
            >
              <Row style={{ justifyContent: "right" }}>
                <Col className="gridbtntool">
                  <span
                    style={{
                      fontSize: "18px",
                      color: "#747474",
                      fontWeight: "600",
                      marginRight: "5px",
                    }}
                  >
                    Admin Mode
                  </span>
                  <Switch
                    checked={ticketToggle}
                    checkedChildren={"ON"}
                    unCheckedChildren={"OFF"}
                    onChange={(e) =>
                      this.setState({
                        ticketToggle: e,
                      })
                    }
                  />
                </Col>
              </Row>
            </Col>
          )}
        </Row>

        <Row
          onClick={() => this.props.handleCancel()}
          style={{ cursor: "pointer" }}
        >
          <BsArrowLeftShort
            style={{ color: "#5f6c74", fontSize: "25px" }}
            className="back-button-return-icon"
          />
          <p
            style={{ color: "#808080", fontSize: "16px", fontWeight: "600" }}
            className="back-button-return"
          >
            Return
          </p>
        </Row>
        <Col
          xxl={{ span: 24 }}
          xl={{ span: 24 }}
          lg={{ span: 24 }}
          md={{ span: 24 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <Card bodyStyle={{ padding: "0px" }} data-aos="fade-up">
            <BaseTable
              className="financeticket_table table_laptopscreen"
              columnsData={columns}
              source={tableData}
              total={tableData?.length}
              rowKey={(record) => record.id}
              handleTableChage={(e) =>
                this.handleTableChage(e, "ticketProcessing")
              }
              sizeRange={
                window.screen.width > 1023
                  ? JSON.parse(localStorage.getItem("tableSizeRange"))
                      ?.ticketProcessing
                  : tableData?.length
              }
              pagination={window.screen.width > 1023 ? true : false}
            />
          </Card>
        </Col>

        {showTruckModal && (
          <BaseModal
            className="tickectfinan_modal antmodal_grid headermodal"
            title={"Trucks"}
            onCancel={() =>
              this.setState({
                showTruckModal: false,
              })
            }
            formId="trucksData"
            loading={loading}
            width={
              window.screen.width > "501" && window.screen.width <= "991"
                ? "80%"
                : "850px"
            }
          >
            <Form onFinish={this.handleTruckFinsih} id="trucksData">
              <BaseTable
                columnsData={equipmentColumns}
                source={showMultpleTrucks}
                total={showMultpleTrucks?.length}
                className="table_laptopscreen"
                rowSelection={{
                  type: "radio",
                  ...rowSelection,
                }}
                handleTableChage={(e) =>
                  this.handleTableChage(e, "multipleTrucks")
                }
                sizeRange={
                  window.screen.width > 1023
                    ? JSON.parse(localStorage.getItem("tableSizeRange"))
                        ?.multipleTrucks
                    : showMultpleTrucks?.length
                }
                pagination={window.screen.width > 1023 ? true : false}
                rowKey={(record) => record?.id}
              />
            </Form>
          </BaseModal>
        )}

        {isModalVisible && (
          <BaseModal
            className="tickectfinan_modal antmodal_grid headermodal remove_border"
            title={
              leadsData?.ticketVerification === "Invoiced"
                ? `View Details ${ticketIndex} / ${totalTicketsData?.length}`
                : `Edit Details ${ticketIndex} / ${totalTicketsData?.length}`
            }
            onCancel={() =>
              this.setState({
                isModalVisible: false,
                ticketUploaded: "",
                percentage: 0,
                ticketStatus: "",
                signCustomer: false,
              })
            }
            formId="ticketForm"
            loading={loading}
            submitButton={
              leadsData?.ticketVerification === "Open"
                ? "Submit for review"
                : leadsData?.ticketVerification === "Review"
                ? "Approve"
                : "Next"
            }
            onReject={() => {
              this.setState({
                showNotes: true,
              });
            }}
            rejectButton={
              leadsData["ticketVerification"] === "Open" ? true : false
            }
            rejectBtn={"Reject"}
            width={
              window.screen.width > "501" && window.screen.width <= "991"
                ? "80%"
                : "850px"
            }
            // btnWidth="150px"
            btnHeight="40px"
            disabled={ticketLoading ? true : false}
            bottomStyle
          >
            <Row
              style={{
                position: "relative",
                zIndex: 999,
                backgroundColor: "#fff",
              }}
            >
              <Col span={24}>
                {leadsData?.ticketVerification === "Review" && (
                  <>
                    <span className="title_changes">STATUS:</span>
                    <Select
                      className="select_Height select_ticket"
                      showSearch
                      placeholder="Select to Status"
                      allowClear
                      value={
                        ticketStatus
                          ? ticketStatus
                          : leadsData?.ticketVerification
                      }
                      bordered={false}
                      onSelect={this.handleChangeTicket}
                      style={{ width: "220px" }}
                    >
                      {ticketStatusData.map((t) => (
                        <Select.Option value={t.value}>
                          <span className="select_option">{t.label}</span>
                        </Select.Option>
                      ))}
                    </Select>
                  </>
                )}
              </Col>
            </Row>

            <Form
              id="ticketForm"
              onFinish={(e) => this.onFinish(e, "jobInfo")}
              ref={this.formRef}
            >
              {singlePdfTicket !== "" && (
                <Col
                  xxl={{ span: 24, offset: 0 }}
                  xl={{ span: 24, offset: 0 }}
                  lg={{ span: 24, offset: 0 }}
                  md={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  xs={{ span: 24, offset: 0 }}
                  className="pdfviewcls"
                >
                  <TransformWrapper
                    initialScale={1}
                    initialPositionX={1}
                    initialPositionY={1}
                  >
                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                      <React.Fragment>
                        <Controls />
                        <TransformComponent>
                          <PDFViewer
                            url={`https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/${singlePdfTicket}?#view=FitH`}
                            viewer="url"
                            style={{
                              width: "100%",
                              height: "400px",
                              overflow: "auto",
                            }}
                            onError={(error) => {
                              console.error("Error loading PDF:", error);
                            }}
                          />
                        </TransformComponent>
                      </React.Fragment>
                    )}
                  </TransformWrapper>
                </Col>
              )}
              <div className="bordered-class"  style={{marginBottom: '-70px'}}>
                {ticketUploaded && leadsData?.ticketVerification === "Open" && (
                  <>
                    <Col
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignContent: "center",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          alignContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <b> Signed By Customer</b>
                        <Space direction="vertical">
                          <Switch
                            checked={signCustomer}
                            onChange={(e) =>
                              this.setState({
                                signCustomer: e,
                              })
                            }
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                          />
                        </Space>
                      </div>
                      {hourlyJob && (
                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            alignContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <b>Daily Summary</b>
                          <Space direction="vertical">
                            <Switch
                              checked={dailySummary}
                              onChange={(e) =>
                                this.setState({
                                  dailySummary: e,
                                  quantityType: e ? "Loads" : "Tons",
                                  loadTicket: !e,
                                })
                              }
                              checkedChildren="Yes"
                              unCheckedChildren="No"
                            />
                          </Space>

                          <b>Load Ticket</b>
                          <Space direction="vertical">
                            <Switch
                              checked={loadTicket}
                              onChange={(e) => {
                                console.log(e, "e");
                                this.setState({
                                  loadTicket: e,
                                  dailySummary: !e,
                                  quantityType: e ? "Tons" : "Loads",
                                });
                              }}
                              checkedChildren="Yes"
                              unCheckedChildren="No"
                            />
                          </Space>
                        </div>
                      )}
                    </Col>

                    <Row gutter={[24, 8]} style={{ marginTop: "1vw" }}>
                      <Col
                        xxl={{ span: dailySummary ? 8 : 12, offset: 0 }}
                        xl={{ span: dailySummary ? 8 : 12, offset: 0 }}
                        lg={{ span: dailySummary ? 8 : 12, offset: 0 }}
                        md={{ span: dailySummary ? 8 : 12, offset: 0 }}
                        sm={{ span: 24, offset: 0 }}
                        xs={{ span: 24, offset: 0 }}
                      >
                        <b>Ticket Date</b>

                        <Form.Item
                          name="ticketDate"
                          rules={[
                            {
                              required: true,
                              message: "Please input your Date!",
                            },
                          ]}
                          initialValue={
                            leadsData?.ticketDate &&
                            moment(leadsData?.ticketDate)
                          }
                          style={{ marginBottom: "0px" }}
                        >
                          <DatePicker
                            className="form-input"
                            style={{ width: "100%" }}
                            placeholder="Select Date"
                            format={"M/D/YYYY"}
                            disabled={disabledBtn}
                          />
                        </Form.Item>
                      </Col>

                      {!dailySummary && (
                        <Col
                          xxl={{ span: 12, offset: 0 }}
                          xl={{ span: 12, offset: 0 }}
                          lg={{ span: 12, offset: 0 }}
                          md={{ span: 12, offset: 0 }}
                          sm={{ span: 24, offset: 0 }}
                          xs={{ span: 24, offset: 0 }}
                        >
                          <b style={{ fontSize: "14px" }}>
                            Load Time
                            {pickUpTime !== "Invalid Date" && pickUpTime !== ""
                              ? " (" + pickUpTime + ")"
                              : leadsData?.pickupTime
                              ? " (" + leadsData?.pickupTime + ")"
                              : ""}{" "}
                            <span>(Ex: 1005a for 10:05 AM)</span>
                          </b>
                          <br />
                          <Form.Item
                            name="pickupVal"
                            rules={[
                              {
                                required: true,
                                message: "Please input your Load Time!",
                              },
                            ]}
                            initialValue={pickupTime || leadsData?.pickupTime}
                            style={{ marginBottom: "0px" }}
                          >
                            <Input
                              className="form-input"
                              placeholder="Please enter Time"
                              onChange={(e) =>
                                this.handleTimeChange(
                                  e,
                                  "pickUpTime",
                                  "pickupTimeErr"
                                )
                              }
                              disabled={disabledBtn}
                            />
                          </Form.Item>
                          {pickupTimeErr && (
                            <span style={{ color: "red" }}>
                              Please input valid load time!
                            </span>
                          )}
                        </Col>
                      )}

                      {dailySummary && (
                        <>
                          <Col
                            xxl={{ span: 8, offset: 0 }}
                            xl={{ span: 8, offset: 0 }}
                            lg={{ span: 8, offset: 0 }}
                            md={{ span: 8, offset: 0 }}
                            sm={{ span: 24, offset: 0 }}
                            xs={{ span: 24, offset: 0 }}
                          >
                            <b style={{ fontSize: "14px" }}>
                              Start Time
                              {startTime !== "Invalid Date" && startTime !== ""
                                ? " (" + startTime + ")"
                                : leadsData?.othersData?.startTime
                                ? " (" + leadsData?.othersData?.startTime + ")"
                                : ""}{" "}
                              {(startTime === "Invalid Date" ||
                                startTime === "" ||
                                leadsData?.othersData?.startTime === "") && (
                                <span style={{ fontSize: "12px" }}>
                                  (Ex: 1005a for 10:05 AM)
                                </span>
                              )}
                            </b>
                            <Form.Item
                              name="startTime"
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your Start Time!",
                                },
                              ]}
                              initialValue={
                                startTime || leadsData?.othersData?.startTime
                              }
                              style={{ marginBottom: "0px" }}
                            >
                              <Input
                                className="form-input"
                                placeholder="Please enter Time"
                                onChange={(e) =>
                                  this.handleTimeChange(
                                    e,
                                    "startTime",
                                    "startTimeErr"
                                  )
                                }
                                disabled={disabledBtn}
                              />
                            </Form.Item>
                            {startTimeErr && (
                              <span style={{ color: "red" }}>
                                Please input valid start time!
                              </span>
                            )}
                          </Col>
                          <Col
                            xxl={{ span: 8, offset: 0 }}
                            xl={{ span: 8, offset: 0 }}
                            lg={{ span: 8, offset: 0 }}
                            md={{ span: 8, offset: 0 }}
                            sm={{ span: 24, offset: 0 }}
                            xs={{ span: 24, offset: 0 }}
                          >
                            <b style={{ fontSize: "14px" }}>
                              End Time
                              {endTime !== "Invalid Date" && endTime !== ""
                                ? " (" + endTime + ")"
                                : leadsData?.othersData?.endTime
                                ? " (" + leadsData?.othersData?.endTime + ")"
                                : ""}{" "}
                              {(endTime === "Invalid Date" ||
                                endTime === "" ||
                                leadsData?.othersData?.endTime === "") && (
                                <span style={{ fontSize: "12px" }}>
                                  (Ex: 1005a for 10:05 AM)
                                </span>
                              )}
                            </b>
                            <br />
                            <Form.Item
                              name="endTime"
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your End Time!",
                                },
                              ]}
                              initialValue={
                                endTime || leadsData?.othersData?.endTime
                              }
                              style={{ marginBottom: "0px" }}
                            >
                              <Input
                                className="form-input"
                                placeholder="Please enter Time"
                                onChange={(e) =>
                                  this.handleTimeChange(
                                    e,
                                    "endTime",
                                    "endTimeErr"
                                  )
                                }
                                disabled={disabledBtn}
                              />
                            </Form.Item>
                            {endTimeErr && (
                              <span style={{ color: "red" }}>
                                Please input valid end time!
                              </span>
                            )}
                          </Col>
                        </>
                      )}

                      <Col
                        xxl={{ span: 8, offset: 0 }}
                        xl={{ span: 8, offset: 0 }}
                        lg={{ span: 8, offset: 0 }}
                        md={{ span: 12, offset: 0 }}
                        sm={{ span: 24, offset: 0 }}
                        xs={{ span: 24, offset: 0 }}
                      >
                        <b>Ticket Number</b>
                        <Form.Item
                          name="ticketNumber"
                          rules={[
                            {
                              required: true,
                              message: "Please enter Ticket Number!",
                            },
                          ]}
                          initialValue={leadsData?.ticketNumber}
                          style={{ marginBottom: "0px" }}
                        >
                          <Input
                            className="form-input"
                            placeholder="Please enter ticket number"
                            disabled={disabledBtn}
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        xxl={{ span: 8, offset: 0 }}
                        xl={{ span: 8, offset: 0 }}
                        lg={{ span: 8, offset: 0 }}
                        md={{ span: 12, offset: 0 }}
                        sm={{ span: 24, offset: 0 }}
                        xs={{ span: 24, offset: 0 }}
                      >
                        <b>Truck Number</b>
                        <Form.Item
                          name="truckNumber"
                          rules={[
                            {
                              required: true,
                              message: "Please Select Truck Number!",
                            },
                          ]}
                          style={{ marginBottom: "0px" }}
                          initialValue={
                            leadsData?.truckNumber ? leadsData?.truckNumber : ""
                          }
                        >
                          <Input
                            type="number"
                            onChange={(e) => {
                              this.setState({
                                updatedTruckNumber: Number(e.target.value),
                                selectedMaterialId: "",
                                selectedCarrierId: "",
                              });
                            }}
                            placeholder="Please Select Truck number"
                            style={{
                              width: "100%",
                            }}
                            className="form-input"
                            onBlur={this.truckNumberValidation}
                            disabled={disabledBtn}
                          />
                        </Form.Item>
                      </Col>

                      <Col
                        xxl={{ span: 8, offset: 0 }}
                        xl={{ span: 8, offset: 0 }}
                        lg={{ span: 8, offset: 0 }}
                        md={{ span: 12, offset: 0 }}
                        sm={{ span: 24, offset: 0 }}
                        xs={{ span: 24, offset: 0 }}
                      >
                        <b>{dailySummary ? "Load Count" : "Quantity"}</b>

                        <Form.Item
                          name="quantity"
                          rules={[
                            {
                              required: !dailySummary,
                              message: "Please Enter Quantity",
                            },
                          ]}
                          initialValue={
                            leadsData?.quantity ? leadsData?.quantity : ""
                          }
                          style={{ marginBottom: "0px" }}
                        >
                          <InputNumber
                            placeholder="Please Enter Quantity"
                            addonAfter={selectAfter(
                              dailySummary ? "Loads" : "Tons"
                            )}
                            style={{ height: "40px", width: "100%" }}
                            disabled={disabledBtn}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                )}

                {!this.props.managerReview &&
                  (leadsData?.reviewNotes ||
                    (ticketUploaded &&
                      leadsData?.ticketVerification !== "Open")) && (
                    <Col
                      span={24}
                      style={{ marginBottom: "0.5vw", marginTop: "0.5vw" }}
                    >
                      <b>Review Notes:</b>

                      <Form.Item
                        name="reviewNotes"
                        rules={[
                          {
                            required: false,
                            message: "Please Enter Review Notes",
                          },
                        ]}
                        initialValue={
                          reviewNotes ? reviewNotes : leadsData?.reviewNotes
                        }
                        style={{ marginBottom: "0px" }}
                      >
                        <TextArea
                          bordered={
                            leadsData?.ticketVerification === "Review"
                              ? true
                              : false
                          }
                          rows={4}
                          style={{
                            width: "100%",
                            height: "75px",
                            borderColor: "#d6d6d6",
                            fontSize: "18px",
                            border: "1px solid gray",
                            fontWeight: "500",
                            // color: "red",
                          }}
                          onChange={(e) =>
                            this.setState({
                              reviewNotes: e.target.value,
                            })
                          }
                          className="form-input"
                          placeholder="Please Enter Review Notes"
                          readOnly={
                            leadsData?.ticketVerification === "Review"
                              ? false
                              : true
                          }
                        />
                      </Form.Item>
                    </Col>
                  )}

                {this.props.managerReview && (
                  <Col
                    span={24}
                    style={{ marginBottom: "0.5vw", marginTop: "0.5vw" }}
                  >
                    <span className="title_changes">Manager Review Notes:</span>

                    <TextArea
                      value={leadsData?.managerNotes}
                      bordered={false}
                      rows={4}
                      style={{
                        width: "100%",
                        height: "75px",
                        borderColor: "#d6d6d6",
                        fontSize: "18px",
                        fontWeight: "500",
                        background: "#f0f0f0",
                        color: "#000",
                      }}
                      className="form-input"
                      placeholder="Please Enter Manager Notes"
                      readOnly
                    />
                  </Col>
                )}
                <Divider style={{marginTop:'24px'}}/>
                {leadsData !== "" && (
                  <>
                    <div style={{ marginTop: "70px" }}>
                    <Divider style={{marginBottom:'0px'}}/>
                      <Row>
                        <Col
                          xxl={{ span: 24 }}
                          xl={{ span: 24 }}
                          lg={{ span: 24 }}
                          md={{ span: 24 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                          style={{
                            backgroundColor: "#586370",
                            color: "#fff",
                            fontWeight: "bold",
                            margin: "10px 0px",
                            borderRadius: "3px",
                          }}
                        >
                          <span
                            style={{ padding: "5px 0px", display: "flex" }}
                            className="title_changes_name"
                          >
                            TICKET UPLOAD DETAILS
                          </span>
                        </Col>
                      </Row>

                      <Row gutter={[12, 0]} className="tiprotitle">
                        <Col
                          xxl={{ span: 8 }}
                          xl={{ span: 8 }}
                          lg={{ span: 8 }}
                          md={{ span: 8 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                          style={{ marginBottom: "0.5vw" }}
                        >
                          <b style={{ marginBottom: "5px" }}>Customer:</b>
                          <Input
                            className="form-input"
                            placeholder="Enter customer name"
                            value={leadsData?.companyName}
                            readOnly
                            style={{
                              background: "#f0f0f0",
                              color: "#000",
                            }}
                          />
                        </Col>

                        <Col
                          xxl={{ span: 8 }}
                          xl={{ span: 8 }}
                          lg={{ span: 8 }}
                          md={{ span: 8 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                          style={{ marginBottom: "0.5vw" }}
                        >
                          <b style={{ marginBottom: "10px" }}>Job Name:</b>{" "}
                          <Input
                            className="form-input"
                            placeholder="Enter job name"
                            value={leadsData?.jobName}
                            readOnly
                            style={{
                              background: "#f0f0f0",
                              color: "#000",
                            }}
                          />
                        </Col>
                        <Col
                          xxl={{ span: 8 }}
                          xl={{ span: 8 }}
                          lg={{ span: 8 }}
                          md={{ span: 8 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                          style={{ marginBottom: "0.5vw" }}
                        >
                          <b style={{ marginBottom: "10px" }}>Order Name:</b>
                          {leadsData?.jobType === "Trucking & Material Job" ? (
                            <Input
                              className="form-input"
                              placeholder="Please enter Order Name"
                              value={leadsData?.material[0]?.vendor}
                              readOnly
                              style={{
                                background: "#f0f0f0",
                                color: "#000",
                              }}
                            />
                          ) : leadsData?.jobType === "Trucking Job" ? (
                            <Input
                              className="form-input"
                              placeholder="Please enter Order Name"
                              value={leadsData?.material[0]?.pickUpAddress}
                              readOnly
                              style={{
                                background: "#f0f0f0",
                                color: "#000",
                              }}
                            />
                          ) : null}
                        </Col>
                        <Col
                          xxl={{ span: 24 }}
                          xl={{ span: 24 }}
                          lg={{ span: 24 }}
                          md={{ span: 24 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                        >
                          <b style={{ marginBottom: "10px" }}>
                            Approved Trucks:
                          </b>
                          <Input
                            className="form-input"
                            placeholder="Enter approved trucks"
                            value={allTruckNumbers?.join(", ")}
                            readOnly
                            style={{
                              background: "#f0f0f0",
                              color: "#000",
                            }}
                          />
                        </Col>
                      </Row>
                    </div>

                    {leadsData?.jobType === "Trucking & Material Job" && (
                      <div style={{ marginTop: "10px" }}>
                        <Row>
                          <Col
                            xxl={{ span: 24 }}
                            xl={{ span: 24 }}
                            lg={{ span: 24 }}
                            md={{ span: 24 }}
                            sm={{ span: 24 }}
                            xs={{ span: 24 }}
                            style={{
                              backgroundColor: "#586370",
                              color: "#fff",
                              fontWeight: "bold",
                              margin: "10px 0px",
                              borderRadius: "3px",
                            }}
                          >
                            <span
                              style={{ padding: "5px 0px", display: "flex" }}
                              className="title_changes_name"
                            >
                              TRUCKING & MATERIAL JOB INFORMATION
                            </span>
                          </Col>
                        </Row>
                        <Row gutter={[12, 0]} className="tiprotitle">
                          <Col
                            xxl={{ span: 12 }}
                            xl={{ span: 12 }}
                            lg={{ span: 12 }}
                            md={{ span: 12 }}
                            sm={{ span: 24 }}
                            xs={{ span: 24 }}
                            style={{ marginBottom: "0.5vw" }}
                          >
                            <b style={{ marginBottom: "10px" }}>Quarry Name:</b>{" "}
                            <Input
                              className="form-input"
                              placeholder="Enter quarry name"
                              value={leadsData?.material[0]?.vendor}
                              readOnly
                              style={{
                                background: "#f0f0f0",
                                color: "#000",
                              }}
                            />
                          </Col>

                          <Col
                            xxl={{ span: 12 }}
                            xl={{ span: 12 }}
                            lg={{ span: 12 }}
                            md={{ span: 12 }}
                            sm={{ span: 24 }}
                            xs={{ span: 24 }}
                          >
                            <b style={{ marginBottom: "10px" }}>Materials:</b>
                            <div>
                              <Input
                                className="form-input"
                                placeholder="Enter materials"
                                value={leadsData?.material[0]?.label}
                                readOnly
                                style={{
                                  background: "#f0f0f0",
                                  color: "#000",
                                }}
                              />

                              {leadsData?.material?.length > 1 && (
                                <Popover
                                  content={leadsData?.material?.map(
                                    (e, i) =>
                                      i !== 0 && (
                                        <p style={{ marginBottom: "1px" }}>
                                          <span
                                            className="title_changes"
                                            style={{ fontSize: "14px" }}
                                          >
                                            {e.label}
                                          </span>
                                        </p>
                                      )
                                  )}
                                >
                                  <Tag
                                    style={{
                                      height: "24px",
                                      marginLeft: "10px",
                                      backgroundColor: "#586370",
                                      color: "#FFF",
                                      borderRadius: "50px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    +{leadsData?.material?.length - 1}
                                  </Tag>
                                </Popover>
                              )}
                            </div>
                          </Col>

                          <Col
                            xxl={{ span: 12 }}
                            xl={{ span: 12 }}
                            lg={{ span: 12 }}
                            md={{ span: 12 }}
                            sm={{ span: 24 }}
                            xs={{ span: 24 }}
                            style={{ marginBottom: "0.5vw" }}
                          >
                            <b style={{ marginBottom: "10px" }}>Pickup:</b>{" "}
                            <Input
                              className="form-input"
                              placeholder="Enter pickup address"
                              value={leadsData?.material[0]?.pickUpAddress}
                              readOnly
                              style={{
                                background: "#f0f0f0",
                                color: "#000",
                              }}
                            />
                          </Col>

                          <>
                            <Col
                              xxl={{ span: 6 }}
                              xl={{ span: 6 }}
                              lg={{ span: 6 }}
                              md={{ span: 6 }}
                              sm={{ span: 24 }}
                              xs={{ span: 24 }}
                              style={{ marginBottom: "0.5vw" }}
                            >
                              <b style={{ marginBottom: "10px" }}>
                                Truck Rate:
                              </b>{" "}
                              <Input
                                className="form-input remove_bg"
                                placeholder="Enter truck rate"
                                value={`$ ${leadsData?.material[0]?.truckingPrice.toFixed(
                                  2
                                )}`}
                                readOnly
                                style={{
                                  background: "#f0f0f0",
                                  color: "#000",
                                  padding: "0px",
                                }}
                                addonAfter={`${leadsData?.material[0]?.truckingPriceType}s`}
                              />
                            </Col>

                            <Col
                              xxl={{ span: 6 }}
                              xl={{ span: 6 }}
                              lg={{ span: 6 }}
                              md={{ span: 6 }}
                              sm={{ span: 24 }}
                              xs={{ span: 24 }}
                            >
                              <b style={{ marginBottom: "10px" }}>
                                Material Rate:
                              </b>{" "}
                              <Input
                                className="form-input remove_bg"
                                placeholder="Enter material rate"
                                value={`$ ${leadsData?.material[0]?.totalPrice.toFixed(
                                  2
                                )}`}
                                readOnly
                                style={{
                                  background: "#f0f0f0",
                                  color: "#000",
                                  padding: "0px",
                                }}
                                addonAfter={`${leadsData?.material[0]?.totalPriceType}s`}
                              />
                            </Col>
                          </>

                          <Col
                            xxl={{ span: 12 }}
                            xl={{ span: 12 }}
                            lg={{ span: 12 }}
                            md={{ span: 12 }}
                            sm={{ span: 24 }}
                            xs={{ span: 24 }}
                            style={{ marginBottom: "0.5vw" }}
                          >
                            <b style={{ marginBottom: "10px" }}>Drop-Off:</b>{" "}
                            <Input
                              className="form-input"
                              placeholder="Enter dropOFF address"
                              value={leadsData?.dropoffAddress}
                              readOnly
                              style={{
                                background: "#f0f0f0",
                                color: "#000",
                              }}
                            />
                          </Col>

                          <Col
                            xxl={{ span: 12 }}
                            xl={{ span: 12 }}
                            lg={{ span: 12 }}
                            md={{ span: 12 }}
                            sm={{ span: 24 }}
                            xs={{ span: 24 }}
                          >
                            <b style={{ marginBottom: "10px" }}>Truck Types:</b>
                            <Input
                              className="form-input"
                              placeholder="Enter Truck Types"
                              value={trucksTypesData
                                ?.filter((e) =>
                                  allTruckTypes?.includes(e.value)
                                )
                                .map((e) => e.truck_code)
                                ?.join(", ")}
                              readOnly
                              style={{
                                background: "#f0f0f0",
                                color: "#000",
                              }}
                            />
                          </Col>
                        </Row>
                      </div>
                    )}
                    {leadsData?.jobType === "Trucking Job" && (
                      <div style={{ marginTop: "10px" }}>
                        <Row>
                          <Col
                            xxl={{ span: 24 }}
                            xl={{ span: 24 }}
                            lg={{ span: 24 }}
                            md={{ span: 24 }}
                            sm={{ span: 24 }}
                            xs={{ span: 24 }}
                            style={{
                              backgroundColor: "#586370",
                              color: "#fff",
                              fontWeight: "bold",
                              margin: "10px 0px",
                              borderRadius: "3px",
                            }}
                          >
                            <span
                              style={{ padding: "5px 0px", display: "flex" }}
                              className="title_changes_name"
                            >
                              TRUCKING JOB INFORMATION
                            </span>
                          </Col>
                        </Row>
                        <Row gutter={[12, 0]} className="tiprotitle">
                          <Col
                            xxl={{ span: 12 }}
                            xl={{ span: 12 }}
                            lg={{ span: 12 }}
                            md={{ span: 12 }}
                            sm={{ span: 24 }}
                            xs={{ span: 24 }}
                            style={{ marginBottom: "0.5vw" }}
                          >
                            <b style={{ marginBottom: "10px" }}>Pickup:</b>{" "}
                            <Input
                              className="form-input"
                              placeholder="Enter pickup address"
                              value={leadsData?.material[0].pickUpAddress}
                              readOnly
                              style={{
                                background: "#f0f0f0",
                                color: "#000",
                              }}
                            />
                          </Col>

                          <Col
                            xxl={{ span: 12 }}
                            xl={{ span: 12 }}
                            lg={{ span: 12 }}
                            md={{ span: 12 }}
                            sm={{ span: 24 }}
                            xs={{ span: 24 }}
                            style={{ marginBottom: "0.5vw" }}
                          >
                            <b style={{ marginBottom: "10px" }}>Truck Rate:</b>{" "}
                            <Input
                              className="form-input remove_bg"
                              placeholder="Enter truck rate"
                              value={`$ ${leadsData?.material[0]?.truckingPrice.toFixed(
                                2
                              )}`}
                              readOnly
                              style={{
                                background: "#f0f0f0",
                                color: "#000",
                                padding: "0px",
                              }}
                              addonAfter={`${leadsData?.material[0]?.truckingPriceType}s`}
                            />
                          </Col>

                          <Col
                            xxl={{ span: 12 }}
                            xl={{ span: 12 }}
                            lg={{ span: 12 }}
                            md={{ span: 12 }}
                            sm={{ span: 24 }}
                            xs={{ span: 24 }}
                            style={{ marginBottom: "0.5vw" }}
                          >
                            <b style={{ marginBottom: "10px" }}>Drop-Off:</b>{" "}
                            <Input
                              className="form-input"
                              placeholder="Enter pickup address"
                              value={leadsData?.dropoffAddress}
                              readOnly
                              style={{
                                background: "#f0f0f0",
                                color: "#000",
                              }}
                            />
                          </Col>

                          <Col
                            xxl={{ span: 12 }}
                            xl={{ span: 12 }}
                            lg={{ span: 12 }}
                            md={{ span: 12 }}
                            sm={{ span: 24 }}
                            xs={{ span: 24 }}
                          >
                            <b style={{ marginBottom: "10px" }}>Truck Types:</b>
                            <Input
                              className="form-input"
                              placeholder="Enter Truck Types"
                              value={trucksTypesData
                                ?.filter((e) =>
                                  allTruckTypes?.includes(e.value)
                                )
                                .map((e) => e.truck_code)
                                ?.join(", ")}
                              readOnly
                              style={{
                                background: "#f0f0f0",
                                color: "#000",
                              }}
                            />
                          </Col>
                          <Col
                            xxl={{ span: 24 }}
                            xl={{ span: 24 }}
                            lg={{ span: 24 }}
                            md={{ span: 24 }}
                            sm={{ span: 24 }}
                            xs={{ span: 24 }}
                          >
                            <b style={{ marginBottom: "10px" }}>
                              Material Name:
                            </b>
                            <Input
                              className="form-input"
                              placeholder="Enter Material Name"
                              value={leadsData?.material[0].materialName}
                              readOnly
                              style={{
                                background: "#f0f0f0",
                                color: "#000",
                              }}
                            />
                          </Col>
                        </Row>
                      </div>
                    )}
                  </>
                )}
              </div>
            </Form>
          </BaseModal>
        )}

        {showNotes && (
          <BaseModal
            className="tickectfinan_modal antmodal_grid headermodal"
            title={"Review Notes"}
            onCancel={() =>
              this.setState({
                showNotes: false,
              })
            }
            formId="reviewForm"
            loading={loading}
            width={
              window.screen.width > "501" && window.screen.width <= "991"
                ? "80%"
                : "520px"
            }
          >
            <Form
              onFinish={(values) => this.onReject(values, leadsData)}
              id="reviewForm"
            >
              <Form.Item
                name="ticketNotes"
                initialValue={
                  signCustomer === false
                    ? managerReview
                      ? "Truck ID does not match"
                      : "Ticket is not signed by Customer"
                    : ""
                }
                rules={[
                  {
                    required: true,
                    message: "Please Enter Review Notes",
                  },
                ]}
                style={{ marginBottom: "0px" }}
              >
                <TextArea
                  rows={4}
                  style={{
                    width: "100%",
                    height: "75px",
                    borderColor: "#d6d6d6",
                    fontSize: "18px",
                    fontWeight: "500",
                  }}
                  className="form-input"
                  placeholder="Please Enter Review Notes"
                />
              </Form.Item>
            </Form>
          </BaseModal>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) =>
  // console.log(state),
  ({
    totalResult: state?.reducer?.ticketsResult,
    loading: state?.reducer?.ticketsResult?.loading,
  });

const mapDispatchToProps = (dispatch) => ({
  actionFetchGetTickets: () => {
    dispatch(getTickets());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAppContext(withRouter(DataInputTickets)));
