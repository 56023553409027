import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { withAppContext } from "../Store/AppContext";
import Cookies from "js-cookie";
import {
  Layout,
  Row,
  Col,
  Button,
  Popover,
  Select,
  Card,
  Avatar,
  Form,
  Input,
  message,
  Checkbox,
  Popconfirm,
  AutoComplete,
  Tooltip,
} from "antd";
import {
  LogoutOutlined,
  SettingOutlined,
  PlusOutlined,
  EllipsisOutlined,
  UsergroupAddOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { add, list, update } from "../api/Apis";
import "../Stylesheet/_header.scss";
import NumberFormat from "react-number-format";
import funnelData from "../Json/funnel.json";
import { Auth } from "aws-amplify";
import appConfig from "../config/AppConfig";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import BaseDrawer from "./BaseDrawer";
import BaseModal from "../Common/BaseModal";
import Trucksuccess from "../Components/trucksuccess";
import { v4 as uuidv4 } from "uuid";
import { MdDelete, MdModeEditOutline } from "react-icons/md";
import Loading from "./Loading";
import Successpack from "../Components/successpack";
import Successrole from "../Components/successrole";
const { Header } = Layout;
const { Meta } = Card;
const { Option } = Select;
const { TextArea } = Input;

const jobData = [
  { label: "Trucking Job", value: "Trucking Job" },
  { label: "Trucking & Material Job", value: "Both" },
  // { label: "Trucking & Material Job", value: "Trucking & Material Job" }
];
class MainHeader extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      selectedAddress: "",
      citySearch: [],
      city: "",
      addressState: "",
      zipCode: "",
      loading: false,
      value: "",
      options: "",
      paramsId: "",
      latitude: "",
      longitude: "",
      pickupSelectedAddress: "",
      pickupAddressState: "",
      pickupCity: "",
      pickupZipCode: "",
      pickupLatitude: "",
      pickupLongitude: "",
      deliverySelectedAddress: "",
      deliveryAddressState: "",
      deliveryCity: "",
      deliveryZipCode: "",
      deliveryLatitude: "",
      deliveryLongitude: "",
      quickLeadType: "New Job",
      companyId: "",
      contactPopup: false,
      contactInfo: [],
      jobInfo: "",
      companyCheck: "",
      quantityType: "Tons",
      isCheckBox: false,
      isCheckBoxcontact: false,
      truckDropInfo: false,
      newRole: "",
      isCheckcontact: false,
      recordContactData: "",
      multipleContacts: [],
      contactLoading: false,
      successModal: false,
      successData: "",
      primaryContactCheck: false,
      allComapaniesData: [],
      selectedCompanyId: "",
      contactsData: [],
      isNewContact: true,
    };
  }

  componentDidMount = () => {
    let userData = JSON.parse(localStorage.getItem("userDetails"));
    this.props.context.getUserAccessInfo(userData?.userRoleInfo);
    this.props.context.setLoading(true);
    this.props?.context?.getTrucksType();
    this.props.context.getSalesPersons();
    this.getCompanies();
  };

  getCompanies = () => {
    this.setState({ loading: true });
    list("companies").then((result) => {
      if (result.success) {
        this.setState({
          allComapaniesData: result.data.filter((c) => !c.isOwnerOperator),
          // filterCompanyData: result.data.filter((c) => !c.isOwnerOperator),
          loading: false,
        });
      } else {
        this.setState({ loading: false, errorLoading: true });
      }
    });
  };

  handleSelectChange = (value) => {
    this.props.context.changeDropDownId(value);
  };

  switchStatement = () => {
    const selectID = funnelData.funeels.filter(
      (e) => Number(e.id) === Number(this.props?.context?.dropDownId)
    );
    switch (window.location.pathname) {
      case `/app/trucking-material`:
        return <p className="pages_heading">Trucking & Material</p>;
      case `/app/owner-operator`:
        return <p className="pages_heading">Owner Operator</p>;
      case `/app/owner-operator-profile/${this.props.context.companyId}`:
        return <p className="pages_heading">Owner Operator Profile</p>;
      case `/app/customer-database`:
        return <p className="pages_heading">Customer Database</p>;
      case `/app/customer-account-profile/${this.props.context.companyId}`:
        return <p className="pages_heading">Customer Account Profile</p>;
      case `/app/jobs/job-profile/${this.props.context.leadId}`:
        return <p className="pages_heading">Job Profile</p>;
      case `/app/quarry-profile/${this.props?.context?.quarryId}`:
        return <p className="pages_heading">Quarry Profile</p>;
      case "/app/ticket-upload":
        return <p className="pages_heading">Ticket Upload</p>;
      case "/app/ticket-processing":
        return <p className="pages_heading">Ticket Processing</p>;
      case "/app/invoice-management":
        return <p className="pages_heading">Invoice Management</p>;
      case "/app/dispatch-tool":
        return <p className="pages_heading">Dispatch Tool</p>;
      // case "/app/dispatch-Quote":
      //   return <p className="pages_heading">Quote Building Tool</p>;
      case "/app/system-users":
        return <p className="pages_heading">System Users</p>;
      case "/app/manage-roles":
        return <p className="pages_heading">Manage Roles</p>;
      case "/app/system-settings":
        return <p className="pages_heading">System Settings</p>;
      case "/app/trucks-scheduler":
        return <p className="pages_heading">Truck Scheduler</p>;
      case "/app/dispatch-jobs":
        return <p className="pages_heading">Request Dispatch</p>;
      case "/app/dispatch-archive":
        return <p className="pages_heading">Dispatch Archive</p>;
      case "/app/salesPerson":
        return <p className="pages_heading">Sales People</p>;
      case "/app/twisted-nail":
        return <p className="pages_heading">Owner Operator Database</p>;
      case `/app/twisted-nail/carrier-profile/${this.props.context.carrierId}`:
        return <p className="pages_heading">Carrier Profile</p>;
      case `/app/salesPerson/${this.props.context.salesPersonId}`:
        return <p className="pages_heading">Sales Person Profile</p>;
      case "/app/projectAnalysis":
        return <p className="pages_heading">Project Analysis</p>;
      case "/app/customer-report":
        return (
          <div className="crmheading">
            <Select
              bordered={false}
              defaultValue={selectID[0]?.option}
              style={{ border: "none", width: "310px" }}
              className="select_header"
              onChange={this.handleSelectChange}
            >
              {funnelData.funeels.map((eachItem) => (
                <Option key={eachItem.id} className="option_style">
                  {eachItem.option}
                </Option>
              ))}
            </Select>
          </div>
        );
      case "/app/quarry-finder-tool":
        return <p className="pages_heading">Quarry Database</p>;
      case "/app/owner-operator-database-tool":
        return <p className="pages_heading">Owner Operator Database Tool</p>;
      case "/app/material-database":
        return <p className="pages_heading">Material Database</p>;
      case "/app/truck-driver-database":
        return <p className="pages_heading">Driver Database</p>;
      case "/app/dashboard":
        return <p className="pages_heading">Dashboard</p>;
      case `/app/leads/leaddetails/${this.state.paramsId}`:
        return <p className="pages_heading">Leads</p>;
      case "/app/invoice":
        return <p className="pages_heading">Generate Invoice</p>;
      case "/app/profile-settings":
        return <p className="pages_heading">Profile Setting</p>;
      case "/app/accountsReceivable":
        return <p className="pages_heading">Accounts Receivable</p>;
      case "/app/accountsPayable":
        return <p className="pages_heading">Accounts Payable</p>;
      case "/app/invoiceManagement":
        return <p className="pages_heading">Invoice Management</p>;
      case `/app/Invoice-Management/${this.props.context.invoiceId}`:
        return <p className="pages_heading">Invoice Management Details</p>;
      case "/app/restoreData":
        return <p className="pages_heading">Restore Data</p>;
      case `/app/updateJobDetails/${this.props.context.leadId}`:
        return <p className="pages_heading">Update Job Details</p>;
      case `/app/imageDatabase`:
        return <p className="pages_heading">Quarry Image Database</p>;
      case `/app/billManagement`:
        return <p className="pages_heading">Bill Management</p>;
      case `/app/job-database`:
        return <p className="pages_heading">Job Database</p>;
      case `/app/manager-review`:
        return <p className="pages_heading">Manager Review</p>;
      case "/app/loading":
        return <p className="pages_heading">Loading</p>;

      default:
        <Select
          bordered={false}
          defaultValue="Trucking / Material"
          style={{ border: "none", width: "310px" }}
          className="select_header"
          onChange={this.handleSelectChange}
        >
          {funnelData.funeels.map((eachItem) => (
            <Option key={eachItem.id} className="option_style">
              {eachItem.option}
            </Option>
          ))}
        </Select>;
        return <p className="pages_heading">Error Page</p>;
    }
  };

  onFinish = (values) => {
    const {
      quickLeadType,
      deliveryLatitude,
      deliveryLongitude,
      pickupSelectedAddress,
      deliverySelectedAddress,
      selectedAddress,
      pickupLatitude,
      pickupLongitude,
      latitude,
      longitude,
      contactInfo,
      selectedCompanyId,
      isNewContact,
    } = this.state;
    let data = {
      leadType: [
        {
          id:
            quickLeadType === "Material Sales"
              ? "1"
              : quickLeadType === "Owner Operator"
              ? "2"
              : quickLeadType === "Driver"
              ? "3"
              : "4",
          value: quickLeadType,
        },
      ],
      notes: values.notes,
    };

    if (this.state?.selectedAddress[0]?.label !== "") {
      if (quickLeadType === "Material Sales") {
        this.setState({
          visible: false,
          loading: true,
        });
        let entityData = {
          ...data,
          company: values["customerAccount"],
          contactInfo: contactInfo,
          multiContact: true,
          isOwnerOperator: false,
        };
        let customerData = {
          entityData,
        };

        this.addCompanyCall(customerData);
      } else if (quickLeadType === "Owner Operator") {
        if (contactInfo?.length > 0) {
          this.setState({
            visible: false,
            loading: true,
          });
          let entityData = {
            ...data,
            company:
              values["customerAccount"] !== undefined
                ? values["customerAccount"]
                : "",
            carrier_name:
              values["customerAccount"] !== undefined
                ? values["customerAccount"]
                : "",
            carrier_status: "Applicant",
            carrierDetails: [],
            contactInfo: contactInfo,
            isOwnerOperator: true,
          };
          let ownerData = {
            entityData,
          };
          ownerData["entityData"]["stageId"] = "Uncontacted Lead";
          // console.log(ownerData);
          this.addCompanyCall(ownerData);
        } else {
          message.error("Please Enter Contact Information");
        }
      } else if (quickLeadType === "Driver") {
        this.setState({
          visible: false,
          loading: true,
        });
        let driverData = {
          entityData: {
            ...data,
            firstName: values.fName,
            lastName: values.lName,
            phone: values.phone,
            email: values.email,
            lat: latitude,
            lng: longitude,
            address: selectedAddress,
            multiContact: false,
            stageId: "Contacted",
          },
        };
        this.addCompanyCall(driverData);
      } else if (quickLeadType === "New Job") {
        this.setState({
          visible: false,
          loading: true,
        });
        let commonData = {
          companyId:
            selectedCompanyId !== undefined && selectedCompanyId !== ""
              ? selectedCompanyId
              : "",
          company: values["customerAccount"],
          multipleContacts:
            contactInfo?.length === 0
              ? [
                  {
                    firstName: values["customerAccount"],
                    lastName: "",
                    contactRole: "",
                    phone: "",
                    email: "",
                    notes: "",
                  },
                ]
              : contactInfo,
          leadType: [
            {
              id: "1",
              value: "Material Sales",
            },
          ],
          jobType: values?.jobType,
          jobName: "",
          dropoff: deliverySelectedAddress,
          dropOffLatLng: {
            lat: deliveryLatitude,
            lng: deliveryLongitude,
          },
          stageId: "Contacted",
          notes: values["notes"],
          isNewCompany:
            selectedCompanyId !== undefined && selectedCompanyId !== ""
              ? false
              : true,
          isNewContact,
        };
        let truckingData = {
          entityData: {
            ...commonData,
          },
        };
        let truckingWithPickUp = {
          entityData: {
            ...commonData,
            material: [
              {
                pickUpAddress: pickupSelectedAddress,
                pickUpLatLng: {
                  lat: pickupLatitude,
                  lng: pickupLongitude,
                },
              },
            ],
          },
        };
        let materialData = {
          entityData: {
            ...commonData,
            requiredMaterials: values["requiredMaterials"],
          },
        };
        // let existingCompanyName = allComapaniesData?.filter(
        //   (e) => e.company === values["customerAccount"]
        // );
        // if (existingCompanyName?.length > 0) {
        //   message.error("This Company already exists!");
        //   this.getCompanies();
        // } else {
        if (values["jobType"] === "Trucking Job") {
          if (
            pickupSelectedAddress !== undefined &&
            pickupSelectedAddress !== ""
          ) {
            let url =
              "https://maps.googleapis.com/maps/api/distancematrix/json?units=imperial&destinations=" +
              [deliveryLatitude, deliveryLongitude] +
              "&origins=" +
              [pickupLatitude, pickupLongitude] +
              "&key=AIzaSyB781k9fNf61hnxE3A6cvcascwhAhIVIK4";
            let milesData = {
              entityData: {
                url,
              },
            };
            add("getMiles", milesData)
              .then((res) => {
                if (res.success) {
                  truckingWithPickUp["entityData"]["material"][0]["duration"] =
                    res.data.duration;
                  truckingWithPickUp["entityData"]["material"][0]["milesData"] =
                    res.data.distance;
                  // console.log(truckingWithPickUp);
                  this.addApicall(truckingWithPickUp);
                }
              })
              .catch((err) => {
                this.setState({
                  loading: false,
                });
                console.log(err, "err");
              });
          } else {
            this.addApicall(truckingData);
          }
        } else {
          this.addApicall(materialData);
        }
        // }
      }
    } else {
      message("Please enter your address!");
    }
  };

  addApicall = (data) => {
    add(appConfig["entityNames"]["addLead"], data)
      .then((result) => {
        if (result.success) {
          // console.log(result);
          this.props.history.push(`${window.location.pathname}#quickJob`);
          // this.props.context.allLeadsData();
          this.props.context.setLoading(false);
          this.getCompanies();
          // this.getCompaniesData();
          this.setState({
            successData: result?.data?.leadObj,
            successModal: true,
            loading: false,
            isCheckBox: false,
            isCheckBoxcontact: false,
            deliverySelectedAddress: "",
            pickupSelectedAddress: "",
            deliveryLatitude: "",
            deliveryLongitude: "",
            pickupLatitude: "",
            pickupLongitude: "",
            contactInfo: [],
            quickLeadType: "New Job",
          });
        }
      })
      .catch((err) => {
        this.setState({
          successModal: false,
          loading: false,
          isCheckBox: false,
          isCheckBoxcontact: false,
          deliverySelectedAddress: "",
          pickupSelectedAddress: "",
          deliveryLatitude: "",
          deliveryLongitude: "",
          pickupLatitude: "",
          pickupLongitude: "",
          contactInfo: [],
          quickLeadType: "New Job",
        });
        console.log(err);
      });
  };

  addCompanyCall = (data) => {
    add(appConfig["entityNames"]["addCompany"], data)
      .then((result) => {
        if (result.success) {
          this.props.history.push(`${window.location.pathname}#quickJob`);
          this.props.context.setLoading(false);
          this.setState({
            successData: result?.data?.data,
            successModal: true,
            contactInfo: [],
            loading: false,
            selectedAddress: "",
            latitude: "",
            longitude: "",
          });
        } else {
          message.info(result.errors.message.message);
          this.setState({
            contactInfo: [],
            loading: false,
          });
        }
      })
      .catch((err) => {
        this.setState({
          successModal: false,
          loading: false,
          contactInfo: [],
          selectedAddress: "",
          latitude: "",
          longitude: "",
        });
        console.log(err);
      });
  };

  onClickCustomerProfile = () => {
    const { successData } = this.state;
    this.setState({ successModal: false });
    // console.log(successData, "lead+comp");
    this.props.history.push(
      `/app/customer-account-profile/${successData?.companyId}`
    );
  };

  onClickCompanyProfile = () => {
    const { successData } = this.state;
    this.setState({ successModal: false });
    // console.log(successData, "only comp");
    this.props.history.push(`/app/customer-account-profile/${successData?.id}`);
  };

  onClickOwnerOperator = () => {
    const { successData } = this.state;
    this.setState({ successModal: false });
    // console.log(successData, "owner comp");
    this.props.history.push(`/app/owner-operator-profile/${successData?.id}`);
  };

  onClickJobProfile = () => {
    const { successData } = this.state;
    this.setState({ successModal: false });
    this.props.history.push(`/app/jobs/job-profile/${successData?.id}`);
  };

  onClickTrucks = () => {
    this.setState({ successModal: false });
    this.props.history.push("/app/truck-driver-database");
  };

  handleLink = () => {
    window.location.replace("/app/profile-settings");
  };

  getAddress = (address, type) => {
    if (type === "pickup") {
      this.setState({
        pickupSelectedAddress: address.label,
      });
    } else if (type === "dropoff") {
      this.setState({
        deliverySelectedAddress: address.label,
      });
    } else {
      this.setState({
        selectedAddress: address.label,
      });
    }
  };

  handleSelect = (address, type) => {
    const citySearch = [];
    citySearch.push(address.label);
    this.setState({
      citySearch,
    });
    let cityValue = "";
    let stateValue = "";
    let zipCodeValue = "";
    geocodeByAddress(address.label)
      .then((results) => {
        getLatLng(results[0]).then(({ lat, lng }) => {
          if (type === "pickup") {
            this.setState({ pickupLatitude: lat, pickupLongitude: lng });
          } else if (type === "dropoff") {
            this.setState({ deliveryLatitude: lat, deliveryLongitude: lng });
          } else {
            this.setState({ latitude: lat, longitude: lng });
          }
        });
        const geoIdData = results[0];
        this.setState({
          geoIdData,
        });
        let address = results[0].address_components;
        for (let i = 0; i < address.length; i++) {
          if (address[i].types[0] === "locality") {
            cityValue = address[i].long_name;
          }
          if (address[i].types[0] === "administrative_area_level_1") {
            stateValue = address[i].short_name;
          }
          if (address[i].types[0] === "postal_code") {
            zipCodeValue = address[i].long_name;
          }
        }
        this.setState({
          deliveryCity: cityValue,
          deliveryAddressState: stateValue,
          deliveryZipCode: zipCodeValue,
        });
      })
      .catch((error) => console.error("Error", error));
  };

  handleLogout = async () => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const tableSizeRange = JSON.parse(localStorage.getItem("tableSizeRange"));
    let data = {
      entityData: { tableSizeRange },
    };
    console.log(data, "data");
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    await update("users", userDetails.id, data);
    try {
      await Auth.signOut()
        .then((res) => {
          localStorage.setItem("userDetails", JSON.stringify(""));
          localStorage.setItem("tableSizeRange", JSON.stringify({}));
          Cookies.remove("jwt_Token");
          localStorage.setItem("userLogin", JSON.stringify(false));
          // this.props.context.setUserDetails("");
          this.props.context.setUserLogin(false);
          this.props.history.replace("/login");
        })
        .catch((error) => {
          console.log("error signing out: ", error);
          this.props.history.replace("/login");
        });
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };

  addContact = (values) => {
    const {
      recordContactData,
      quickLeadType,
      primaryContactCheck,
      selectedCompanyId,
      contactsData,
    } = this.state;

    this.setState({
      loading: true,
    });

    if (this.state.isCheckcontact) {
      if (quickLeadType === "Owner Operator") {
        this.props.context.updateOwnerRolesData(
          values["roleOther"],
          "ownerOperatorRoles"
        );
      } else {
        this.props.context.updateRolesData(values["roleOther"], "roles");
      }
    }

    if (recordContactData !== "") {
      let filteredData = this.state.contactInfo.filter(
        (c) => c.id !== recordContactData?.id
      );
      this.setState({
        contactInfo: filteredData,
      });
    }

    let data = {
      entityData: {
        id: uuidv4(),
        contact_name: values["fName"] + " " + values["lName"],
        contact_number: values.phone,
        contact_email: values.email,
        firstName: values.fName,
        lastName: values.lName,
        phone: values.phone,
        email: values.email,
        contactRole:
          values["role"] === "Add New Role"
            ? values["roleOther"]
            : recordContactData?.role
            ? recordContactData?.role
            : values["role"],
        notes: values.notes || recordContactData?.notes,
        company:
          this.state.companyCheck === ""
            ? ""
            : this.state.companyCheck || recordContactData?.company,
        companyId:
          selectedCompanyId !== "" && selectedCompanyId !== undefined
            ? selectedCompanyId
            : "",
        jobId: [],
        isPrimaryContact: "",
      },
    };
    if (primaryContactCheck && quickLeadType === "Owner Operator") {
      data["entityData"]["isPrimaryContact"] = true;
    } else {
      data["entityData"]["isPrimaryContact"] = false;
    }
    // console.log(data, "data");
    let contactData = this.state.contactInfo?.filter(
      (c) =>
        c?.email !== "" &&
        c?.email === values?.email &&
        c?.phone !== "" &&
        c?.phone === values?.phone
    );

    if (contactData?.length > 0) {
      message.info("Given Email and Phone Number already exists");
      this.setState({
        loading: false,
      });
      return true;
    } else {
      this.setState({ contactPopup: false });
      add(appConfig["entityNames"]["checkContact"], data)
        .then((result) => {
          if (result.data.message === "success") {
            add(appConfig["entityNames"]["updateContact"], data);
            message.success("Contact Added Successfully!");
            let contactInfo =
              this.state.contactInfo?.length > 0 ? this.state.contactInfo : [];
            if (recordContactData?.length > 0) {
              let filteredData = this.state.contactInfo?.filter(
                (c) => c.id !== recordContactData?.id
              );
              filteredData.push(data["entityData"]);
              this.formRef.current.setFieldsValue({
                contactperson: filteredData?.map((e) => e.id),
              });
              this.setState({
                contactInfo: filteredData,
                contactsData: contactsData.concat(filteredData),
                loading: false,
                isCheckcontact: false,
                newRole: "",
                primaryContactCheck: false,
              });
            }
            contactInfo.push(data["entityData"]);
            this.formRef.current.setFieldsValue({
              contactperson: contactInfo?.map((e) => e.id),
            });
            this.setState({
              contactInfo,
              contactsData: contactsData.concat(contactInfo),
              loading: false,
              isCheckcontact: false,
              newRole: "",
              primaryContactCheck: false,
            });
          } else {
            message.info(result.data.message);
            this.setState({
              loading: false,
              isCheckcontact: false,
              newRole: "",
              primaryContactCheck: false,
            });
            return true;
          }
        })
        .catch((err) => {
          this.setState({
            loading: false,
          });
          console.log(err);
        });
    }
  };

  deleteContactInfo = (id) => {
    let contactInfo = this.state.contactInfo.filter((c) => c.id !== id);
    this.setState({
      contactInfo,
    });
    message.info("Contact Removed Successfully!");
  };

  editContactInfo = (data) => {
    this.setState({
      contactPopup: true,
      recordContactData: data,
      primaryContactCheck: data?.isPrimaryContact,
    });
  };

  onChangeCheckBox = (e) => {
    this.setState({ isCheckBox: e.target.checked });
  };

  handleChange = (value) => {
    document.getElementById("form").reset();
    this.setState({
      quickLeadType: value,
      contactInfo: [],
      isNewContact: true,
      isCheckBoxcontact: false,
      companyCheck: "",
      contactsData: [],
    });
  };

  handelClose = () => {
    document.getElementById("form").reset();
    this.setState({
      contactInfo: [],
      contactsData: [],
      jobInfo: "",
      isCheckBoxcontact: false,
      companyCheck: "",
      visible: false,
      quickLeadType: "New Job",
      selectedMaterial: [],
      contactPopup: false,
      isNewContact: true,
    });
  };

  handleQuickAc = () => {
    const { allComapaniesData } = this.state;
    if (
      JSON.stringify(this?.props?.context?.getComapaniesData) !==
      JSON.stringify(allComapaniesData)
    ) {
      this.setState({
        allComapaniesData: this?.props?.context?.getComapaniesData,
      });
    }
    this.setState({ visible: true, quickLeadType: "New Job" });
  };

  onSelectCompanyName = (value, option) => {
    this.setState({
      companyCheck: value,
      selectedCompanyId: option.key,
    });
    this.getContactDetails(option.key);
  };

  handleChangeCompanyName = (value, option) => {
    this.setState({
      contactsData: [],
    });
    if (option) {
      this.setState({
        selectedCompanyId: option.key,
      });
      this.getContactDetails(option.key);
    }

    this.setState({
      companyCheck: value,
    });
  };

  getContactDetails = (id) => {
    if (id) {
      list(appConfig["entityNames"]["updateContact"], { companyId: id }).then(
        (result) => {
          if (result.success) {
            this.setState({
              loading: false,
              contactsData: result.data.filter((d) => d.isDriver !== true),
            });
          }
        }
      );
    }
  };

  handleSelectContact = (e, option) => {
    this.setState({
      contactInfo: option.map((e) => e.option),
      isNewContact: false,
    });
  };

  render() {
    const {
      quickLeadType,
      contactPopup,
      contactInfo,
      jobInfo,
      newRole,
      recordContactData,
      loading,
      successModal,
      successData,
      allComapaniesData,
      contactsData,
    } = this.state;
    const { userRoleAccess } = this.props.context;
    const roleData = userRoleAccess?.otherData?.filter(
      (e) => e.title === "Finance"
    );
    const finaceInfo =
      roleData &&
      roleData[0]?.multiPannel?.filter((m) => m.title === "Ticket Processing");
    let loginType = "";

    if (finaceInfo && finaceInfo[0]?.selectedOptions?.includes("Edit")) {
      loginType = "review";
    } else if (
      finaceInfo &&
      finaceInfo[0]?.selectedOptions?.includes("Data Input")
    ) {
      loginType = "open";
    }
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));

    return (
      <>
        <Loading enableLoading={loading} />
        <Layout>
          <Header className="header_styling">
            <Row style={{ height: "100%" }}>
              <Col
                xxl={{ span: 24 }}
                xl={{ span: 24 }}
                lg={{ span: 24 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
                // span={24}
                className="header_border"
                style={{
                  // borderBottom: "1px solid #e1e1e1",
                  height: "100%",
                }}
              >
                <Row gutter={[24, 0]} style={{ height: "100%" }}>
                  <Col
                    xxl={{ span: 7 }}
                    xl={{ span: 7 }}
                    lg={{ span: 6 }}
                    md={{ span: 12 }}
                    sm={{ span: 15 }}
                    xs={{ span: 15 }}
                    className="text_headingpage"
                  >
                    {this.switchStatement()}
                  </Col>

                  <Col
                    xxl={{ span: 16 }}
                    xl={{ span: 16 }}
                    lg={{ span: 16 }}
                    md={{ span: 12 }}
                    sm={{ span: 9 }}
                    xs={{ span: 9 }}
                    style={{ height: "100%", marginLeft: "auto" }}
                    className="main-header-profile"
                  >
                    <div className="fixedprofile">
                      <div className="fixedprofilegrpff">
                        {loginType === "review" && (
                          <>
                            <div className="quickleadsection show-on-desktop-portrait">
                              <Button
                                type="primary"
                                icon={<PlusOutlined />}
                                onClick={() => this.handleQuickAc()}
                                style={{
                                  borderRadius: "5px",
                                  height: "35px",
                                  margin: "auto 0px",
                                }}
                              >
                                <span>Quick Action</span>
                              </Button>
                            </div>

                            <div className="quickleadsection show-on-mobile-portrait">
                              <Button
                                type="primary"
                                icon={<PlusOutlined />}
                                onClick={() => this.handleQuickAc()}
                                style={{
                                  borderRadius: "5px",
                                  height: "35px",
                                  margin: "auto 0px",
                                }}
                              >
                                <span>QA</span>
                              </Button>
                            </div>
                            <div className="divideverticalicon">|</div>
                          </>
                        )}

                        <div className="profilecontentdd">
                          <Popover
                            placement="bottom"
                            content={
                              <div style={{ width: "max-content" }}>
                                <Row
                                  style={{
                                    cursor: "pointer",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <Col>
                                    <SettingOutlined
                                      style={{
                                        fontSize: "14px",
                                        marginRight: "10px",
                                      }}
                                    />
                                  </Col>
                                  <Col>
                                    <Link
                                      onClick={this.handleLink}
                                      style={{
                                        textDecoration: "none",
                                        color: "black",
                                        fontSize: "16px",
                                      }}
                                    >
                                      Profile Settings
                                    </Link>
                                  </Col>
                                </Row>

                                <Row
                                  onClick={this.handleLogout}
                                  style={{ cursor: "pointer" }}
                                >
                                  <Col>
                                    <LogoutOutlined
                                      style={{
                                        color: "red",
                                        fontSize: "18px",
                                      }}
                                    />
                                  </Col>
                                  <Col
                                    style={{
                                      color: "red",
                                      fontSize: "16px",
                                      marginLeft: "10px",
                                      marginTop: "-5px",
                                    }}
                                  >
                                    Logout
                                  </Col>
                                </Row>
                              </div>
                            }
                            title={null}
                            trigger="hover"
                          >
                            <Link
                              style={{ textDecoration: "none" }}
                              // onClick={this.handleLink}
                            >
                              <Meta
                                avatar={
                                  <Avatar
                                    src={
                                      userDetails &&
                                      userDetails.profileImage ? (
                                        userDetails.profileImage
                                      ) : (
                                        <Button
                                          shape="circle"
                                          size="big"
                                          block={true}
                                          className="header-img-btn"
                                          style={{
                                            border: "1px solid #aab0bb",
                                            color: "#aab0bb",
                                          }}
                                        >
                                          {userDetails
                                            ? userDetails?.firstName
                                                .charAt(0)
                                                .toUpperCase()
                                            : ""}
                                        </Button>
                                      )
                                    }
                                  />
                                }
                                title={
                                  <span className="profiletitled">
                                    {userDetails?.firstName +
                                      " " +
                                      userDetails?.lastName}
                                  </span>
                                }
                                description={
                                  userDetails?.userProfile === "A"
                                    ? "Admin"
                                    : userDetails?.userProfile
                                }
                              />
                              <DownOutlined />
                            </Link>
                          </Popover>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>

            {this.state.visible && (
              <BaseDrawer
                title={
                  quickLeadType === "Material Sales"
                    ? "Add New Customer"
                    : quickLeadType === "New Job"
                    ? "Add New Job"
                    : quickLeadType === "Owner Operator"
                    ? "Add New Owner Operator"
                    : quickLeadType === "Driver"
                    ? "Add New Driver"
                    : "Add New Contact"
                }
                onClose={() => this.handelClose()}
                visible={this.state.visible}
                destroyOnClose={true}
                className="headerdrawer"
                footer={[
                  <div style={{ float: "right" }}>
                    <Button
                      onClick={() => {
                        this.handelClose();
                      }}
                      className="cancelbtnd"
                      style={{
                        height: "35px",
                        width: "100px",
                        borderRadius: "5px",
                        marginRight: "25px",
                      }}
                      type="ghost"
                    >
                      Cancel
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={this.state.loading}
                      style={{
                        height: "35px",
                        width: "100px",
                        borderRadius: "5px",
                      }}
                      form="form"
                    >
                      Save
                    </Button>
                  </div>,
                ]}
              >
                <Col span={24}>
                  <span className="title_changes">Types</span>
                  <Form.Item
                    name="type"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Your Type!",
                      },
                    ]}
                  >
                    <Select
                      defaultValue={quickLeadType}
                      allowClear
                      className="select_Height"
                      style={{ height: "40px", fontWeight: "300" }}
                      placeholder="Please Select Your Type"
                      onChange={this.handleChange}
                    >
                      <Option value="New Job">New Job</Option>
                      <Option value="Material Sales">New Customer</Option>
                      <Option value="Owner Operator">New Owner Operator</Option>
                      <Option value="Driver">New CDL Driver</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Form
                  layout="vertical"
                  onFinish={this.onFinish}
                  initialValues={{ remember: true }}
                  id="form"
                  ref={this.formRef}
                >
                  <Row gutter={[18, 0]}>
                    {quickLeadType === "New Job" && (
                      <Col span={24}>
                        <span className="title_changes">Job Type</span>
                        <Form.Item
                          name="jobType"
                          rules={[
                            {
                              required: true,
                              message: "Please Select Job Type!",
                            },
                          ]}
                        >
                          <Select
                            size="large"
                            allowClear
                            className="select_Height"
                            onChange={(value) =>
                              this.setState({
                                jobInfo: value,
                                pickUpAddress: "",
                                pickupLatitude: "",
                                pickupLongitude: "",
                              })
                            }
                            placeholder="Please Select A Job Type"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                          >
                            {jobData.map((eachItem) => (
                              <Option
                                key={eachItem.value}
                                value={eachItem.value}
                                className="option_style"
                              >
                                {eachItem.label}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    )}

                    {quickLeadType !== "Driver" && (
                      <Col span={24}>
                        {quickLeadType === "Owner Operator" ? (
                          <span className="title_changes">Carrier Name</span>
                        ) : (
                          <span className="title_changes">
                            Customer Account
                          </span>
                        )}
                        <Form.Item
                          name="customerAccount"
                          rules={[
                            {
                              required:
                                quickLeadType === "Owner Operator"
                                  ? false
                                  : true,
                              message:
                                quickLeadType === "Owner Operator"
                                  ? "Please Enter Carrier Name"
                                  : "Please Enter Customer Account!",
                            },
                          ]}
                        >
                          {/* <AutoComplete
                              size="large"
                              showSearch
                              className="select_Height"
                              style={{
                                borderColor: "#d6d6d6",
                              }}
                              onSelect={this.onSelectCompanyName}
                              onChange={this.handleChangeCompanyName}
                              placeholder="Please Select A Customer Account"
                              filterOption={(inputValue, option) =>
                                option.value
                                  .toUpperCase()
                                  .indexOf(inputValue.toUpperCase()) !== -1
                              }
                            >
                              {companyData.map((eachItem) => (
                                <Option
                                  key={eachItem.id}
                                  value={eachItem.label}
                                  className="option_style"
                                >
                                  {eachItem.label}
                                </Option>
                              ))}
                            </AutoComplete> */}
                          {quickLeadType === "Owner Operator" ? (
                            <Input
                              className="form-input"
                              placeholder={
                                quickLeadType === "Owner Operator"
                                  ? "Please Enter Carrier Name"
                                  : "Please Enter Customer Account"
                              }
                              onChange={(e) =>
                                this.setState({
                                  companyCheck: e.target.value,
                                })
                              }
                            />
                          ) : (
                            <AutoComplete
                              allowClear
                              size="large"
                              showSearch
                              className="select_Height"
                              style={{
                                borderColor: "#d6d6d6",
                              }}
                              onSelect={this.onSelectCompanyName}
                              onChange={this.handleChangeCompanyName}
                              placeholder="Please Select A Customer Account"
                              filterOption={(inputValue, option) =>
                                option.value
                                  .toUpperCase()
                                  .indexOf(inputValue.toUpperCase()) !== -1
                              }
                            >
                              {allComapaniesData.map(
                                (eachItem) =>
                                  eachItem.companyName && (
                                    <Option
                                      key={eachItem.id}
                                      value={eachItem.companyName}
                                      className="option_style"
                                    >
                                      {eachItem.companyName}
                                    </Option>
                                  )
                              )}
                            </AutoComplete>
                          )}
                        </Form.Item>
                      </Col>
                    )}

                    {quickLeadType === "Driver" && (
                      <>
                        <Col span={24}>
                          <span className="title_changes">First Name</span>
                          <Form.Item
                            name="fName"
                            rules={[
                              {
                                required: true,
                                message: "Please Enter Your First Name!",
                              },
                            ]}
                          >
                            <Input
                              className="form-input"
                              placeholder="Please Enter Your First Name"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <span className="title_changes">Last Name</span>
                          <Form.Item
                            name="lName"
                            rules={[
                              {
                                required: true,
                                message: "Please Enter your Last Name!",
                              },
                            ]}
                          >
                            <Input
                              className="form-input"
                              placeholder="Please Enter your Last Name"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <span className="title_changes">Phone</span>
                          <Form.Item
                            name="phone"
                            rules={[
                              {
                                required: false,
                                message: "Please Enter Your Phone!",
                              },
                            ]}
                          >
                            <NumberFormat
                              className="ant-input-number numberictest"
                              format="(###) ###-####"
                              style={{ width: "100%" }}
                              placeholder="Please Enter Your Phone"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <span className="title_changes">Email</span>
                          <Form.Item
                            name="email"
                            rules={[
                              {
                                required: false,
                                message: "Please Enter Your Email!",
                              },
                              {
                                type: "email",
                                message: "The Input Is Not Valid Email",
                              },
                            ]}
                          >
                            <Input
                              className="form-input"
                              placeholder="Please Enter Your Email"
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          xxl={{ span: 24, offset: 0 }}
                          xl={{ span: 24, offset: 0 }}
                          lg={{ span: 24, offset: 0 }}
                          md={{ span: 24, offset: 0 }}
                          sm={{ span: 24, offset: 0 }}
                          xs={{ span: 24, offset: 0 }}
                        >
                          <span className="title_changes">Home City</span>
                          <Form.Item
                            name="address"
                            rules={[
                              {
                                required: false,
                                message: "Please Enter Home City",
                              },
                            ]}
                          >
                            <GooglePlacesAutocomplete
                              className="form-input"
                              autocompletionRequest={{
                                componentRestrictions: {
                                  country: ["us"],
                                },
                              }}
                              selectProps={{
                                placeholder: "Search Home City...",
                                onChange: (address) => {
                                  this.handleSelect(address);
                                  this.getAddress(address);
                                },
                              }}
                            />
                          </Form.Item>
                        </Col>
                      </>
                    )}

                    {quickLeadType !== "Driver" && (
                      <>
                        <Col span={24}>
                          <span className="title_changes">Contact Name</span>
                          <div className="contactname-multiple">
                            <Form.Item
                              name="contactperson"
                              rules={[
                                {
                                  required: false,
                                  message: "Please Enter Your Contact Name!",
                                },
                              ]}
                            >
                              <Select
                                size="large"
                                allowClear
                                mode="multiple"
                                showSearch
                                className="select_multiplequarytol"
                                style={{
                                  height: "auto !important",
                                  width: "100% !important",
                                }}
                                placeholder="Please Select A Contact Name"
                                onChange={this.handleSelectContact}
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                              >
                                {contactsData?.map((eachItem, i) => (
                                  <Option
                                    key={i}
                                    value={eachItem?.id}
                                    option={eachItem}
                                    className="option_style"
                                  >
                                    {eachItem.firstName +
                                      " " +
                                      eachItem.lastName}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>

                            {!contactPopup && (
                              <Tooltip
                                title="Add Contact"
                                overlayStyle={{
                                  backGroundColor: "#000",
                                  color: "#000",
                                }}
                              >
                                <div
                                  style={{
                                    fontWeight: "500",
                                    fontSize: "20px",
                                    display: "flex",
                                    alignItems: "center",
                                    marginLeft: "auto",
                                    padding: "8px",
                                    position: "absolute",
                                    top: "0px",
                                    right: "0px",
                                    height: "100%",
                                    borderLeft: "1px solid #ddd",
                                    zIndex: "+9",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    this.state.companyCheck !== "" ||
                                    quickLeadType === "Owner Operator"
                                      ? this.setState({
                                          contactPopup: true,
                                          recordContactData: "",
                                        })
                                      : message.info(
                                          "Please Enter Your Company Name First!"
                                        )
                                  }
                                >
                                  <UsergroupAddOutlined />
                                </div>
                              </Tooltip>
                            )}
                          </div>
                        </Col>

                        {contactInfo?.map((c) => (
                          <Col span={24} style={{ marginBottom: "1vw" }}>
                            <div
                              style={{
                                display: "flex",
                                flexWrap: "nowrap",
                                alignItems: "center",
                              }}
                            >
                              {/* <span className="title_changes">
                                Contact Name:
                              </span> */}
                              <Popover
                                content={
                                  (c?.phone || c?.email) && (
                                    <Row>
                                      <Col span={24}>
                                        <span>{c.phone}</span>
                                      </Col>
                                      <Col span={24}>
                                        <span
                                          style={{
                                            color: "black",
                                            display: "flex",
                                            flexWrap: "wrap",
                                            alignItems: "center",
                                            justifyContent: "left",
                                          }}
                                        >
                                          {c.email}
                                        </span>
                                      </Col>
                                    </Row>
                                  )
                                }
                              >
                                <span
                                  className="title_changes"
                                  style={{
                                    margin: "0px 5px",
                                    wordBreak: "break-word",
                                    width: "100%",
                                    maxWidth: "270px",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textTransform: "capitalize",
                                    cursor: "pointer",
                                  }}
                                >
                                  {quickLeadType === "Owner Operator"
                                    ? c.contactRole
                                      ? c?.contact_name + ", " + c.contactRole
                                      : c?.contact_name
                                    : c.contactRole
                                    ? c.firstName +
                                      " " +
                                      c.lastName +
                                      ", " +
                                      c.contactRole
                                    : c.firstName + " " + c.lastName}
                                </span>
                              </Popover>
                              <Col>
                                <Popover
                                  placement="left"
                                  content={
                                    <Row className="popovergrid">
                                      <Col span={24}>
                                        <Button
                                          style={{
                                            fontSize: "15px",
                                            cursor: "pointer",
                                            border: "none",
                                            padding: "0px",
                                            textAlign: "left",
                                            width: "100%",
                                            height: "auto",
                                          }}
                                          onClick={() =>
                                            this.editContactInfo(c)
                                          }
                                        >
                                          <span
                                            style={{
                                              color: "black",
                                              display: "flex",
                                              flexWrap: "wrap",
                                              alignItems: "center",
                                              justifyContent: "left",
                                            }}
                                          >
                                            <MdModeEditOutline
                                              style={{ marginRight: "5px" }}
                                            />
                                            Edit
                                          </span>
                                        </Button>
                                      </Col>
                                      <Col span={24}>
                                        <Button
                                          style={{
                                            fontSize: "15px",
                                            cursor: "pointer",
                                            border: "none",
                                            padding: "0px",
                                            textAlign: "left",
                                            width: "100%",
                                            height: "auto",
                                          }}
                                        >
                                          <Popconfirm
                                            title="Are you sure？"
                                            okText="Yes"
                                            cancelText="No"
                                            onConfirm={() =>
                                              this.deleteContactInfo(c.id)
                                            }
                                          >
                                            <span
                                              style={{
                                                color: "black",
                                                display: "flex",
                                                flexWrap: "wrap",
                                                alignItems: "center",
                                                justifyContent: "left",
                                              }}
                                            >
                                              <MdDelete
                                                style={{ marginRight: "5px" }}
                                              />
                                              Delete
                                            </span>
                                          </Popconfirm>
                                        </Button>
                                      </Col>
                                    </Row>
                                  }
                                >
                                  <EllipsisOutlined
                                    style={{
                                      fontSize: "30px",
                                      color: "grey",
                                      marginTop: "3px",
                                    }}
                                  />
                                </Popover>
                              </Col>
                            </div>
                          </Col>
                        ))}
                      </>
                    )}

                    {quickLeadType === "New Job" && (
                      <>
                        {jobInfo !== "Trucking Job"  && (
                          <Col span={24}>
                            <span className="title_changes">
                              Material Notes
                            </span>
                            <Form.Item
                              name="requiredMaterials"
                              rules={[
                                {
                                  required: true,
                                  message: "Please Enter Material Notes!",
                                },
                              ]}
                            >
                              <TextArea
                                rows={4}
                                style={{
                                  width: "100%",
                                  height: "75px",
                                  borderColor: "#d6d6d6",
                                }}
                                className="form-input"
                                placeholder="Please Enter Material Notes"
                              />
                            </Form.Item>
                          </Col>
                        )}

                        {jobInfo === "Trucking Job" && (
                          <Col
                            xxl={{ span: 24, offset: 0 }}
                            xl={{ span: 24, offset: 0 }}
                            lg={{ span: 24, offset: 0 }}
                            md={{ span: 24, offset: 0 }}
                            sm={{ span: 24, offset: 0 }}
                            xs={{ span: 24, offset: 0 }}
                          >
                            <span className="title_changes">
                              Pickup Address
                            </span>
                            <Form.Item
                              name="pickupAddress"
                              rules={[
                                {
                                  required: false,
                                  message: "Please Enter Your Address",
                                },
                              ]}
                            >
                              <GooglePlacesAutocomplete
                                className="form-input"
                                autocompletionRequest={{
                                  componentRestrictions: {
                                    country: ["us"],
                                  },
                                }}
                                selectProps={{
                                  placeholder: "Search Address...",
                                  onChange: (address) => {
                                    this.handleSelect(address, "pickup");
                                    this.getAddress(address, "pickup");
                                  },
                                }}
                              />
                            </Form.Item>
                          </Col>
                        )}

                        <Col
                          xxl={{ span: 24, offset: 0 }}
                          xl={{ span: 24, offset: 0 }}
                          lg={{ span: 24, offset: 0 }}
                          md={{ span: 24, offset: 0 }}
                          sm={{ span: 24, offset: 0 }}
                          xs={{ span: 24, offset: 0 }}
                        >
                          <span className="title_changes">
                            Delivery Address
                          </span>
                          <Form.Item
                            name="deliveryAddress"
                            rules={[
                              {
                                required: false,
                                message: "Please Enter Your Address",
                              },
                            ]}
                          >
                            <GooglePlacesAutocomplete
                              className="form-input"
                              autocompletionRequest={{
                                componentRestrictions: {
                                  country: ["us"],
                                },
                              }}
                              selectProps={{
                                placeholder: "Search Address...",
                                onChange: (address) => {
                                  this.handleSelect(address, "dropoff");
                                  this.getAddress(address, "dropoff");
                                },
                              }}
                            />
                          </Form.Item>
                        </Col>
                      </>
                    )}

                    <Col span={24}>
                      <span className="title_changes">Notes</span>
                      <Form.Item
                        name="notes"
                        rules={[
                          {
                            required: false,
                            message: "Please Enter Your Notes!",
                          },
                        ]}
                      >
                        <TextArea
                          rows={4}
                          style={{
                            width: "100%",
                            height: "75px",
                            borderColor: "#d6d6d6",
                          }}
                          className="form-input"
                          placeholder="Please Enter Your Notes"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </BaseDrawer>
            )}

            {contactPopup && (
              <BaseModal
                title={
                  recordContactData !== ""
                    ? "Edit Contact"
                    : recordContactData === ""
                    ? "Add New Contact"
                    : ""
                }
                onCancel={() => {
                  this.setState({
                    contactPopup: false,
                    isCheckcontact: false,
                    materialCheck: false,
                    selectedMaterialType: "",
                    materialSelectedData: "",
                    quantityType: "Ton",
                    newRole: "",
                  });
                }}
                visible={contactPopup}
                destroyOnClose={true}
                formId="addContact"
                loading={this.state.loading}
                className="antmodal_grid headermodal"
                width={
                  window.screen.width > "501" && window.screen.width <= "991"
                    ? "25%"
                    : "500px"
                }
              >
                <Form
                  onFinish={this.addContact}
                  initialValues={{ remember: true }}
                  id="addContact"
                >
                  <Row gutter={[16, 0]}>
                    <Col span={12}>
                      <span className="title_changes">First Name</span>
                      <Form.Item
                        name={"fName"}
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Your First Name!",
                          },
                        ]}
                        initialValue={
                          recordContactData?.firstName
                            ? recordContactData?.firstName
                            : ""
                        }
                      >
                        <Input
                          className="form-input"
                          placeholder="Please Enter Your First Name"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <span className="title_changes">Last Name</span>
                      <Form.Item
                        name="lName"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter your Last Name!",
                          },
                        ]}
                        initialValue={
                          recordContactData?.lastName
                            ? recordContactData?.lastName
                            : ""
                        }
                      >
                        <Input
                          className="form-input"
                          placeholder="Please Enter your Last Name"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <span className="title_changes">Phone</span>
                      <Form.Item
                        name="phone"
                        rules={[
                          {
                            required: false,
                            message: "Please Enter Your Phone!",
                          },
                        ]}
                        initialValue={
                          recordContactData?.phone
                            ? recordContactData?.phone
                            : ""
                        }
                      >
                        <NumberFormat
                          className="ant-input-number numberictest"
                          format="(###) ###-####"
                          style={{ width: "100%" }}
                          placeholder="Please Enter Your Phone"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <span className="title_changes">Email</span>
                      <Form.Item
                        name="email"
                        rules={[
                          {
                            required: false,
                            message: "Please Enter Your Email!",
                          },
                          {
                            type: "email",
                            message: "The Input Is Not Valid Email",
                          },
                        ]}
                        initialValue={
                          recordContactData?.email
                            ? recordContactData?.email
                            : ""
                        }
                      >
                        <Input
                          className="form-input"
                          placeholder="Please Enter Your Email"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <span className="title_changes">Role</span>
                      <Form.Item
                        name="role"
                        rules={[
                          {
                            required: false,
                            message: "Please Enter Your Role!",
                          },
                        ]}
                        initialValue={
                          recordContactData?.contactRole &&
                          recordContactData?.contactRole
                        }
                      >
                        <Select
                          size="large"
                          showSearch
                          allowClear
                          className="select_Height"
                          // style={{ height: "40px" }}
                          placeholder="Please Select A Role"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          onChange={(value) => {
                            this.setState({ newRole: value });
                            value === "Others" &&
                              message.info("Please Input Your Role");
                          }}
                        >
                          {(quickLeadType === "Owner Operator"
                            ? this.props.context.ownerRoles
                            : this.props.context.rolesData
                          )?.map((role) => (
                            <Option key={role.id} value={role.label}>
                              {role.label}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    {newRole === "Add New Role" && (
                      <Col span={24}>
                        <span className="title_changes">Add New Role</span>
                        <Form.Item name="roleOther">
                          <Input
                            className="form-input"
                            placeholder="Please Enter Role"
                          />
                        </Form.Item>
                        <Checkbox
                          checked={this.state.isCheckcontact}
                          onChange={(e) =>
                            this.setState({
                              isCheckcontact: e.target.checked,
                            })
                          }
                          style={{ marginBottom: "18px" }}
                        >
                          <span
                            className="checkBox_size"
                            style={{ fontSize: "14px" }}
                          >
                            Add this new role to default list
                          </span>
                        </Checkbox>
                      </Col>
                    )}
                    <Col span={24}>
                      <span className="title_changes">Notes</span>
                      <Form.Item
                        name="notes"
                        rules={[
                          {
                            required: false,
                            message: "Please Enter Your Notes!",
                          },
                        ]}
                        initialValue={
                          recordContactData?.notes
                            ? recordContactData?.notes
                            : ""
                        }
                      >
                        <TextArea
                          rows={4}
                          style={{
                            width: "100%",
                            height: "75px",
                            borderColor: "#d6d6d6",
                          }}
                          className="form-input"
                          placeholder="Please Enter Your Notes"
                        />
                      </Form.Item>
                    </Col>
                    {(contactInfo?.filter((e) => e?.isPrimaryContact === true)
                      ?.length === 0 ||
                      contactInfo?.length === 0 ||
                      recordContactData?.isPrimaryContact === true) &&
                      quickLeadType === "Owner Operator" && (
                        <Col
                          xxl={{ span: 24 }}
                          xl={{ span: 24 }}
                          lg={{ span: 24 }}
                          md={{ span: 24 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                        >
                          {/* <span className="title_changes">Add this contact as primary</span> */}
                          <Checkbox
                            checked={this.state.primaryContactCheck}
                            onChange={(e) =>
                              this.setState({
                                primaryContactCheck: e.target.checked,
                              })
                            }
                            style={{ marginBottom: "18px" }}
                          >
                            <span
                              className="checkBox_size"
                              style={{ fontSize: "14px" }}
                            >
                              Add This Contact As Primary
                            </span>
                          </Checkbox>
                        </Col>
                      )}
                  </Row>
                </Form>
              </BaseModal>
            )}

            {successModal && (
              <BaseModal
                title={"Alert"}
                onCancel={() => {
                  this.setState({
                    successModal: false,
                  });
                }}
                visible={successModal}
                destroyOnClose={true}
                formId="successModal"
                loading={this.state.loading}
                className="antmodal_grid headermodal"
                width={"45%"}
                footer={null}
              >
                <Col
                  span={24}
                  style={{ textAlign: "center", marginBottom: "10px" }}
                >
                  {successData?.jobType && (
                    <div>
                      <Successrole />
                      <span
                        className="title_changes"
                        style={{
                          color: "#555",
                          fontWeight: "600",
                          display: "flex",
                          width: "100%",
                          justifyContent: "center",
                          margin: "20px 0px",
                        }}
                      >
                        New Job Added Successfully
                      </span>
                    </div>
                  )}
                  {successData?.isOwnerOperator === false && (
                    <div>
                      <Successpack />
                      <span
                        className="title_changes"
                        style={{
                          color: "#555",
                          fontWeight: "600",
                          display: "flex",
                          width: "100%",
                          justifyContent: "center",
                          margin: "20px 0px",
                        }}
                      >
                        New Customer Account Added Successfully
                      </span>
                    </div>
                  )}
                  {successData?.isOwnerOperator === true && (
                    <div>
                      <Successpack />
                      <span
                        className="title_changes"
                        style={{
                          color: "#555",
                          fontWeight: "600",
                          display: "flex",
                          width: "100%",
                          justifyContent: "center",
                          margin: "20px 0px",
                        }}
                      >
                        New Owner Operator Added Successfully
                      </span>
                    </div>
                  )}
                  {successData?.multiContact === false && (
                    <div>
                      <Trucksuccess />
                      <span
                        className="title_changes"
                        style={{
                          color: "#555",
                          fontWeight: "600",
                          display: "flex",
                          width: "100%",
                          justifyContent: "center",
                          margin: "20px 0px",
                        }}
                      >
                        New Driver Added Successfully
                      </span>
                    </div>
                  )}
                </Col>
                <Col
                  span={24}
                  style={{
                    // width: "100%",
                    display: "flex",
                    // flexDirection: "column",
                    justifyContent: "center",
                    // justifyContent:
                    //   successData?.jobNumber && successData?.jobNumber !== ""
                    //     ? "space-between"
                    //     : "space-around",
                    alignItems: "center",
                  }}
                >
                  <div className="btnalertflex">
                    {successData?.isOwnerOperator && (
                      <>
                        {/* <Col span={!successData?.jobType ? 12 : 8}> */}
                        <Button
                          type="ghost"
                          style={{
                            borderRadius: "5px",
                            height: "35px",
                            marginBottom: "5px",
                            // width: "100%",
                            // border:"none"
                          }}
                          onClick={this.onClickOwnerOperator}
                        >
                          View Owner Operator Profile
                        </Button>
                        {/* </Col> */}
                      </>
                    )}

                    {successData?.jobNumber &&
                      successData?.jobNumber !== "" && (
                        <>
                          {/* <Col span={8}> */}
                          <Button
                            type="ghost"
                            style={{
                              borderRadius: "5px",
                              height: "35px",
                              marginBottom: "5px",
                              // width: "100%",
                              // border:"none"
                            }}
                            onClick={this.onClickCustomerProfile}
                          >
                            View Customer Profile
                          </Button>
                          {/* </Col> */}
                          {/* <Col span={8}> */}
                          <Button
                            type="ghost"
                            style={{
                              borderRadius: "5px",
                              height: "35px",
                              marginBottom: "5px",
                              minWidth: "170px",
                              // border:"none"
                            }}
                            onClick={this.onClickJobProfile}
                          >
                            View Job Profile
                          </Button>
                          {/* </Col> */}
                        </>
                      )}

                    {successData?.isOwnerOperator === false && (
                      // <Col span={24}>
                      <>
                        <Button
                          type="ghost"
                          style={{
                            borderRadius: "5px",
                            height: "35px",
                            marginBottom: "5px",
                            // width: "100%",
                            // border:"none"
                          }}
                          onClick={this.onClickCompanyProfile}
                        >
                          View Customer Profile
                        </Button>
                      </>
                      // </Col>
                    )}

                    {successData?.multiContact === false && (
                      // <Col span={12}>.
                      <>
                        <Button
                          type="ghost"
                          style={{
                            borderRadius: "5px",
                            height: "35px",
                            marginBottom: "5px",
                            // width: "100%",
                            // border:"none"
                          }}
                          onClick={this.onClickTrucks}
                        >
                          View Truck Driver Database
                        </Button>
                      </>
                      // </Col>
                    )}

                    {/* <Col span={!successData?.jobType ? 12 : 8}> */}
                    <Button
                      type="primary"
                      style={{
                        borderRadius: "5px",
                        height: "35px",
                        marginBottom: "5px",
                        // width: "100%",
                        border: "none",
                      }}
                      onClick={() =>
                        this.setState({
                          successModal: false,
                        })
                      }
                    >
                      Stay On Current Page
                    </Button>
                    {/* </Col> */}
                  </div>
                </Col>
              </BaseModal>
            )}
          </Header>
        </Layout>
      </>
    );
  }
}

export default withAppContext(withRouter(MainHeader));
