import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Drawer,
  Form,
  Checkbox,
  Col,
  Button,
  Select,
  message,
  AutoComplete,
  // Row,
  InputNumber,
  Switch,
  Input,
  DatePicker,
  Row,
  // Radio,
} from "antd";
// import { MdDelete } from "react-icons/md";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
// import { add } from "../api/Apis";
// import appConfig from "../config/AppConfig";
import Loading from "./Loading";
import moment from "moment";
// import funnelStages from "../Json/funnel.json";
import QuarryJsonData from "../Json/quarryData.json";

const { RangePicker } = DatePicker;

const checkData = ["Manually Entered Data", "Imported Data"];

const jobType = [
  { label: "Trucking Job", value: "Trucking Job" },
  { label: "Trucking & Material Job", value: "Trucking & Material Job" },
];

const jobStatusData = [
  { label: "Job Lead", value: "Job Lead" },
  { label: "Contacted", value: "Contacted" },
  { label: "Build Quote", value: "Build Quote" },
  { label: "Quote Delivered", value: "Quote Delivered" },
  { label: "Quote Accepted", value: "Quote Accepted" },
  { label: "Dispatch Approved", value: "Dispatch Approved" },
  { label: "Paused", value: "Paused" },
  { label: "Closed", value: "Close Job" },
];

const accountsPayableData = [
  { label: "Quarry Bill", value: "Quarry Bill" },
  { label: "Trucking Bill", value: "Trucking Bill" },
];

const statusData = [
  {
    label: "Applicant",
  },
  {
    label: "Update Needed",
  },
  // {
  //   label: "Active Carrier",
  // },
];

const paymentStatus = [
  {
    label: "Paid",
  },
  {
    label: "Unpaid",
  },
  {
    label: "Overdue",
  },
];

const { Option } = Select || AutoComplete;

export default class Filter extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      checkedList: this.props.checkData || checkData,
      truckList: this.props.checkData || this.props?.trucksData,
      checkAll: true,
      indeterminate: true,
      selectedMaterial: this.props?.checkData || [],
      selectedCompanyId: "",
      finalMaterialData: [],
      quarryType: [],
      selectedJob: [],
      selectedMaterialType: "",
      materialSub: "",
      materialSize: "",
      quarryAddress: "",
      quarryLat: 0,
      quarryLang: 0,
      subCategory: [],
      materialValue: "",
      loading: false,
      filteredTrucksData: [],
      filterQuarryMaterial: [],
      ownerOperatorAddress: "",
      filteredStatus: [],
      checkAllStatus:
        this.props.filterPropTruckingData?.jobStatus?.length ===
        jobStatusData?.length
          ? true
          : false,
      truckingStatus: this.props.filterPropTruckingData?.jobStatus,
      accountsPayable: [],
      checkAllStatusOwner:
        this.props.filteredStatusData?.length === statusData?.length
          ? true
          : false,
      ownerStatus: this.props.filteredStatusData,
      carrierStatus: this.props.checkData,
      checkAllStatusTruck: true,
      checkAlldispatchStatus: true,
      jobs: false,
      selectedAddress: this.props.checkData
        ? this.props.checkData?.address
        : undefined,
      latitude: this.props.checkData
        ? this.props.checkData?.origin?.lat
        : undefined,
      longitude: this.props.checkData
        ? this.props.checkData?.origin?.long
        : undefined,
      funnelData: [
        "Uncontacted Lead",
        "Pending Carrier Packet + Insurance",
        "Pending Insurance",
        "Pending Carrier Packet",
        "Carrier Data Input",
        "Welcome Call",
        "Update Needed",
        // "Approved Carrier",
        "Archived Carrier",
        "Stalled Application",
      ],
      ownerFilterRadio: "",
      diameter: false,
      carrierData: [],
      emptyTrucks: false,
      truckTypesCheck: props.trucksData?.map((e) => e.value),
    };
  }

  static getDerivedStateFromProps = (props, state) => {
    if (JSON.stringify(state?.filteredStatus?.length === 0)) {
      return {
        filteredStatus: props.trucksData?.map((e) => e.value),
        carrierData:
          JSON.stringify(props?.carrierData) !==
          JSON.stringify(state?.carrierData)
            ? props.carrierData
            : state?.carrierData,
      };
    }
  };

  componentDidMount = () => {
    const {
      jobDatabase,
      billManagement,
      billData,
      accountsPayable,
      filterPropQuarryData,
      customer,
      driver,
      twistedOperator,
      dispatchToolFilter,
      ownerOperatorProfile,
      checkedData,
      restoreData,
      filterPropTruckingData,
      checkData,
      ownerOperator,
      dispatchArchive,
      QuarryTool,
      ticketProcessing,
      quarryProfile,
      dispatchStatus,
      dispatchTool,
      dispatchToolStatus
    } = this.props;
    if (billManagement) {
      this.formRef.current.setFieldsValue({
        dateRange: billData?.dateRange,
        vendorId: billData?.vendorId,
        materialId: billData?.materialId,
        min: billData?.min,
        max: billData?.max,
      });
    }

    if (this.props.customer && checkData) {
      this.formRef.current.setFieldsValue({
        radius: checkData.radius !== 0 ? checkData.radius : "",
        activity: checkData?.activity,
        accountStatus: checkData.accountStatus,
      });
      if (
        checkData?.lat !== undefined &&
        checkData?.lat !== "" &&
        checkData?.lng !== undefined &&
        checkData?.lng !== ""
      ) {
        this.setState({
          latitude: checkData?.lat,
          longitude: checkData?.lng,
        });
      }
    }

    if (jobDatabase) {
      this.formRef.current.setFieldsValue({
        dispatchCheckData:
          filterPropTruckingData?.dispatchCheckData !== undefined
            ? filterPropTruckingData?.dispatchCheckData
            : this.state.filteredStatus,
      });
    }

    if (dispatchTool || dispatchToolStatus) {
      this.formRef.current.setFieldsValue({
        dispatchCheckData:
          checkData !== undefined ? checkData : this.state.filteredStatus,
        dispatchStatus:
          checkData !== undefined ? checkData : dispatchStatus,
      });
      this.setState({
        checkAllStatusTruck:
          (checkData !== undefined && checkData?.length) ===
          this.state.filteredStatus?.length
            ? true
            : checkData === undefined
            ? true
            : false,
      });
    }
    if (filterPropTruckingData !== undefined && filterPropTruckingData !== "") {
      this.formRef.current.setFieldsValue({
        salesPersonName: filterPropTruckingData.salesPersonName,
        jobType: filterPropTruckingData.jobType?.filter((e) => e !== "Both"),
        company: filterPropTruckingData.company,
        jobStatus: filterPropTruckingData.jobStatus,
      });
      this.setState({
        truckingStatus: filterPropTruckingData.jobStatus,
        checkAllStatus:
          this?.props?.filterPropTruckingData?.jobStatus?.length ===
          jobStatusData?.length
            ? true
            : false,
      });
    }
    if (accountsPayable !== undefined && accountsPayable !== "") {
      this.formRef.current.setFieldsValue({
        billStatus: checkData,
      });
      this.setState({
        truckingStatus: checkData,
        checkAllStatus:
          checkData?.length === accountsPayableData?.length ? true : false,
      });
    }
    if (filterPropQuarryData !== undefined && filterPropQuarryData !== "") {
      this.setState({
        selectedMaterialType: filterPropQuarryData.category,
        materialSub: filterPropQuarryData.materialSub,
        materialSize: filterPropQuarryData.materialSize,
        quarryAddress: filterPropQuarryData.quarryAddress,
        diameter: filterPropQuarryData.diameter,
        subCategory: filterPropQuarryData.subCategoryId,
        filterQuarryMaterial: filterPropQuarryData.filterQuarryMaterial,
      });
      this.formRef.current.setFieldsValue({
        quarryType: filterPropQuarryData.quarryType,
        subCategory: filterPropQuarryData.subCategory,
        materialId: filterPropQuarryData.materialId,
        quarryId: filterPropQuarryData.quarryId,
        quarryLocation: filterPropQuarryData.quarryAddress,
        radius: filterPropQuarryData.radius,
        materialTest: filterPropQuarryData.materialTest,
        materialStatus: filterPropQuarryData.materialStatus,
        materialSupply: filterPropQuarryData.materialSupply,
        materialDiameter: filterPropQuarryData.materialDiameter,
        materialMinDiameter: filterPropQuarryData.materialMinDiameter,
        materialMaxDiameter: filterPropQuarryData.materialMaxDiameter,
        quarryMaterial: filterPropQuarryData.quarryMaterial,
      });
    }
    if (customer && checkData) {
      this.formRef.current.setFieldsValue({
        radius: checkData.radius !== 0 ? checkData.radius : "",
        activity: checkData?.activity,
        // openJobs: checkData.openJobs,
        // jobStatus: checkData.jobStatus,
        accountStatus: checkData.accountStatus,
      });
      if (
        checkData?.lat !== undefined &&
        checkData?.lat !== "" &&
        checkData?.lng !== undefined &&
        checkData?.lng !== ""
      ) {
        this.setState({
          latitude: checkData?.lat,
          longitude: checkData?.lng,
        });
      }
    }
    if (driver && checkData) {
      this.formRef.current.setFieldsValue({
        address: checkData.address,
        radius: checkData.radius !== 0 ? checkData.radius : "",
        dateAdded: checkData.dateAdded,
      });
    }
    if (dispatchToolFilter && checkData) {
      this.formRef.current.setFieldsValue({
        dispatchDate: checkData.dispatchDate,
        jobType: checkData.jobType,
      });
    }
    if (twistedOperator && checkData) {
      this.formRef.current.setFieldsValue({
        dispatchCheckData:
          checkData.dispatchCheckData !== undefined
            ? checkData.dispatchCheckData
            : this.state.filteredStatus,
        ownerOperatorCarrier_name: checkData.ownerOperatorCarrier_name,
        ownerOperatorRadius: checkData.ownerOperatorRadius,
        ownerOperatorLocation: checkData.ownerOperatorLocation,
        dispatchStatus: checkData.dispatchStatus,
      });
      this.setState({
        checkAllStatusTruck:
          checkData.dispatchCheckData?.length ===
          this.state.filteredStatus?.length
            ? true
            : checkData.dispatchCheckData === undefined
            ? true
            : false,
        checkAlldispatchStatus:
          checkData.dispatchStatus?.length !== 4 ? false : true,
        ownerOperatorAddress:
          this.props?.checkData?.ownerOperatorLocation !== undefined &&
          this.props?.checkData?.ownerOperatorLocation !== ""
            ? this.props?.checkData?.ownerOperatorLocation
            : this.state.ownerOperatorAddress,
        truckTypesCheck: checkData.dispatchCheckData,
      });
    }
    if (ownerOperator) {
      if (checkData !== "" && checkData !== undefined) {
        // this.formRef.current.setFieldsValue({
        //   activityStatus: checkData?.statusData,
        //   carrierStages: checkData?.stagesData,
        // });
        this.setState({
          // ownerFilterRadio: radioData,
          // ownerStatus: this.props?.checkData?.statusData,
          carrierStatus: this.props?.checkData,
          checkAllStatusOwner:
            this?.props?.checkData?.length === statusData?.length
              ? true
              : false,
          checkAllStageOwner:
            this.props?.checkData?.length === 9 ? true : false,
        });
      }
    }
    if (ownerOperatorProfile) {
      // console.log(checkData);
      if (checkData?.startAndEnddates !== undefined) {
        this.formRef.current.setFieldsValue({
          dateRange: [
            moment(checkData?.startAndEnddates?.startDate),
            moment(checkData?.startAndEnddates?.endDate),
          ],
        });
        this.setState({
          invoiceDateRange: checkData?.startAndEnddates,
        });
      }
      if (checkData?.truckNumbers !== undefined) {
        this.formRef.current.setFieldsValue({
          truckId: checkData?.truckNumbers,
        });
      }
      if (checkData?.paymentStatus !== undefined) {
        this.formRef.current.setFieldsValue({
          paymentStatus: checkData?.paymentStatus,
        });
      }
    }
    if (quarryProfile) {
      // console.log(checkData);
      if (checkData?.startAndEnddates !== undefined) {
        this.formRef.current.setFieldsValue({
          dateRange: [
            moment(checkData?.startAndEnddates?.startDate),
            moment(checkData?.startAndEnddates?.endDate),
          ],
        });
      }
      if (checkData?.materialType !== undefined) {
        this.formRef.current.setFieldsValue({
          materialType: checkData?.materialType,
        });
      }
      if (checkData?.paymentStatus !== undefined) {
        this.formRef.current.setFieldsValue({
          paymentStatus: checkData?.paymentStatus,
        });
      }
    }
    if (dispatchArchive) {
      if (checkData?.startAndEnddates !== undefined) {
        this.formRef.current.setFieldsValue({
          dateRange: [
            moment(checkData?.startAndEnddates?.startDate),
            moment(checkData?.startAndEnddates?.endDate),
          ],
        });
      }
    }
    if (QuarryTool) {
      // console.log(filterPropQuarryData);
      if (
        filterPropQuarryData?.origin?.lat !== undefined &&
        filterPropQuarryData?.origin?.lat !== "" &&
        filterPropQuarryData?.origin?.long !== undefined &&
        filterPropQuarryData?.origin?.long !== ""
      ) {
        this.setState({
          quarryLat: filterPropQuarryData?.origin?.lat,
          quarryLang: filterPropQuarryData?.origin?.long,
        });
      }
    }

    if (restoreData) {
      if (checkedData !== undefined) {
        this.formRef.current.setFieldsValue({
          selectedValue: checkedData?.selectedValue,
          deletedBy: checkedData?.deletedBy,
        });
      }
    }
    if (ticketProcessing) {
      if (checkedData !== undefined) {
        this.formRef.current.setFieldsValue({
          companyName: checkedData?.selectedValue,
          jobName: checkedData?.jobName,
          truckNumber: checkedData?.truckNumber,
        });
      }
    }
  };

  onFinishTrucking = (values) => {
    if (
      values?.company !== undefined ||
      values?.jobStatus?.length !== 0 ||
      values?.jobType !== undefined
    ) {
      values["jobStatus"] = this.state.truckingStatus;
      if (values?.jobType?.includes("Trucking & Material Job")) {
        values.jobType.push("Both");
      }
      this.props.filterResultData(values);
    } else {
      message.error("Please Select Atleast One Category To Filter");
    }
  };

  onFinish = () => {
    this.props.filterResultData(this.state.checkedList);
  };

  onFinishTruck = (values) => {
    if (this.props.twistedOperator) {
      let origin = {
        lat: this.state.latitude,
        long: this.state.longitude,
      };
      values["ownerOperatorLocation"] = this.state.ownerOperatorAddress;
      values["origin"] = origin;
      values["ownerOperatorRadius"] =
        values["ownerOperatorRadius"] !== undefined
          ? values["ownerOperatorRadius"]
          : 0;
      this.props.filterResultData(values);
    } else {
      this.props.filterResultData(values["dispatchCheckData"]);
    }
  };

  handleCheck = (list) => {
    this.setState({
      checkedList: list,
      indeterminate: !!list?.length && list?.length < checkData?.length,
      checkAll: list.length === checkData.length,
    });
  };

  // handleSubcategory = (e, option) => {
  //   const { selectedMaterialType } = this.state;
  //   console.log(option, "option", selectedMaterialType);
  //   let splitData = option?.value?.split(" ");
  //   this.setState({
  //     materialSub: e,
  //     materialSize: "",
  //   });
  //   if (selectedMaterialType === "Sand & Fill") {
  //     this.setState({
  //       subCategory: "SandAndFill - " + splitData[0],
  //     });
  //   } else if (selectedMaterialType === "Recycled & Other") {
  //     this.setState({
  //       subCategory: "RecycledAndOther - " + splitData[0],
  //     });
  //   } else {
  //     this.setState({
  //       subCategory:
  //         selectedMaterialType?.replaceAll(" ", "") + " - " + splitData[0],
  //     });
  //   }
  // };

  handleMaterialSize = (e, option) => {
    // console.log(option, e);
    this.setState({
      materialSize: e,
      materialValue: option.key,
    });
  };

  handleCheckTruck = (list) => {
    this.setState({
      truckList: list,
      indeterminate:
        list.length !== this.props?.trucksData.length ? true : false,
      checkAll: list.length === this.props?.trucksData.length ? true : false,
    });
  };

  handleStatus = (list, status) => {
    // console.log(list);
    this.setState({
      truckingStatus: list,
      statusIndeterminate: list.length !== status?.length ? true : false,
      checkAllStatus: list.length === status?.length ? true : false,
    });
  };

  handleAccountStatus = (list) => {
    this.setState({
      accountsPayable: list,
    });
  };

  handleStatusOwner = (list) => {
    this.setState({
      ownerStatus: list,
      statusIndeterminate: list.length !== jobStatusData?.length ? true : false,
      checkAllStatusOwner: list.length === jobStatusData?.length ? true : false,
    });
  };

  handleStageCarrier = (list) => {
    // console.log(list, "rerer");
    this.setState({
      carrierStatus: list,
    });
  };

  handleSearch = (value) => {
    let res = [];
    if (!value || value.indexOf("@") >= 0) {
      res = [];
    } else {
      res = ["gmail.com", "163.com", "qq.com"].map(
        (domain) => `${value}@${domain}`
      );
    }
    this.setState.setResult(res);
  };

  onCheckAllChange = (e) => {
    this.setState({
      checkedList: e.target.checked ? checkData : [],
      checkAll: e.target.checked,
      indeterminate: false,
    });
  };

  onCheckAllChangeTruck = (e) => {
    let data = [];
    this.props.trucksData.map((e) => data.push(e.value));
    this.setState({
      truckList: e.target.checked ? data : [],
      checkAll: e.target.checked,
      indeterminate: false,
    });
  };

  onCheckAllStatus = (e, StatusData) => {
    let data = [];
    StatusData.map((e) => data.push(e.value));
    this.setState({
      truckingStatus: e.target.checked ? data : [],
      statusIndeterminate: false,
      checkAllStatus: e.target.checked,
    });
  };

  onCheckAllStatusOwner = (e) => {
    let data = [];
    statusData?.map((e) => data.push(e.label));
    this.setState({
      ownerStatus: e.target.checked ? data : [],
      statusIndeterminate: false,
      checkAllStatusOwner: e.target.checked,
    });
  };

  onCheckAllStageOwner = (e) => {
    // console.log(e, "90909");
    let data = [];
    this.state.funnelData?.map((e) => data.push(e));
    this.setState({
      carrierStatus: e.target.checked ? data : [],
      statusIndeterminate: false,
      checkAllStageOwner: e.target.checked,
    });
  };

  onCheckAllStatusTruck = (e) => {
    const { filteredStatus } = this.state;
    this.setState({
      emptyTrucks: !e.target.checked,
    });
    if (e?.target?.checked) {
      this.formRef.current.setFieldsValue({
        dispatchCheckData: filteredStatus,
      });
      this.setState({
        checkAllStatusTruck: e.target.checked,
        truckTypesCheck: filteredStatus,
      });
    } else {
      this.formRef.current.setFieldsValue({
        dispatchCheckData: "",
      });
      this.setState({
        checkAllStatusTruck: e.target.checked,
        truckTypesCheck: [],
      });
    }
  };

  dispatchStatusTruck = (e) => {
    const { dispatchStatus } = this.props;
    if (e?.target?.checked) {
      this.formRef.current.setFieldsValue({
        dispatchStatus: dispatchStatus,
      });
      this.setState({
        checkAlldispatchStatus: e.target.checked,
      });
    } else {
      this.formRef.current.setFieldsValue({
        dispatchStatus: [],
      });
      this.setState({
        checkAlldispatchStatus: e.target.checked,
      });
    }
  };

  onFinishFilterQuarry = (values) => {
    const {
      // materialSize,
      // selectedMaterialType,
      quarryLat,
      quarryLang,
      quarryAddress,
      diameter,
      subCategory,
      filterQuarryMaterial,
      // subCategory,
      // materialSub,
      // materialValue,
    } = this.state;

    let data = {
      quarryType: values["quarryType"],
      origin: {
        lat: quarryLat,
        long: quarryLang,
      },
      quarryAddress,
      radius: values["radius"],
      materialDiameter: values["materialDiameter"],
      materialStatus: values["materialStatus"],
      materialSupply: values["materialSupply"],
      materialTest: values["materialTest"],
      materialMaxDiameter: values["materialMaxDiameter"],
      materialMinDiameter: values["materialMinDiameter"],
      diameter,
      subCategory: values["subCategory"],
      // category: selectedMaterialType,
      subCategoryId: subCategory,
      materialId: values["materialId"],
      quarryId: values["quarryId"],
      // materialValue,
      // materialSize,
      // materialSub,
      quarryMaterial: values["quarryMaterial"],
      filterQuarryMaterial,
    };
    this.props.filterResultData(data);
  };

  onSelectMaterial = (e) => {
    const { finalMaterialData, selectedMaterial } = this.state;
    let duplicateData =
      selectedMaterial?.length > 0 &&
      selectedMaterial.filter((s) => s?.id === e);
    if (duplicateData?.length > 0) {
      this.formRef.current.setFieldsValue({
        material: "",
      });
      message.info("Given Material Is Already Selected");
      return true;
    } else {
      let materialData = finalMaterialData?.filter((m) => m?.id === e);
      if (materialData?.length > 0) {
        this.formRef.current.setFieldsValue({
          material: "",
        });
      }
      let materialInfo =
        this.state?.selectedMaterial?.length > 0
          ? this.state?.selectedMaterial
          : [];
      materialInfo.push(materialData[0]);
      this.setState({
        selectedMaterial: materialInfo,
      });
    }
  };

  deleteMaterial = (id) => {
    let materialInfo = this.state.selectedMaterial.filter((c) => c.id !== id);
    this.setState({
      selectedMaterial: materialInfo,
    });
    message.info("Material Deleted Successfully!");
  };

  onClearFilter = () => {
    if (this.props?.QuarryTool || this.props?.imageDatabase) {
      this.props?.onClearFilter();
      this.setState({ selectedMaterial: [], selectedCustomer: [] });
    } else if (this.props?.truckingMaterial) {
      this.props?.onClearFilter();
      this.setState({
        selectedCustomer: [],
      });
    } else if (this.props.ownerDatabase) {
      this.props?.onClearFilter();
      this.setState({ checkedList: [] });
    } else if (this.props.customer || this.props.driver) {
      this.props.onClearFilter();
      this.setState({ filteredTrucksData: [] });
    } else if (this.props.ownerOperator) {
      this.props.onClearFilter();
    } else if (this.props.ownerOperatorProfile) {
      this.props?.onClickClearFilter();
    } else if (this.props.ticketProcessing) {
      this?.props?.onClearFilter();
    } else if (this.props.restoreData) {
      this?.props?.onClearFilter();
    } else {
      this.props?.onClickClearFilter();
      this.setState({ truckList: [] });
    }
  };

  onSelectCompany = (e) => {
    this.setState({ selectedCompanyId: e });
  };

  getAddress = (address) => {
    this.setState({
      selectedAddress: address.label,
    });
  };

  handleSelect = (address) => {
    this.setState({
      quarryAddress: address.label,
    });

    geocodeByAddress(address.label)
      .then((results) => {
        getLatLng(results[0]).then(({ lat, lng }) =>
          this.setState({
            quarryLat: lat,
            quarryLang: lng,
            latitude: lat,
            longitude: lng,
          })
        );
      })
      .catch((error) => console.error("Error", error));
  };

  onFinishDriver = (values) => {
    // console.log(values);
    if (this.props?.customer) {
      let data = {
        address: this.state.selectedAddress,
        lat: this.state.latitude,
        lng: this.state.longitude,
        radius:
          values["radius"] !== "" && values["radius"] !== undefined
            ? values["radius"]
            : 0,
        // activity: values["activity"],
        // openJobs: this.state.jobs,
        accountStatus: values["accountStatus"],
      };
      this.props.filterResultData(data);
    } else {
      if (
        (this.state.selectedAddress === undefined &&
          values["radius"] !== undefined &&
          values["radius"] !== "" &&
          values["radius"] !== null &&
          values["dateAdded"] === undefined) ||
        (this.state.selectedAddress === undefined &&
          values["radius"] !== undefined &&
          values["radius"] !== "" &&
          values["radius"] !== null &&
          values["dateAdded"] !== undefined)
      ) {
        message.info("Please Enter Home City");
      } else {
        let data = {
          address: this.state.selectedAddress,
          origin: {
            lat: this.state.latitude,
            long: this.state.longitude,
          },
          radius:
            values["radius"] !== "" && values["radius"] !== undefined
              ? values["radius"]
              : 0,
          dateAdded: values["dateAdded"],
        };
        // console.log(data);
        this.props.filterResultData(data);
      }
    }
  };

  onFinishRestore = (values) => {
    this.props.filterResultData(values);
  };

  onFinishTicket = (values) => {
    this.props?.filterResultData(values);
  };

  onFinishOwnerOperator = (values) => {
    const { carrierStatus } = this.state;
    this.props.filterResultData(carrierStatus, "Carrier Stages");

    //  if (ownerFilterRadio === "Activity Status") {
    //  let data = {
    //     statusData: ownerStatus,
    //     stagesData: [],
    //   };
    //   this.props.filterResultData(data, "Activity Status");
    // } else if (ownerFilterRadio === "Carrier Stages") {
    //   let data = {
    //     statusData: [],
    //     stagesData: carrierStatus,
    //   };
    //   this.props.filterResultData(data, "Carrier Stages");
    // } else {
    //   message.info("Please Select Atleast One Status");
    // }
  };

  onFinishOwnerOperatorProfile = (values) => {
    let data = {
      startAndEnddates: this.state.invoiceDateRange,
      truckNumbers: values["truckId"],
      paymentStatus: values["paymentStatus"],
    };
    // console.log(data);
    if (
      data?.truckNumbers === undefined &&
      data?.startAndEnddates === undefined &&
      data?.paymentStatus === undefined
    ) {
      message.info("Please Enter Atleast One Category To Filter");
    } else {
      this.props.filterResultData(data);
    }
  };

  onFinishQuarryProfile = (values) => {
    let data = {
      startAndEnddates: this.state.invoiceDateRange,
      materialType: values["materialType"],
      paymentStatus: values["paymentStatus"],
    };
    // console.log(data);
    if (
      data?.materialType === undefined &&
      data?.startAndEnddates === undefined &&
      data?.paymentStatus === undefined
    ) {
      message.info("Please Enter Atleast One Category To Filter");
    } else {
      this.props.filterResultData(data);
    }
  };

  onFinishBill = (values) => {
    if (values["min"] >= values["max"]) {
      return message.error("Max range should be more than min range!");
    }
    if (
      (values["min"] === undefined && values["max"] === undefined) ||
      (values["min"] !== undefined && values["max"] !== undefined)
    ) {
      this.props.filterResultData(values);
    } else if (values["min"] === undefined || values["max"] === undefined) {
      return message.error("Please enter amount range!");
    }
  };

  onFinishDispatchArchive = () => {
    let data = {
      startAndEnddates: this.state.invoiceDateRange,
    };
    this.props.filterResultData(data);
  };

  onFinishFilterData = (values) => {
    if (values?.jobType?.includes("Trucking & Material Job")) {
      values.jobType.push("Both");
    }
    this.props.filterResultData(values);
  };

  dateRange = (e) => {
    // console.log(e);
    let data = {
      entityData: {
        startDate: moment(e[0]).format("L"),
        endDate: moment(e[1]).format("L"),
      },
    };
    // console.log(data["entityData"], "data");

    this.setState({
      invoiceDateRange: data["entityData"],
    });
  };

  onCheckAllStatusTrucks = (e) => {
    this.setState({
      emptyTrucks: !e?.length,
    });
    this.setState({
      truckTypesCheck: e,
    });
    if (e?.length < 5) {
      this.setState({
        checkAllStatusTruck: false,
      });
    }
  };

  onChangeStatus = (e) => {
    if (e?.length === 4) {
      this.setState({
        checkAlldispatchStatus: true,
      });
    } else {
      this.setState({
        checkAlldispatchStatus: false,
      });
    }
  };

  onFinishData = (values) => {
    this.props.filterResultData(values);
  };

  onFinishAccountsData = (values) => {
    this.props.filterResultData(this.state.truckingStatus);
  };

  resetvendorValues = () => {
    this.setState({
      topVendorsData: [],
      selectedRowKeys: [],
    });
  };

  handleFilter = () => {
    let uniqueValues = [];
    if (this.props.activeKey === "0") {
      uniqueValues = [
        ...new Set(
          this.props?.restoreCustomerName?.map((e) => e?.quarryName) || []
        ),
      ];
      return uniqueValues;
    } else if (this.props.activeKey === "1") {
      uniqueValues = [
        ...new Set(
          this.props?.restoreCustomerName?.map((e) => e?.firstName) || []
        ),
      ];
      return uniqueValues;
    } else if (this.props.activeKey === "2") {
      uniqueValues = [
        ...new Set(
          this.props?.restoreCustomerName?.map((e) => e?.carrier_name) || []
        ),
      ];
      return uniqueValues;
    } else {
      return uniqueValues;
    }
  };

  handleDeletedByFilter = () => {
    let uniqueDeletedBy = [];

    if (this.props.activeKey === "0") {
      uniqueDeletedBy = [
        ...new Set(
          this.props?.restoreCustomerName?.map((e) => e?.deletedByName) || []
        ),
      ];
      return uniqueDeletedBy;
    } else if (this.props.activeKey === "1") {
      uniqueDeletedBy = [
        ...new Set(
          this.props?.restoreCustomerName?.map((e) => e?.deletedByName) || []
        ),
      ];
      return uniqueDeletedBy;
    } else if (this.props.activeKey === "2") {
      uniqueDeletedBy = [
        ...new Set(
          this.props?.restoreCustomerName?.map((e) => e?.deletedByName) || []
        ),
      ];
      return uniqueDeletedBy;
    } else {
      return uniqueDeletedBy;
    }
  };

  findFilter = (fieldVal) => {
    const { totalData } = this.props;
    const result = totalData?.filter(
      (value, index, self) =>
        self.findIndex((e) => e[fieldVal] === value[fieldVal]) === index
    );
    return result;
  };

  render() {
    const {
      checkAlldispatchStatus,
      truckTypesCheck,
      diameter,
      checkedList,
      checkAllStatus,
      checkAll,
      indeterminate,

      quarryAddress,
      truckingStatus,

      loading,
      ownerOperatorAddress,
      checkAllStatusTruck,
      carrierStatus,
      funnelData,
      checkAllStageOwner,
      filterQuarryMaterial,
      subCategory,
      carrierData,
      emptyTrucks,
    } = this.state;
    const {
      materialCategory,
      activeKey,
      checkData,
      jobDatabase,
      billManagement,
      billData,
      vendorData,
      dispatchToolStatus,
      dispatchStatus,
      showMapFilter,
    } = this.props;
    return (
      <div>
        <Loading enableLoading={loading} />
        <Drawer
          title="FILTER"
          placement="right"
          maskClosable={this.props.ticketProcessing}
          // mask={false}
          onClose={() => {
            this.props.closeFilter();
          }}
          visible={this.props.visible}
          className="drawerbox headerdrawer"
          footer={
            <Button
              htmlType="submit"
              // onReset={true}
              style={{
                height: "45px",
                width: "100%",
                backgroundColor: "#1e90ff40",
                border: "none",
                color: "#000",
              }}
              form="filterform"
            >
              <span style={{ fontSize: "16px", fontWeight: "500" }}>
                Show Result
              </span>
            </Button>
          }
        >
          {this.props?.accountsPayable && (
            <Form
              onFinish={this.onFinishAccountsData}
              ref={this.formRef}
              id="filterform"
            >
              <Col span={24} className="righttextfilter">
                <span>
                  <Link onClick={this.onClearFilter}>Clear Filter</Link>
                </span>
              </Col>

              <Col span={24}>
                {/* <span className="title_changes">Invoice Status</span> */}
                <Form.Item
                  name="billStatus"
                  rules={[
                    {
                      required: false,
                      message: "Please Enter Your Job Status!",
                    },
                  ]}
                  initialValue={truckingStatus}
                >
                  <Checkbox
                    onChange={(e) =>
                      this.onCheckAllStatus(e, accountsPayableData)
                    }
                    checked={checkAllStatus}
                    style={{
                      marginTop: "10px",
                      borderBottom: "2px solid #9a9a9a",
                      paddingBottom: "5px",
                    }}
                  >
                    <span className="checkBox_size">Select All</span>
                  </Checkbox>
                  <Checkbox.Group
                    style={{ width: "100%" }}
                    onChange={(e) => this.handleStatus(e, accountsPayableData)}
                    value={truckingStatus}
                  >
                    {accountsPayableData?.map((d) => (
                      <Col
                        span={24}
                        style={{ marginBottom: "10px", marginTop: "10px" }}
                      >
                        <Checkbox value={d.value} key={d.value}>
                          <span className="checkBox_size">{d.label}</span>
                        </Checkbox>
                      </Col>
                    ))}
                  </Checkbox.Group>
                </Form.Item>
              </Col>
            </Form>
          )}
          {this.props?.revenueAnalysis && (
            <Form
              onFinish={this.onFinishData}
              ref={this.formRef}
              id="filterform"
            >
              <Col span={24} className="righttextfilter">
                <span>
                  <Link onClick={this.onClearFilter}>Clear Filter</Link>
                </span>
              </Col>

              <Col span={24}>
                <span className="title_changes">Customer Name</span>
                <Form.Item
                  name="company"
                  rules={[
                    {
                      required: false,
                      message: "Please Enter Your Customer Name!",
                    },
                  ]}
                  initialValue={this?.props?.checkData?.company}
                >
                  <Select
                    showSearch
                    allowClear
                    className="select_Height"
                    style={{ height: "40px" }}
                    // onChange={this.onSelectCompany}
                    placeholder="Please Select A Customer Account"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children !== undefined &&
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {this.props?.company?.map((eachItem) => (
                      <Option
                        key={eachItem?.companyId}
                        value={eachItem?.companyId}
                        className="option_style"
                      >
                        {eachItem?.companyName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Form>
          )}
          {(this.props?.salesPersonFilter || this.props.dispatchToolFilter) && (
            <Form
              onFinish={this.onFinishFilterData}
              ref={this.formRef}
              id="filterform"
            >
              <Col span={24} className="righttextfilter">
                <span>
                  <Link onClick={this.onClearFilter}>Clear Filter</Link>
                </span>
              </Col>

              {this.props.dispatchToolFilter && (
                <>
                  <Col span={24}>
                    <span className="title_changes">Dispatch Date</span>
                    <Form.Item
                      name="dispatchDate"
                      rules={[
                        {
                          required: false,
                          message: "Please Enter Date!",
                        },
                      ]}
                    >
                      <DatePicker
                        className="form-input"
                        style={{ width: "100%" }}
                        placeholder="Please enter your Dispatch Date"
                        format={"MM/DD/YYYY"}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <span className="title_changes">Job Type</span>
                    <Form.Item
                      name="jobType"
                      rules={[
                        {
                          required: false,
                          message: "Please Enter Your Job Type!",
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        showSearch
                        allowClear
                        style={{ height: "40px" }}
                        className="select_multiplequarytol"
                        placeholder="Please Select A Job Type"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      >
                        {jobType
                          ?.filter((e) => e !== "Both")
                          ?.map((eachItem) => (
                            <Option
                              key={eachItem.label}
                              value={eachItem.label}
                              className="option_style"
                            >
                              {eachItem.label}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </>
              )}

              {this.props?.salesPersonFilter && (
                <Col span={24}>
                  <span className="title_changes">Sales Person</span>
                  <Form.Item
                    name="salesPerson"
                    rules={[
                      {
                        required: false,
                        message: "Please input your salesPerson!",
                      },
                    ]}
                    initialValue={this.props?.checkData?.salesPerson}
                  >
                    <Select
                      showSearch
                      allowClear
                      style={{ height: "40px" }}
                      className="select_Height"
                      placeholder="Please Select A Sales Person"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {this.props.filterSalesPerson?.map((eachItem) =>
                        this?.props?.salesData?.map(
                          (element) =>
                            eachItem?.salesPersonId === element.id && (
                              <Option
                                key={eachItem.salesPersonId}
                                value={eachItem.salesPersonId}
                                className="option_style"
                              >
                                {element.firstName + " " + element.lastName}
                              </Option>
                            )
                        )
                      )}
                    </Select>
                  </Form.Item>
                </Col>
              )}
              <Col span={24}>
                <span className="title_changes">Company Name</span>
                <Form.Item
                  name="salesCompanyName"
                  rules={[
                    {
                      required: false,
                      message: "Please input your companyName!",
                    },
                  ]}
                  initialValue={this.props?.checkData?.salesCompanyName}
                >
                  <Select
                    showSearch
                    allowClear
                    style={{ height: "40px" }}
                    className="select_Height"
                    placeholder="Please Select A companyName"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {this.props.filterData?.map((eachItem) => (
                      <Option
                        key={eachItem.id}
                        value={eachItem.companyId}
                        className="option_style"
                      >
                        {eachItem.companyName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Form>
          )}

          {(this.props?.dispatchTool || this.props.twistedOperator) && (
            <Form
              onFinish={this.onFinishTruck}
              id="filterform"
              ref={this.formRef}
            >
              <Col span={24} className="righttextfilter">
                <span>
                  <Link onClick={this.onClearFilter}>Clear Filter</Link>
                </span>
              </Col>
              <span className="title_changes" style={{ fontWeight: "bold" }}>
                Select Truck Type
              </span>
              <Col span={24}>
                <Checkbox
                  onChange={this.onCheckAllStatusTruck}
                  checked={checkAllStatusTruck}
                  style={{
                    marginTop: "10px",
                    borderBottom: "2px solid #9a9a9a",
                    paddingBottom: "5px",
                  }}
                >
                  <span className="checkBox_size">Select All</span>
                </Checkbox>
              </Col>
              <Form.Item
                name="dispatchCheckData"
                rules={[
                  {
                    required: false,
                    message: "Please Enter Your checkData!",
                  },
                ]}
              >
                <Checkbox.Group
                  onChange={this.onCheckAllStatusTrucks}
                  style={{ width: "100%" }}
                >
                  {this.props?.trucksData?.map((d) => (
                    <Col
                      span={24}
                      style={{ marginBottom: "10px", marginTop: "10px" }}
                    >
                      <Checkbox value={d?.value}>
                        <span className="checkBox_size">{d.label}</span>
                      </Checkbox>
                    </Col>
                  ))}
                </Checkbox.Group>
              </Form.Item>
              {dispatchToolStatus && !showMapFilter && (
                <Col span={24}>
                  <span
                    className="title_changes"
                    style={{ fontWeight: "bold" }}
                  >
                    Select Status
                  </span>
                  <Col span={24}>
                    <Checkbox
                      onChange={this.dispatchStatusTruck}
                      checked={checkAlldispatchStatus}
                      style={{
                        marginTop: "10px",
                        borderBottom: "2px solid #9a9a9a",
                        paddingBottom: "5px",
                      }}
                    >
                      <span className="checkBox_size">Select All</span>
                    </Checkbox>
                  </Col>
                  <Form.Item
                    name="dispatchStatus"
                    rules={[
                      {
                        required: false,
                        message: "Please Enter Your status!",
                      },
                    ]}
                  >
                    <Checkbox.Group
                      style={{ width: "100%" }}
                      onChange={this.onChangeStatus}
                    >
                      {dispatchStatus?.map((d) => (
                        <Col
                          span={24}
                          style={{ marginBottom: "10px", marginTop: "10px" }}
                        >
                          <Checkbox value={d}>
                            <span className="checkBox_size">{d}</span>
                          </Checkbox>
                        </Col>
                      ))}
                    </Checkbox.Group>
                  </Form.Item>
                </Col>
              )}

              {this.props.twistedOperator && (
                <>
                  <Col span={24}>
                    <span className="title_changes">Carrier Name</span>
                    <Form.Item
                      name="ownerOperatorCarrier_name"
                      rules={[
                        {
                          required: false,
                          message: "Please Enter Your Carrier_name!",
                        },
                      ]}
                    >
                      <Select
                        size="large"
                        showSearch
                        allowClear
                        className="select_Height"
                        placeholder="Please Select A Carrier Name"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        style={{ width: "100%" }}
                      >
                        {showMapFilter
                          ? !emptyTrucks &&
                            [
                              ...new Map(
                                this.props.carrierData
                                  ?.filter((d) =>
                                    truckTypesCheck?.includes(d.truck_type)
                                  )
                                  .map((item) => [item["id"], item])
                              ).values(),
                            ]?.map((d, index) => (
                              <Option key={index} value={d.id}>
                                {d.carrier_name}
                              </Option>
                            ))
                          : !emptyTrucks &&
                            [
                              ...new Map(
                                carrierData
                                  ?.filter((d) =>
                                    truckTypesCheck?.includes(d.truck_type)
                                  )
                                  .map((item) => [item["id"], item])
                              ).values(),
                            ]?.map((d, index) => (
                              <Option key={index} value={d.id}>
                                {d.carrier_name}
                              </Option>
                            ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <span className="title_changes">Parking City</span>
                    <Form.Item
                      name="ownerOperatorLocation"
                      rules={[
                        {
                          required: false,
                          message: "Please Enter Your Location!",
                        },
                      ]}
                    >
                      <GooglePlacesAutocomplete
                        className="form-input"
                        autocompletionRequest={{
                          componentRestrictions: {
                            country: ["us"],
                          },
                        }}
                        selectProps={{
                          defaultInputValue: `${
                            this.props?.checkData?.ownerOperatorLocation !==
                              "" &&
                            this.props?.checkData?.ownerOperatorLocation !==
                              undefined
                              ? this.props?.checkData?.ownerOperatorLocation
                              : ownerOperatorAddress
                          }`,

                          placeholder: "Search Address...",
                          onChange: (address) => {
                            this.setState({
                              ownerOperatorAddress: address.label,
                            });
                            this.handleSelect(address);
                          },
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <span className="title_changes">Radius</span>
                    <Form.Item
                      name="ownerOperatorRadius"
                      rules={[
                        {
                          required: false,
                          message: "Please Enter Your Radius!",
                        },
                      ]}
                    >
                      <InputNumber
                        placeholder="Please Enter Your Radius"
                        addonAfter={"Miles"}
                        style={{
                          height: "40px",
                          width: "100%",
                        }}
                      />
                    </Form.Item>
                  </Col>
                </>
              )}
            </Form>
          )}

          {this.props?.truckingMaterial && (
            <Form
              onFinish={this.onFinishTrucking}
              id="filterform"
              ref={this.formRef}
            >
              <Col span={24} className="righttextfilter">
                <span>
                  <Link onClick={this.onClearFilter}>Clear Filter</Link>
                </span>
              </Col>

              <Col span={24}>
                <span className="title_changes">Job Type</span>
                <Form.Item
                  name="jobType"
                  rules={[
                    {
                      required: false,
                      message: "Please Enter Your Job Type!",
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    showSearch
                    allowClear
                    style={{ height: "40px" }}
                    className="select_multiplequarytol"
                    placeholder="Please Select A Job Type"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {jobType
                      .filter((e) => e !== "Both")
                      ?.map((eachItem) => (
                        <Option
                          key={eachItem.label}
                          value={eachItem.label}
                          className="option_style"
                        >
                          {eachItem.label}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={24}>
                <span className="title_changes">Customer Name</span>
                <Form.Item
                  name="company"
                  rules={[
                    {
                      required: false,
                      message: "Please Enter Your Customer Name!",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    allowClear
                    className="select_Height"
                    style={{ height: "40px" }}
                    // onChange={this.onSelectCompany}
                    placeholder="Please Select A Customer Account"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children !== undefined &&
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {this.props?.company?.map((eachItem) => (
                      <Option
                        key={eachItem?.companyId}
                        value={eachItem?.companyId}
                        className="option_style"
                      >
                        {eachItem?.companyName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              {!jobDatabase && (
                <Col span={24}>
                  <span className="title_changes">Sales Person Name</span>
                  <Form.Item
                    name="salesPersonName"
                    rules={[
                      {
                        required: false,
                        message: "Please Enter Your Customer Name!",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      allowClear
                      className="select_Height"
                      style={{ height: "40px" }}
                      // onChange={this.onSelectCompany}
                      placeholder="Please Select A Customer Account"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children !== undefined &&
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {this.props?.salesPersonName?.map((eachItem) => (
                        <Option
                          key={eachItem?.id}
                          value={eachItem?.id}
                          className="option_style"
                          style={{
                            textTransform: "capitalize",
                            color: "#474747",
                            fontSize: "16px",
                            fontFamily: "sans-serif",
                          }}
                        >
                          {eachItem?.firstName + " " + eachItem?.lastName}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}
              {!this.props.dispatchToolFilter && !jobDatabase && (
                <Col span={24}>
                  <span className="title_changes">Job Status</span>
                  <Form.Item
                    name="jobStatus"
                    rules={[
                      {
                        required: false,
                        message: "Please Enter Your Job Status!",
                      },
                    ]}
                    initialValue={truckingStatus}
                  >
                    <Checkbox
                      onChange={(e) => this.onCheckAllStatus(e, jobStatusData)}
                      checked={checkAllStatus}
                      style={{
                        marginTop: "10px",
                        borderBottom: "2px solid #9a9a9a",
                        paddingBottom: "5px",
                      }}
                    >
                      <span className="checkBox_size">Select All</span>
                    </Checkbox>
                    <Checkbox.Group
                      style={{ width: "100%" }}
                      onChange={(e) => this.handleStatus(e, jobStatusData)}
                      value={truckingStatus}
                    >
                      {jobStatusData?.map((d) => (
                        <Col
                          span={24}
                          style={{ marginBottom: "10px", marginTop: "10px" }}
                        >
                          <Checkbox value={d.value}>
                            <span className="checkBox_size">{d.label}</span>
                          </Checkbox>
                        </Col>
                      ))}
                    </Checkbox.Group>
                  </Form.Item>
                </Col>
              )}
            </Form>
          )}

          {this.props?.ownerDatabase && (
            <Form onFinish={this.onFinish} id="filterform">
              <Col span={24} className="righttextfilter">
                <span>
                  <Link onClick={this.onClearFilter}>Clear Filter</Link>
                </span>
              </Col>
              <span className="title_changes" style={{ fontWeight: "bold" }}>
                Operators Classification
              </span>
              <Form.Item name="checkData">
                <Checkbox
                  indeterminate={indeterminate}
                  onChange={this.onCheckAllChange}
                  checked={checkAll}
                  style={{ marginTop: "10px" }}
                >
                  <span className="checkBox_size">Total Data</span>
                </Checkbox>
                <Checkbox.Group
                  style={{ width: "100%" }}
                  onChange={this.handleCheck}
                  value={checkedList}
                >
                  {checkData.map((d) => (
                    <Col
                      span={24}
                      style={{ marginBottom: "10px", marginTop: "10px" }}
                    >
                      <Checkbox value={d}>
                        <span className="checkBox_size">{d}</span>
                      </Checkbox>
                    </Col>
                  ))}
                </Checkbox.Group>
              </Form.Item>
            </Form>
          )}

          {(this.props?.QuarryTool || this.props?.imageDatabase) && (
            <>
              <Form
                onFinish={this.onFinishFilterQuarry}
                ref={this.formRef}
                id="filterform"
              >
                <Col span={24} className="righttextfilter">
                  <span>
                    <Link onClick={this.onClearFilter}>Clear Filter</Link>
                  </span>
                </Col>

                <Col span={24} style={{ marginBottom: "1vw" }}>
                  <span className="title_changes">
                    {" "}
                    {this.props?.imageDatabase
                      ? "Main Material"
                      : "Material Type"}
                  </span>
                  <Form.Item
                    name="quarryType"
                    rules={[
                      {
                        required: false,
                        message: "Please Enter Material Type!",
                      },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      showSearch
                      allowClear
                      style={{ height: "40px" }}
                      className="select_multiplequarytol"
                      placeholder="Please Select A Material Type"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onChange={(e, option) => {
                        this.setState({
                          filterQuarryMaterial: option,
                        });
                      }}
                    >
                      {Object.keys(materialCategory)?.map((eachItem) => (
                        <Option
                          key={eachItem}
                          value={
                            Object.values(materialCategory[eachItem])[0]
                              ?.categoryKey
                          }
                          className="option_style"
                        >
                          {eachItem}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={24} style={{ marginBottom: "1vw" }}>
                  <span className="title_changes">
                    {this.props?.imageDatabase
                      ? "Material Subcategory"
                      : "Sub Category 1"}
                  </span>
                  <Form.Item
                    name="subCategory"
                    rules={[
                      {
                        required: false,
                        message: "Please Enter Sub Category!",
                      },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      showSearch
                      allowClear
                      style={{ height: "40px" }}
                      className="select_multiplequarytol"
                      // className="select_Height"
                      placeholder="Please Select A Sub Category"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onChange={(e, option) => {
                        this.setState({
                          subCategory: option,
                        });
                      }}
                    >
                      {filterQuarryMaterial?.map((e) =>
                        Object.values(materialCategory[e.key])?.map((c) => (
                          <Option
                            value={c?.subcat}
                            subTypes={c?.subTypes}
                            key={c.subTypes?.map((a) => a.id)}
                            className="option_style"
                          >
                            {c.subcat}
                          </Option>
                        ))
                      )}
                    </Select>
                  </Form.Item>
                </Col>

                {this.props.imageDatabase && (
                  <>
                    <Col span={24} style={{ marginBottom: "10px" }}>
                      <span className="title_changes">Specific Material</span>
                      <Form.Item
                        name="materialId"
                        rules={[
                          {
                            required: false,
                            message: "Please Enter Your Material!",
                          },
                        ]}
                      >
                        <Select
                          mode="multiple"
                          size="large"
                          showSearch
                          allowClear
                          className="select_multiplequarytol"
                          placeholder="Please Select A Material"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          // value={materialSub}
                          style={{ width: "100%" }}
                        >
                          {subCategory?.map((l) =>
                            l.subTypes?.map(
                              (c) =>
                                c.subType !== "" && (
                                  <Option key={c.id} value={c.id}>
                                    {c.subType}
                                  </Option>
                                )
                            )
                          )}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col span={24} style={{ marginBottom: "10px" }}>
                      <span className="title_changes">Specific Quarry</span>
                      <Form.Item
                        name="quarryId"
                        rules={[
                          {
                            required: false,
                            message: "Please Enter Your Material!",
                          },
                        ]}
                      >
                        <Select
                          mode="multiple"
                          size="large"
                          showSearch
                          allowClear
                          className="select_multiplequarytol"
                          placeholder="Please Select A Quarry"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          style={{ width: "100%" }}
                        >
                          {this.props.quarryData.map((c) => (
                            <Option key={c.id} value={c.id}>
                              {c.quarryName}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </>
                )}
                {!this.props?.imageDatabase && (
                  <>
                    <Col span={24}>
                      <span className="title_changes">Near Address</span>
                      <Form.Item
                        name="quarryLocation"
                        rules={[
                          {
                            required: false,
                            message: "Please Enter Your Location!",
                          },
                        ]}
                        initialValue={quarryAddress}
                      >
                        <GooglePlacesAutocomplete
                          className="form-input"
                          autocompletionRequest={{
                            componentRestrictions: {
                              country: ["us"],
                            },
                          }}
                          selectProps={{
                            quarryAddress,
                            defaultInputValue: `${
                              this.props?.filterPropQuarryData
                                ?.quarryAddress !== "" &&
                              this.props?.filterPropQuarryData
                                ?.quarryAddress !== undefined
                                ? this.props?.filterPropQuarryData
                                    ?.quarryAddress
                                : quarryAddress
                            }`,

                            placeholder: "Search Address...",
                            onChange: (address) => {
                              this.handleSelect(address);
                              // this.getAddress(address);
                            },
                          }}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <span className="title_changes">Search Radius</span>
                      <Form.Item
                        name="radius"
                        rules={[
                          {
                            required: false,
                            message: "Please Enter Your Radius!",
                          },
                        ]}
                      >
                        <InputNumber
                          placeholder="Please Enter Your Radius"
                          addonAfter={"Miles"}
                          style={{
                            height: "40px",
                            width: "100%",
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </>
                )}

                <Col span={24}>
                  <span className="title_changes">Testing</span>
                  <Form.Item
                    name="materialTest"
                    rules={[
                      {
                        required: false,
                        message: "Please Enter Testing!",
                      },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      size="large"
                      showSearch
                      allowClear
                      className="select_multiplequarytol"
                      placeholder="Please Select A testMaterial"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onChange={this.resetvendorValues}
                    >
                      {QuarryJsonData?.testMaterial?.map((m, i) => (
                        <Option key={i} value={m}>
                          {m}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                {diameter ? (
                  <Col span={24} style={{ marginTop: "20px" }}>
                    <Row gutter={[12, 0]}>
                      <Col span={12}>
                        <span className="title_changes">Min</span>
                        <Form.Item
                          name="materialMinDiameter"
                          rules={[
                            {
                              required: false,
                              message: "Please Enter Diameter!",
                            },
                          ]}
                          style={{ marginBottom: "10px" }}
                        >
                          <Input
                            style={{
                              height: "40px",
                              width: "100%",
                            }}
                            placeholder="Please enter diameter"
                            onChange={this.resetvendorValues}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <span
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span className="title_changes">Max</span>
                          <Switch
                            checkedChildren={"Yes"}
                            unCheckedChildren={"No"}
                            onChange={(e) => this.setState({ diameter: e })}
                            checked={diameter}
                          >
                            Size Range
                          </Switch>
                        </span>
                        <Form.Item
                          name="materialMaxDiameter"
                          rules={[
                            {
                              required: false,
                              message: "Please Enter Diameter!",
                            },
                          ]}
                          style={{ marginBottom: "10px" }}
                        >
                          <Input
                            style={{
                              height: "40px",
                              width: "100%",
                            }}
                            placeholder="Please enter diameter"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                ) : (
                  <Col span={24} style={{ marginTop: "20px" }}>
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span className="title_changes">Diameter</span>
                      <Switch
                        checkedChildren={"Yes"}
                        unCheckedChildren={"No"}
                        onChange={(e) => this.setState({ diameter: e })}
                      >
                        Size Range
                      </Switch>
                    </span>
                    <Form.Item
                      name="materialDiameter"
                      rules={[
                        {
                          required: false,
                          message: "Please Enter Diameter!",
                        },
                      ]}
                      style={{ marginBottom: "10px" }}
                    >
                      <Input
                        style={{
                          height: "40px",
                          width: "100%",
                        }}
                        onChange={this.resetvendorValues}
                        placeholder="Please enter diameter"
                      />
                    </Form.Item>
                  </Col>
                )}

                <Col span={24}>
                  <span className="title_changes">Washed Status</span>
                  <Form.Item
                    name="materialStatus"
                    rules={[
                      {
                        required: false,
                        message: "Please Enter washed status!",
                      },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      size="large"
                      showSearch
                      allowClear
                      className="select_multiplequarytol"
                      placeholder="Please Select A statusMaterial"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onChange={this.resetvendorValues}
                    >
                      {QuarryJsonData?.statusMaterial?.map((m, i) => (
                        <Option key={i} value={m}>
                          {m}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24} style={{ marginTop: "20px" }}>
                  <span className="title_changes">Supply</span>
                  <Form.Item
                    name="materialSupply"
                    rules={[
                      {
                        required: false,
                        message: "Please Enter supply!",
                      },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      size="large"
                      showSearch
                      allowClear
                      className="select_multiplequarytol"
                      // className="select_Height"
                      // style={{ height: "40px" }}
                      placeholder="Please Select A Supply"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onChange={this.resetvendorValues}
                    >
                      {QuarryJsonData?.supplyMaterial?.map((m, i) => (
                        <Option key={i} value={m}>
                          {m}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Form>
            </>
          )}

          {(this.props?.driver || this.props?.customer) && (
            <Form
              onFinish={this.onFinishDriver}
              ref={this.formRef}
              id="filterform"
            >
              <Col span={24} className="righttextfilter">
                <span>
                  <Link onClick={this.onClearFilter}>Clear Filter</Link>
                </span>
              </Col>

              <Col
                xxl={{ span: 24, offset: 0 }}
                xl={{ span: 24, offset: 0 }}
                lg={{ span: 24, offset: 0 }}
                md={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                xs={{ span: 24, offset: 0 }}
              >
                {this.props?.customer ? (
                  <span className="title_changes">Location</span>
                ) : (
                  <span className="title_changes">Home City</span>
                )}
                <Form.Item
                  name="address"
                  rules={[
                    {
                      required: false,
                      message: this.props?.customer
                        ? "Please Enter Location"
                        : "Please Enter Home City!",
                    },
                  ]}
                >
                  <GooglePlacesAutocomplete
                    className="form-input"
                    autocompletionRequest={{
                      componentRestrictions: {
                        country: ["us"],
                      },
                    }}
                    selectProps={{
                      quarryAddress,
                      defaultInputValue: `${
                        this.props?.checkData?.address !== "" &&
                        this.props?.checkData?.address !== undefined
                          ? this?.props?.checkData?.address
                          : quarryAddress
                      }`,
                      placeholder: this.props?.customer
                        ? "Search Location"
                        : "Search Home City...",
                      onChange: (address) => {
                        this.handleSelect(address);
                        this.getAddress(address);
                      },
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <span className="title_changes">Radius</span>
                <Form.Item
                  name="radius"
                  rules={[
                    {
                      required: false,
                      message: "Please Enter Radius!",
                    },
                  ]}
                >
                  <InputNumber
                    placeholder="Please Enter Radius"
                    min={1}
                    controls={true}
                    style={{
                      height: "40px",
                      width: "100%",
                      minWidth: "290px",
                    }}
                    addonAfter={"Miles"}
                  />
                </Form.Item>
              </Col>
              {this.props?.driver && (
                <Col span={24}>
                  <span className="title_changes">Date Added</span>
                  <Form.Item
                    name="dateAdded"
                    rules={[
                      {
                        required: false,
                        message: "Please Enter Date!",
                      },
                    ]}
                  >
                    <DatePicker
                      className="form-input"
                      style={{ width: "100%" }}
                      placeholder="Please enter your dateAdded"
                      format={"MM/DD/YYYY"}
                    />
                  </Form.Item>
                </Col>
              )}
              {this.props?.customer && (
                <>
                  <Col span={24}>
                    <span className="title_changes">Account Status</span>
                    <Form.Item
                      name="accountStatus"
                      rules={[
                        {
                          required: false,
                          message: "Please Enter Your Account Status!",
                        },
                      ]}
                    >
                      <Select
                        size="large"
                        allowClear
                        className="select_multiplequarytol"
                        style={{ height: "40px" }}
                        //  onChange={this.onSelectCompany}
                        placeholder="Please Select A Account Status"
                      >
                        <Option key={1} value="Active" className="option_style">
                          Active
                        </Option>
                        <Option
                          key={2}
                          value="Inactive"
                          className="option_style"
                        >
                          Inactive
                        </Option>
                        <Option
                          key={3}
                          value="Overdue"
                          className="option_style"
                        >
                          Overdue
                        </Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </>
              )}
            </Form>
          )}

          {this.props.ownerOperator && (
            <Form
              onFinish={this.onFinishOwnerOperator}
              ref={this.formRef}
              id="filterform"
            >
              <Col span={24} className="righttextfilter">
                <span>
                  <Link onClick={this.onClearFilter}>Clear Filter</Link>
                </span>
              </Col>
              <span className="title_changes">Carrier Stages</span>

              <Col span={24}>
                <Form.Item
                  name="carrierStages"
                  rules={[
                    {
                      required: false,
                      message: "Please Enter Your Carrier Stages!",
                    },
                  ]}
                >
                  <Checkbox
                    onChange={this.onCheckAllStageOwner}
                    checked={checkAllStageOwner}
                    style={{
                      marginTop: "10px",
                      borderBottom: "2px solid #9a9a9a",
                      paddingBottom: "5px",
                    }}
                  >
                    <span className="checkBox_size">Select All</span>
                  </Checkbox>
                  <Checkbox.Group
                    style={{ width: "100%" }}
                    onChange={this.handleStageCarrier}
                    value={carrierStatus}
                  >
                    {funnelData?.map((s) => (
                      <Col
                        span={24}
                        style={{ marginBottom: "10px", marginTop: "10px" }}
                      >
                        <Checkbox key={s} value={s}>
                          <span className="checkBox_size">{s}</span>
                        </Checkbox>
                      </Col>
                    ))}
                  </Checkbox.Group>
                </Form.Item>
              </Col>
            </Form>
          )}

          {this.props.ownerOperatorProfile && (
            <Form
              onFinish={this.onFinishOwnerOperatorProfile}
              ref={this.formRef}
              id="filterform"
            >
              <Col span={24} className="righttextfilter">
                <span>
                  <Link onClick={this.onClearFilter}>Clear Filter</Link>
                </span>
              </Col>

              <Col span={24}>
                <span className="title_changes">Date Range</span>
                <Form.Item
                  name="dateRange"
                  rules={[
                    {
                      required: false,
                      message: "Please Enter Your Date Range!",
                    },
                  ]}
                >
                  <RangePicker
                    disabledDate={(current) => {
                      let customDate = moment()
                        .add(1, "d")
                        .format("YYYY-MM-DD");
                      return (
                        current && current > moment(customDate, "YYYY-MM-DD")
                      );
                    }}
                    format={"MM/DD/YYYY"}
                    style={{ width: "100%", height: "40px" }}
                    onChange={this.dateRange}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <span className="title_changes">Truck Numbers</span>
                <Form.Item
                  name={"truckId"}
                  rules={[
                    {
                      required: false,
                      message: "Please Select Required",
                    },
                  ]}
                >
                  <Select
                    allowClear
                    style={{ height: "40px" }}
                    // className="select_multiplequarytol"
                    className="select_Height"
                    placeholder="Please Select A Job Type"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {this.props?.invoicesData?.map((eachItem) => (
                      <Option
                        key={eachItem.truckId}
                        value={eachItem.truckId}
                        className="option_style"
                      >
                        {eachItem.truckId}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <span className="title_changes">Payment Status</span>
                <Form.Item
                  name="paymentStatus"
                  rules={[
                    {
                      required: false,
                      message: "Please Select Any Payment Status!",
                    },
                  ]}
                >
                  <Select
                    // size="large"
                    // mode="multiple"
                    // showSearch
                    allowClear
                    style={{ height: "40px" }}
                    // className="select_multiplequarytol"
                    className="select_Height"
                    placeholder="Please Select A Payment Status"
                    // optionFilterProp="children"
                    // filterOption={(input, option) =>
                    //   option.children
                    //     .toLowerCase()
                    //     .includes(input.toLowerCase())
                    // }
                  >
                    {paymentStatus?.map((eachItem) => (
                      <Option
                        key={eachItem.label}
                        value={eachItem.label}
                        className="option_style"
                      >
                        {eachItem.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Form>
          )}

          {billManagement && (
            <Form
              onFinish={this.onFinishBill}
              ref={this.formRef}
              id="filterform"
            >
              <Col span={24} className="righttextfilter">
                <span>
                  <Link onClick={this.onClearFilter}>Clear Filter</Link>
                </span>
              </Col>

              <Col span={24}>
                <span className="title_changes">Date Range</span>
                <Form.Item
                  name="dateRange"
                  rules={[
                    {
                      required: false,
                      message: "Please Enter Your Date Range!",
                    },
                  ]}
                >
                  <RangePicker
                    // disabledDate={(current) => {
                    //   let customDate = moment()
                    //     .add(1, "d")
                    //     .format("YYYY-MM-DD");
                    //   return (
                    //     current && current > moment(customDate, "YYYY-MM-DD")
                    //   );
                    // }}
                    format={"MM/DD/YYYY"}
                    style={{ width: "100%", height: "40px" }}
                    onChange={this.dateRange}
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <span className="title_changes">Vendor Name</span>
                <Form.Item
                  name="vendorId"
                  rules={[
                    {
                      required: false,
                      message: "Please Enter Your vendor Name!",
                    },
                  ]}
                  initialValue={billData?.vendorData}
                >
                  <Select
                    showSearch
                    allowClear
                    className="select_Height"
                    style={{ height: "40px" }}
                    placeholder="Please Select A Customer Account"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children !== undefined &&
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {vendorData?.map((eachItem) => (
                      <Option
                        key={eachItem?.selectedMaterial[0]?.vendorId}
                        value={eachItem?.selectedMaterial[0]?.vendorId}
                        className="option_style"
                      >
                        {eachItem?.selectedMaterial[0]?.vendor}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={24}>
                <span className="title_changes">Material Type</span>
                <Form.Item
                  name={"materialId"}
                  rules={[
                    {
                      required: false,
                      message: "Please Select Required",
                    },
                  ]}
                >
                  <Select
                    allowClear
                    style={{ height: "40px" }}
                    className="select_Height"
                    placeholder="Please Select A Job Type"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {materialCategory?.map((eachItem) => (
                      <Option
                        key={eachItem?.selectedMaterial[0]?.materialId}
                        value={eachItem?.selectedMaterial[0]?.materialId}
                        className="option_style"
                      >
                        {eachItem?.selectedMaterial[0]?.category +
                          " - " +
                          eachItem?.selectedMaterial[0]?.label?.split(" - ")[1]}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={24}>
                <span className="title_changes">Amount Range</span>
                <Row gutter={[12, 0]}>
                  <Col span={12}>
                    <Form.Item
                      name={"min"}
                      rules={[
                        {
                          required: false,
                          message: "Please Select Required",
                        },
                      ]}
                    >
                      <InputNumber
                        prefix={"$"}
                        precision={2}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        decimalSeparator={"."} // value={quantity}
                        placeholder="Min Amount"
                        style={{
                          width: "100%",
                        }}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      name={"max"}
                      rules={[
                        {
                          required: false,
                          message: "Please Select Required",
                        },
                      ]}
                    >
                      <InputNumber
                        prefix={"$"}
                        precision={2}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        decimalSeparator={"."}
                        placeholder="Max Amount"
                        style={{
                          width: "100%",
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Form>
          )}

          {this.props.quarryProfile && (
            <Form
              onFinish={this.onFinishQuarryProfile}
              ref={this.formRef}
              id="filterform"
            >
              <Col span={24} className="righttextfilter">
                <span>
                  <Link onClick={this.onClearFilter}>Clear Filter</Link>
                </span>
              </Col>

              <Col span={24}>
                <span className="title_changes">Date Range</span>
                <Form.Item
                  name="dateRange"
                  rules={[
                    {
                      required: false,
                      message: "Please Enter Your Date Range!",
                    },
                  ]}
                >
                  <RangePicker
                    disabledDate={(current) => {
                      let customDate = moment()
                        .add(1, "d")
                        .format("YYYY-MM-DD");
                      return (
                        current && current > moment(customDate, "YYYY-MM-DD")
                      );
                    }}
                    format={"MM/DD/YYYY"}
                    style={{ width: "100%", height: "40px" }}
                    onChange={this.dateRange}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <span className="title_changes">Material Type</span>
                <Form.Item
                  name={"materialType"}
                  rules={[
                    {
                      required: false,
                      message: "Please Select Required",
                    },
                  ]}
                >
                  <Select
                    // mode="multiple"
                    // showSearch
                    allowClear
                    style={{ height: "40px" }}
                    // className="select_multiplequarytol"
                    className="select_Height"
                    placeholder="Please Select A Job Type"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {/* {materialsNewList?.category?.map((d) => (
                      <Col
                        span={24}
                        style={{ marginBottom: "10px", marginTop: "10px" }}
                      >
                        <Checkbox value={d?.id}>
                          <span className="checkBox_size">{d.label}</span>
                        </Checkbox>
                      </Col>
                    ))} */}
                    {Object.keys(materialCategory)?.map((eachItem) => (
                      <Option
                        key={eachItem}
                        value={
                          Object.values(materialCategory[eachItem])[0]
                            ?.categoryKey
                        }
                        className="option_style"
                      >
                        {eachItem}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <span className="title_changes">Payment Status</span>
                <Form.Item
                  name="paymentStatus"
                  rules={[
                    {
                      required: false,
                      message: "Please Select Any Payment Status!",
                    },
                  ]}
                >
                  <Select
                    // size="large"
                    // mode="multiple"
                    // showSearch
                    allowClear
                    style={{ height: "40px" }}
                    // className="select_multiplequarytol"
                    className="select_Height"
                    placeholder="Please Select A Payment Status"
                    // optionFilterProp="children"
                    // filterOption={(input, option) =>
                    //   option.children
                    //     .toLowerCase()
                    //     .includes(input.toLowerCase())
                    // }
                  >
                    {paymentStatus?.map((eachItem) => (
                      <Option
                        key={eachItem.label}
                        value={eachItem.label}
                        className="option_style"
                      >
                        {eachItem.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Form>
          )}

          {this.props.dispatchArchive && (
            <Form
              onFinish={this.onFinishDispatchArchive}
              ref={this.formRef}
              id="filterform"
            >
              <Col span={24} className="righttextfilter">
                <span>
                  <Link onClick={this.onClearFilter}>Clear Filter</Link>
                </span>
              </Col>

              <Col span={24}>
                <span className="title_changes">Date Range</span>
                <Form.Item
                  name="dateRange"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Date Range!",
                    },
                  ]}
                >
                  <RangePicker
                    disabledDate={(current) => {
                      let customDate = moment()
                        .add(1, "d")
                        .format("YYYY-MM-DD");
                      return (
                        current && current > moment(customDate, "YYYY-MM-DD")
                      );
                    }}
                    format={"MM/DD/YYYY"}
                    style={{ width: "100%", height: "40px" }}
                    onChange={this.dateRange}
                  />
                </Form.Item>
              </Col>
            </Form>
          )}

          {this.props.ticketProcessing && (
            <Form
              onFinish={this.onFinishTicket}
              ref={this.formRef}
              id="filterform"
            >
              <Col span={24} className="righttextfilter">
                <span>
                  <Link onClick={this.onClearFilter}>Clear Filter</Link>
                </span>
              </Col>
              <>
                <Col span={24}>
                  <span className="title_changes">Customer Name</span>
                  <Form.Item
                    name="companyId"
                    rules={[
                      {
                        required: false,
                        message: "Please Enter Your Customer Name!",
                      },
                    ]}
                    initialValue={checkData?.companyId}
                  >
                    <Select
                      size="large"
                      allowClear
                      className="select_multiplequarytol"
                      style={{ height: "40px" }}
                      placeholder={`Please Select A Customer`}
                      optionFilterProp="children"
                    >
                      {this.findFilter("companyId")?.map((eachItem, index) => (
                        <Option
                          key={index}
                          value={eachItem.companyId}
                          className="option_style"
                        >
                          {eachItem?.companyName}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <span className="title_changes">Job Name</span>
                  <Form.Item
                    name="jobId"
                    rules={[
                      {
                        required: false,
                        message: "Please Enter Your Job Name!",
                      },
                    ]}
                    initialValue={checkData?.jobId}
                  >
                    <Select
                      size="large"
                      allowClear
                      className="select_multiplequarytol"
                      style={{ height: "40px" }}
                      placeholder="Please Select A Job Name"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {this.findFilter("jobId")?.map((eachItem, index) => (
                        <Option
                          key={index}
                          value={eachItem.jobId}
                          className="option_style"
                        >
                          {eachItem?.jobNumber}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <span className="title_changes">Truck #</span>
                  <Form.Item
                    name="truckNumber"
                    rules={[
                      {
                        required: false,
                        message: "Please Enter Your Truck Number!",
                      },
                    ]}
                    initialValue={checkData?.truckNumber}
                  >
                    <Select
                      size="large"
                      allowClear
                      className="select_multiplequarytol"
                      style={{ height: "40px" }}
                      placeholder="Please Select A Truck"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {this.findFilter("truckNumber")?.map(
                        (eachItem, index) => (
                          <Option
                            key={index}
                            value={eachItem.truckNumber}
                            className="option_style"
                          >
                            {eachItem?.truckNumber}
                          </Option>
                        )
                      )}
                    </Select>
                  </Form.Item>
                </Col>
              </>
              {/* )}  */}
            </Form>

            // </Form>
          )}

          {this.props.restoreData && (
            <Form
              onFinish={this.onFinishRestore}
              ref={this.formRef}
              id="filterform"
            >
              <Col span={24} className="righttextfilter">
                <span>
                  <Link onClick={this.onClearFilter}>Clear Filter</Link>
                </span>
              </Col>
              <>
                <Col span={24}>
                  <span className="title_changes">
                    {activeKey === "0"
                      ? "Quarry Name"
                      : activeKey === "1"
                      ? "Driver Name"
                      : "	Carrier Name"}
                  </span>
                  <Form.Item
                    name="selectedValue"
                    // initialValue={this.props.checkedData}
                  >
                    <Select
                      size="large"
                      allowClear
                      className="select_multiplequarytol"
                      style={{ height: "40px" }}
                      placeholder={`Please Select A ${
                        activeKey === "0"
                          ? "Quarry"
                          : activeKey === "1"
                          ? "Driver"
                          : "Carrier"
                      } Name`}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {this.handleFilter()?.map((eachItem) => {
                        return (
                          <Option
                            key={eachItem?.id}
                            value={eachItem}
                            className="option_style"
                          >
                            {eachItem}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <span className="title_changes">Deleted By</span>
                  <Form.Item
                    name="deletedBy"
                    // initialValue={this.props.checkedData}
                  >
                    <Select
                      size="large"
                      allowClear
                      className="select_multiplequarytol"
                      style={{ height: "40px" }}
                      placeholder="Please Select A Deleted By"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {this.handleDeletedByFilter()?.map((e) => {
                        return (
                          <Option
                            key={e?.id}
                            value={e}
                            className="option_style"
                          >
                            {e}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </>
              {/* )}  */}
            </Form>

            // </Form>
          )}
        </Drawer>
      </div>
    );
  }
}
