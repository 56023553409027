import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Input,
  Button,
  Form,
  Select,
  message,
  InputNumber,
  Checkbox,
  Tooltip,
  Switch,
  Divider,
  Upload,
  Popover,
  Popconfirm,
} from "antd";
import {
  PlusOutlined,
  UsergroupAddOutlined,
  InboxOutlined,
  CloseOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";
import { MdDelete, MdModeEditOutline } from "react-icons/md";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
} from "react-google-places-autocomplete";
import { add, updateTotal } from "../../../api/Apis";
import { Storage } from "aws-amplify";
import appConfig from "../../../config/AppConfig";
import Loading from "../../../Common/Loading";
import { withAppContext } from "../../../Store/AppContext";
import BaseModal from "../../../Common/BaseModal";
import BaseTable from "../../../Common/BaseTable";
import { getLatLng } from "react-places-autocomplete";
import NumberFormat from "react-number-format";
import { v4 as uuidv4 } from "uuid";
import AOS from "aos";
import "aos/dist/aos.css";
import { connect } from "react-redux";
import {
  addContact,
  addDocument,
  deleteContactandJob,
  getCompany,
  getDocuments,
  getDropDownOptions,
  getLead,
  getNotes,
  getQuarryLocations,
  getTopVendors,
  updateContacts,
  updateDocument,
  updateDropDownOptions,
  updateLead,
  updateQuarry,
} from "../../../Redux/Actions/actions";
import QuarryJsonData from "../../../Json/quarryData.json";
import { BsArrowLeftShort } from "react-icons/bs";
import moment from "moment";

const { Option } = Select;
const { TextArea } = Input;
const { Dragger } = Upload;

const jobType = [
  { label: "Trucking Job", value: "Trucking Job" },
  // { label: "Trucking & Material Job", value: "Trucking & Material Job" },
  { label: "Trucking & Material Job", value: "Both" },
];

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

const bigData = ["Round Bottom", "End Dump", "Belly Dump", "SuperDump"]
  .slice()
  .sort();
const smallData = ["15+ Ton Dump Truck", "10+ Ton Dump Truck", "SuperDump"]
  .slice()
  .sort();

class UpdateJobDetails extends Component {
  formRef = React.createRef();
  formRef1 = React.createRef();
  exemptRef = React.createRef();
  constructor(props) {
    super(props);

    this.state = {
      showJobProfile: false,
      isNoteModalVisible: false,
      newNoteData: "",
      visible: false,
      selectedStatus: "",
      pickUpAddress: "",
      keyValue: "1",
      dropAddress: "",
      areaAddress: "",
      quoteAttachment: "",
      imageLoading: false,
      isEditLeadModalVisible: false,
      isDocModalVisible: false,
      isCallModalVisible: false,
      leadDetails: {},
      internalLoading: false,
      quotesData: [],
      documentsData: [],
      invoicesData: [],
      notesData: [],
      newNote: "",
      idData: "",
      selectedInvoice: "",
      selectedQuote: "",
      isQuickMsgVisible: false,
      quarryData: [],
      leadId: "",
      latitude: "",
      longitude: "",
      isJobEditModal: false,
      jobInfo: "",
      quantityType: "Ton",
      totalPriceType: "Ton",
      materialCostType: "Ton",
      isCheckBox: false,
      newRole: "",
      quoteAttachmentId: "",
      materialTruckType: [],
      selectedMaterial: [],
      recordMaterialData: {},
      materialCheck: false,
      selectedMaterialType: [],
      finalMaterialData: [],
      addContact: false,
      truckingCostType: "Ton",
      isContactVisible: false,
      contactsData: [],
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      roleOther: "",
      leadsData: [],
      companyId: "",
      jobContactData: "",
      materialPopup: false,
      pickupDetails: [],
      selectedAddress: "",
      repeatDays: [],
      activeClasses: false,
      calenderType: "Week",
      endsValue: "",
      afterValue: "",
      onValue: "",
      newVendor: "",
      truckingPriceType: "Ton",
      recurringData: "",
      dropoffLatitude: "",
      dropoffLongitude: "",
      pickUpLatitude: "",
      pickupLongitude: "",
      dispatchModal: false,
      vendorData: [],
      seperate: false,
      initialMaterialCost: "",
      materialPriceState: "",
      selectVendor: "",
      materialValue: "",
      categoriesList: [],
      materialCostPrice: "",
      materialTruckPrice: 0,
      milesData: "",
      duration: "",
      vendorName: "",
      quantity: 1,
      masterRoleId: "",
      masterTruckId: "",
      notesPopup: false,
      recordNotesData: "",
      fileType: "",
      dispatchDate: "",
      recurring: "",
      recordUpdate: false,
      iscalenderVisible: false,
      contactDetails: [],
      quarryContacts: [],
      rolesData: [],
      trucksData: [],
      materialList: [],
      materialSelectedData: {},
      topVendorsData: [],
      vendorDetails: "",
      updateJobIdContact: [],
      useMarkUp: "",
      truckingCost: "",
      customRate: false,
      materialCustomRate: false,
      diameter: "",
      materialCategory: {},
      selectedRowKeys: [],
      salesCommission: "",
      isSheetModalVisible: false,
      exemptVisible: false,
      projectAttachment: "",
      projectAttachmentId: "",
      subCategory1: [],
      subCategory2: [],
      showMaterialValues: false,
      loading: false,
      materialCharacteristics: {},
      checkAll: false,
      indeterminate: false,
      bigIndeterminate: false,
      bigAll: false,
      smallIndeterminate: false,
      smallAll: false,
      truckCustomRate: false,
      materialCategoryId: [],
      salesPersonVal: "",
      autoSave: false,
      editUpdateMaterial: false,
      materialTax: "",
      additionalTaxRate: "",
      orginalTaxRate: "",
      salesTaxRate: "",
      landedPriceType: "Ton",
      landedPriceState: "",
      landedPriceError: false,
      showTableModal: "",
    };
  }

  componentDidMount = () => {
    const { match } = this.props;
    const { params } = match;
    const { id } = params;
    this.props.actionFetchgetLead(id);
    this.props.actiongetDocuments(id);
    this.props.actiongetNotes(id);
    this.props?.context?.updateLeadId(id);
    this.props.actionFetchQuarries();

    document.title = "Twisted Nail - Lead Details";
    AOS.init({
      // initialise with other settings
      duration: 2000,
    });
  };

  componentDidUpdate(newProps) {
    if (
      newProps?.totalResult?.success === false &&
      this.props?.totalResult?.success === true
    ) {
      this.setState(
        {
          loading: false,
          internalLoading: false,
          showJobProfile: true,
          leadDetails: this.props?.totalResult?.lead,
          quarryContacts: this.props?.totalResult?.lead?.contactDetails?.filter(
            (e) => e.materialId
          ),
          quarryData: [this.props?.totalResult?.lead],
          invoicesData: this.props?.totalResult?.lead?.invoiceDetails,
          invoiceNotes: {
            ...this.props?.totalResult?.lead?.invoiceNotes,
            notes1: this.props?.totalResult?.lead?.invoiceNotes?.notes1,
            notes2: this.props?.totalResult?.lead?.invoiceNotes?.notes2,
            notes3: this.props?.totalResult?.lead?.invoiceNotes?.notes3,
          },
          jobInfo: this.props?.totalResult?.lead?.jobType,
          selectedMaterial: this.props?.totalResult?.lead.material || [],
          selectedDropOffAddress: this.props?.totalResult?.lead?.dropoff,
          dropAddress: this.props?.totalResult?.lead?.dropoff,
          dropoffLatitude: this.props?.totalResult?.lead?.dropOffLatLng?.lat,
          dropoffLongitude: this.props?.totalResult?.lead?.dropOffLatLng?.lng,
          contactDetails: this.props?.totalResult?.lead?.contactDetails,
          taxExempt: this.props?.totalResult?.lead?.taxExempt || "Not Exempt",
          projectInformationSheet:
            this.props?.totalResult?.lead?.projectInformationSheet ||
            "Not Received",
          updateJobIdContact: this.props?.totalResult?.lead?.multipleContacts,
          leadQuote: this.props?.totalResult?.lead?.multipleContacts,
          salesPersonVal: this.props?.totalResult?.lead.salesPersonId,
        },
        () => this.props.actiongetDropDownOptions()
      );
      this.props?.actionFetchCompany(this.props?.totalResult?.lead?.companyId);
    }

    if (
      newProps?.companyResult?.success === false &&
      this.props?.companyResult?.success === true
    ) {
      let quarryContacts = [];

      if (this.props?.totalResult?.lead?.multipleContacts?.length > 0) {
        quarryContacts =
          this.props.companyResult?.companyData?.contactDetails.concat(
            this.props?.totalResult?.lead?.multipleContacts
          );
      } else {
        quarryContacts = this.props.companyResult?.companyData?.contactDetails;
      }
      quarryContacts = quarryContacts?.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t?.id === value?.id)
      );
      this.setState({
        contactsData: quarryContacts,
      });
    }

    if (
      newProps?.topVendorsData?.success === false &&
      this.props?.topVendorsData?.success === true
    ) {
      if (this.props?.topVendorsData.vendors?.length > 0) {
        const vendorsData = this.props?.topVendorsData.vendors?.filter((e) => {
          e["material"].forEach((m) => {
            m["quarryName"] = e["quarryName"];
            m["quarryNickName"] = e["quarryNickName"]
              ? e["quarryNickName"]
              : e["quarryName"]?.slice(0, 12);
            m["duration"] = e["duration"];
            m["milesData"] = e["distance"];
            m["quarryId"] = e["id"];
            m["vendorTerms"] = e["accountTerms"];
            m["vendorDiscountTerms"] = e["payDiscountTerms"];
            m["vendorDiscount"] = e["payDiscount"];
            m["address"] = e["address"];
            m["lat"] = e["lat"];
            m["lng"] = e["lng"];
          });
          return e;
        });
        this.setState({
          topVendorsData: vendorsData,
          loading: false,
        });
      } else {
        this.setState({
          topVendorsData: this.props?.topVendorsData.vendors,
          loading: false,
        });
        message.info("No vendors found!");
      }
    }

    if (
      newProps?.leadUpdateInfo?.success === false &&
      this.props?.leadUpdateInfo?.success === true
    ) {
      this.setState({
        showJobProfile: false,
        loading: false,
        selectedMaterial: [],
        recordMaterialData: {},
        materialCostType: "Ton",
        truckingCostType: "Ton",
        quantityType: "Ton",
        totalPriceType: "Ton",
        iscalenderVisible: false,
        taxExempt: "",
        dropoffLatitude: "",
        dropoffLongitude: "",
        recordUpdate: false,
        quarryContacts: [],
        dispatchModal: false,
        isModalVisible: false,
        internalLoading: false,
      });
      this.props.actionFetchgetLead(this.props.match.params.id);
    }

    if (
      newProps?.contactsInfo?.success === false &&
      this.props?.contactsInfo?.success === true
    ) {
      this.setState({
        newRole: "",
        roleOther: "",
        roleCheckBox: false,
        isCheckBox: false,
        jobContactData: "",
      });
      this.props.actionFetchgetLead(this.props.match.params.id);
    }

    if (
      newProps?.contactData?.success === false &&
      this.props?.contactData?.success === true
    ) {
      this.addContactToJob(this.props?.contactData?.contact?.data);
      this.setState({
        isCheckcontact: false,
        roleOther: "",
        newRole: "",
      });
    }

    if (
      newProps?.docResult?.success === false &&
      this.props?.docResult?.success === true
    ) {
      this.setState({
        quoteAttachment: "",
      });
      if (this.props?.docResult.noUpdate) {
        this.props.actionFetchgetLead(this.props.match.params.id);
      }
      this.props.actiongetDocuments(this.props.match.params.id);
    }

    if (
      newProps?.documentsData?.success === false &&
      this.props?.documentsData?.success === true
    ) {
      this.setState({
        documentsData: this.props.documentsData.docData,
      });
    }

    if (
      newProps?.notesResult?.success === false &&
      this.props?.notesResult?.success === true
    ) {
      this.setState({
        notesData: this.props.notesResult.notesData,
      });
    }

    if (
      newProps?.notesInfo?.success === false &&
      this.props?.notesInfo?.success === true
    ) {
      this.props.actiongetNotes(this.props.match.params.id);
      this.props.actionFetchgetLead(this.props.match.params.id);
    }

    if (
      newProps?.dropDownOptionsInfo?.success === false &&
      this.props?.dropDownOptionsInfo?.success === true
    ) {
      this.props?.actiongetDropDownOptions();
    }

    if (
      newProps?.dropDownOptions?.success === false &&
      this.props?.dropDownOptions?.success === true
    ) {
      let materialData = {};
      let materialsNewList = this.props.materialList[0]?.materialsNewList;

      let index = 0;
      let sortedMaterialList = materialsNewList
        ?.filter((v) => v.statusCode)
        .sort((a, b) => a?.indexVal - b?.indexVal);
      sortedMaterialList?.map((eachMat) => {
        let subTypes = [];
        if (eachMat?.statusCode) {
          index += 1;
          materialData[eachMat.category] = !Object.keys(materialData).includes(
            eachMat.category
          )
            ? []
            : materialData[eachMat.category];
          let typeCheck = materialData[eachMat.category].filter(
            (o) => o.subcat === eachMat.materialValue.split(" - ")[1]
          );

          if (typeCheck.length > 0) {
            subTypes = typeCheck[0]["subTypes"];
            subTypes.push({
              subType: eachMat.materialValue.split(" - ")[2],
              id: eachMat.id,
            });
          } else {
            subTypes.push({
              subType: eachMat.materialValue.split(" - ")[2],
              id: eachMat.id,
            });
            materialData[eachMat.category].push({
              subcat: eachMat.materialValue.split(" - ")[1],
              cate: eachMat.materialValue.split(" - ")[0],
              categoryKey: eachMat.categoryKey,
              subShortName: eachMat.subShortName,
              subTypes,
            });
          }
          if (
            sortedMaterialList?.filter((e) => e.statusCode)?.length === index
          ) {
            if (
              window.location.search &&
              this.props?.totalResult?.lead?.material
            ) {
              let materialId = window.location.search?.slice(1);
              let selectedMaterial =
                this.props?.totalResult?.lead.material?.filter(
                  (e) => e.id === materialId
                );
              if (selectedMaterial?.length) {
                this.setState(
                  {
                    materialCategory: materialData,
                    showTableModal: selectedMaterial[0]?.category
                      ? "Trucking & Material Job"
                      : "Trucking Job",
                  },
                  () => {
                    this.editMaterial(selectedMaterial[0]);
                  }
                );
              }
            } else {
              this.setState({
                materialCategory: materialData,
              });
            }
          }
        }
        return true;
      });
      this.setState({
        rolesData: this.props.rolesData[0]?.rolesData?.sort((a, b) =>
          a?.value > b?.value ? 1 : -1
        ),
        trucksData: this.props.trucksData[0]?.trucksData,
        materialList: this.props.materialList[0]?.materialsNewList,
        salesTaxRate: this.props.dropDownOptions.totalData.find(
          (e) => e?.typeofDropDown === "salesTaxRate"
        )?.taxRate,
      });
    }

    if (
      newProps?.quarryResult?.success === false &&
      this.props?.quarryResult?.success === true
    ) {
      this.setState({
        quarryDetails: this.props.quarryResult.quarryData,
        vendorData: this.props.quarryResult.quarryData,
      });
    }
  }

  componentWillUnmount = () => {
    const formValues = this.getAllFieldValues();
    const { autoSave } = this.state;
    if (
      formValues?.contactperson?.length > 0 &&
      formValues?.jobType !== undefined &&
      formValues?.salesPerson !== undefined &&
      formValues?.taxExempt !== undefined &&
      !autoSave
    ) {
      this.onFinishJobForm(formValues, "addContact");
    }
  };

  getAllFieldValues = () => {
    const {
      jobInfo,
      salesPersonVal,
      taxExempt,
      projectInformationSheet,
      contactId,
      leadDetails,
    } = this.state;
    const form = document.getElementById("jobForm");
    const formData = {};
    for (const input of form.elements) {
      if (input.id) {
        if (input.type === "search") {
          formData["jobType"] = jobInfo;
          formData["salesPerson"] = salesPersonVal;
          formData["taxExempt"] = taxExempt;
          formData["projectInformationSheet"] = projectInformationSheet;
          formData["contactperson"] =
            contactId !== undefined
              ? contactId
              : leadDetails?.multipleContacts?.map((c) => c?.id);
        } else {
          formData[input.id] = input.value;
        }
      }
    }
    return formData;
  };

  onSelectChange = (data, value) => {
    const { selectedRowKeys, selectedMaterial, recordMaterialData } =
      this.state;
    if (Object.values(recordMaterialData)?.length === 0) {
      let unCheckedData = selectedRowKeys?.filter((e) => !data.includes(e));
      if (unCheckedData?.length > 0) {
        this.setState({
          selectedMaterial: selectedMaterial?.filter(
            (e) => !e.materialId.includes(unCheckedData)
          ),
        });
      }
    }

    let vendorDetails = value[data?.length - 1];
    if (
      selectedMaterial?.filter(
        (e) =>
          e?.id === vendorDetails?.id &&
          e?.vendorId === vendorDetails?.quarryId &&
          parseInt(e.materialCost) === parseInt(vendorDetails.price)
      )?.length > 0
    ) {
      message.error("You can't select same material with same vendor!");
      return true;
    }
    this.setState({
      selectedRowKeys: data,
      showMaterialValues:
        data?.length > 0 && data.length > selectedRowKeys?.length
          ? true
          : false,
      vendorDetails: value[data?.length - 1],
      initialMaterialCost: value[data?.length - 1]?.price,
      materialCostPrice: value[data?.length - 1]?.price,
      materialCostType: value[data?.length - 1]?.quantityType,
      materialTax: value[data?.length - 1]?.materialTax,
      additionalTaxRate: value[data?.length - 1]?.additionalTaxRate,
      orginalTaxRate: value[data?.length - 1]?.additionalTaxRate,
      materialPriceState: "",
      truckingCost: "",
      materialTruckType: [],
      bigAll: false,
      smallAll: false,
      checkAll: false,
    });
    this.formRef1.current.setFieldsValue({
      quantity: "",
      totalPrice: "",
      markUp: "",
      truckingCost: "",
      truckingPrice: "",
      materialCost: value[data?.length - 1]?.price,
    });
  };

  getAddress = (address, type) => {
    if (type === "pickup") {
      this.setState({
        selectedPickupAddress: address.label,
      });
    } else if (type === "dropOff") {
      this.setState({
        selectedDropOffAddress: address.label,
      });
    } else {
      this.setState({ selectedAddress: address.label });
    }
  };

  handleSelect = (address, type) => {
    const { dropoffLatitude, dropoffLongitude } = this.state;
    const citySearch = [];
    citySearch.push(address.label);
    this.setState({
      citySearch,
    });
    let cityValue = "";
    let stateValue = "";
    let zipCodeValue = "";
    geocodeByAddress(address.label)
      .then((results) => {
        getLatLng(results[0]).then(({ lat, lng }) => {
          if (type === "pickup") {
            this.setState({
              loading: true,
            });
            let url =
              "https://maps.googleapis.com/maps/api/distancematrix/json?units=imperial&destinations=" +
              [dropoffLatitude, dropoffLongitude] +
              "&origins=" +
              [lat, lng] +
              "&key=AIzaSyB781k9fNf61hnxE3A6cvcascwhAhIVIK4";
            let milesData = {
              entityData: {
                url,
              },
            };
            add("getMiles", milesData)
              .then((res) =>
                this.setState({
                  pickUpLatitude: lat,
                  pickupLongitude: lng,
                  milesData: res.data.distance,
                  duration: res.data.duration,
                  loading: false,
                })
              )
              .catch((err) => {
                this.setState({
                  loading: false,
                });
                console.log(err, "err");
              });
          } else if (type === "dropOff") {
            this.setState({ dropoffLatitude: lat, dropoffLongitude: lng });
          } else {
            this.setState({ latitude: lat, longitude: lng });
          }
        });
        const geoIdData = results[0];
        this.setState({
          geoIdData,
        });
        let address = results[0].address_components;
        for (let i = 0; i < address?.length; i++) {
          if (address[i].types[0] === "locality") {
            cityValue = address[i].long_name;
          }
          if (address[i].types[0] === "administrative_area_level_1") {
            stateValue = address[i].short_name;
          }
          if (address[i].types[0] === "postal_code") {
            zipCodeValue = address[i].long_name;
          }
        }
        this.setState({
          city: cityValue,
          addressState: stateValue,
          zipCode: zipCodeValue,
        });
      })
      .catch((error) => console.error("Error", error));
  };

  onFinishJobForm = (values, type) => {
    const {
      leadDetails,
      selectedMaterial,
      updateJobIdContact,
      dropoffLatitude,
      dropoffLongitude,
      selectedDropOffAddress,
      quarryContacts,
      contactDetails,
      salesCommission,
      invoiceNotes,
      documentsData,
    } = this.state;
    const { match, context } = this.props;
    const { params } = match;
    const { id } = params;

    if (type === "autoSave") {
      this.setState({
        autoSave: true,
      });
    }
    if (values.projectInformationSheet !== "Not Received") {
      let docSheet = [];
      docSheet = documentsData?.filter((e) => e.sheetStatus);
      if (docSheet?.length === 0) {
        message.error("Please Upload Project Information Sheet");
        this.setState({
          isSheetModalVisible: true,
        });
        return true;
      }
    }

    if (values.jobType !== "Trucking Job") {
      if (values["taxExempt"] === "Not Exempt") {
        selectedMaterial?.map((e) => {
          if (
            e.materialTax === "Manufactured" &&
            e.additionalTaxRate !== e.orginalTaxRate
          ) {
            e["additionalTaxRate"] = e.orginalTaxRate;
          }
          return e;
        });
      } else if (values.taxExempt !== "Not Exempt") {
        selectedMaterial?.map((e) => {
          if (e.materialTax === "Manufactured") {
            e["additionalTaxRate"] = 0;
          }
          return e;
        });
        let docSheet = [];
        docSheet = documentsData?.filter(
          (e) => e.docType === "Tax Exemption Certificate"
        );
        if (docSheet?.length === 0) {
          message.error("Please Upload Tax Exemption Certificate");
          this.setState({
            exemptVisible: true,
          });
          return true;
        }
      }
    }
    const salesData = context.salesPersonsData?.find(
      (e) => e.id === values["salesPerson"]
    );
    let commonData = {
      company: leadDetails?.companyDetails?.company,
      companyName: leadDetails?.companyDetails?.companyName,
      contactperson: "",
      contactId: "",
      jobName: values["jobName"],
      materialName: values["materialName"],
      jobType: values["jobType"],
      invoiceNotes: invoiceNotes,
      dropoff: selectedDropOffAddress,
      dropOffLatLng: {
        lat: dropoffLatitude,
        lng: dropoffLongitude,
      },
      multipleContacts: updateJobIdContact?.filter((e) =>
        e?.jobId?.includes(leadDetails?.id)
      ),
      notes: values["notes"] !== "" ? values["notes"] : leadDetails?.notes,
      salesPersonId: values["salesPerson"],
      salesCommission:
        salesData?.salesCommission ||
        leadDetails["salesCommission"] ||
        salesCommission,
    };

    let leadData = {
      entityData: {
        ...commonData,
        material: selectedMaterial,
        taxExempt: values.taxExempt,
        projectInformationSheet: values.projectInformationSheet,
        accountChargeLimit: leadDetails.accountChargeLimit || "Cash Account",
        taxStatus: values.taxExempt === "Exempt" ? true : false,
      },
    };
    let quarryContactsData = [];
    let quarryContact = {};
    contactDetails?.filter((e) => {
      quarryContactsData = quarryContacts?.filter((q) => q.id !== e.id);
      return e;
    });
    // return
    quarryContact["entityData"] = quarryContactsData;
    this.updateMultipleContacts(
      quarryContact,
      id,
      leadData,
      quarryContactsData,
      type
    );
  };

  updateMultipleContacts = (
    quarryContact,
    id,
    leadData,
    quarryContactsData,
    type
  ) => {
    const { updateJobIdContact, leadQuote } = this.state;
    if (
      JSON.stringify(updateJobIdContact) !== JSON.stringify(leadQuote) &&
      quarryContactsData?.length > 0
    ) {
      quarryContact["entityData"] =
        quarryContactsData.concat(updateJobIdContact);
      leadData["entityData"]["multipleContacts"] = quarryContact["entityData"];
      this.updateMultipleContact(quarryContact, id, leadData);
    } else if (
      JSON.stringify(updateJobIdContact) !== JSON.stringify(leadQuote)
    ) {
      let updateData = {
        entityData: updateJobIdContact,
      };
      this.updateMultipleContact(updateData, id, leadData);
    } else if (quarryContactsData?.length > 0) {
      leadData["entityData"]["multipleContacts"] =
        quarryContactsData.concat(updateJobIdContact);
      this.addMultipleContact(quarryContact, id, leadData);
    } else {
      this.setState({
        isModalVisible: false,
      });
      this.updateLead(id, leadData, type);
    }
  };

  updateMultipleContact = (quarryContact, id, leadData) => {
    updateTotal("updateMultipleContacts", quarryContact)
      .then((result) => {
        if (result.success) {
          this.setState({
            isModalVisible: false,
            internalLoading: true,
          });
          this.updateLead(id, leadData, "addContact");
        } else {
          this.setState({
            internalLoading: false,
            isModalVisible: false,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  addMultipleContact = (quarryContact, id, leadData) => {
    add("addMultipleContacts", quarryContact)
      .then((result) => {
        if (result.success) {
          this.setState({
            internalLoading: true,
            isModalVisible: false,
          });
          this.updateLead(id, leadData, "addContact");
        } else {
          this.setState({
            internalLoading: false,
            isModalVisible: false,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  updateJobMaterials = (data) => {
    this.setState({ loading: true });
    const { leadDetails } = this.state;
    let materialsData = leadDetails?.material;
    materialsData?.map((m) => {
      data?.map((d) => {
        if (d?.materialId === m?.id) {
          m["distance"] = d?.distance;
          m["duration"] = d?.duration;
        }
        return true;
      });
      return true;
    });
    let finalData = {
      entityData: {
        material: materialsData,
      },
    };
    this.updateLead(leadDetails?.id, finalData);
  };

  verificationTruckingJob = (id, data) => {
    const { selectedMaterial, leadDetails, updateJobIdContact, leadQuote } =
      this.state;
    let truckingCostData = data.entityData?.material?.filter(
      (e) => e?.truckingCost
    );
    let truckingPriceData = data.entityData?.material?.filter(
      (e) => e?.truckingPrice
    );
    if (
      selectedMaterial[0]?.pickUpAddress !== "" &&
      selectedMaterial[0]?.pickUpAddress !== undefined &&
      selectedMaterial?.length !== 0
    ) {
      if (
        this.state?.dropAddress !== "" &&
        this.state?.dropAddress !== undefined
      ) {
        if (
          leadDetails.projectInformationSheet !== undefined &&
          leadDetails.projectInformationSheet !== ""
        ) {
          if (
            truckingCostData?.length === data?.entityData?.material?.length &&
            truckingPriceData?.length === data?.entityData?.material?.length
          ) {
            if (
              leadDetails?.companyDetails?.accountTerms !== "" &&
              leadDetails?.companyDetails?.accountTerms !== undefined
            ) {
              if (
                JSON.stringify(updateJobIdContact) !== JSON.stringify(leadQuote)
              ) {
                let updateData = {
                  entityData: updateJobIdContact,
                };
                this.addMultipleContact(updateData, id, data);
              } else {
                this.setState({
                  dispatchModal: false,
                });
                this.updateLead(id, data);
              }
            } else {
              message.error(
                "Please Enter Account Terms In Customer Account Profile To Proceed Further"
              );
            }
          } else {
            message.error("Please Enter Trucking Cost And Trucking Price");
          }
        } else {
          message.error("Please Select Project Information Sheett!");
        }
      } else {
        message.info("Please Select Dropoff Location");
      }
    } else {
      message.info("Please Select Pickup Address");
    }
  };

  verificationTuckingAndMaterial = (id, data) => {
    const { selectedMaterial, leadDetails, contactDetails, quarryContacts } =
      this.state;
    let vendor = data.entityData.material?.filter((e) => e?.vendor);
    if (vendor?.length === data?.entityData?.material?.length) {
      if (leadDetails.taxExempt !== undefined && leadDetails.taxExempt !== "") {
        if (
          leadDetails.projectInformationSheet !== undefined &&
          leadDetails.projectInformationSheet !== ""
        ) {
          if (
            this.state?.dropAddress !== "" &&
            this.state?.dropAddress !== undefined
          ) {
            if (selectedMaterial?.length > 0) {
              if (
                leadDetails?.companyDetails?.accountTerms !== "" &&
                leadDetails?.companyDetails?.accountTerms !== undefined
              ) {
                this.setState({
                  dispatchModal: false,
                });
                let quarryContactsData = [];
                let quarryContact = {};
                contactDetails?.filter((e) => {
                  quarryContactsData = quarryContacts?.filter(
                    (q) => q.id !== e.id
                  );
                  return e;
                });
                quarryContact["entityData"] = quarryContactsData;
                this.updateMultipleContacts(
                  quarryContact,
                  id,
                  data,
                  quarryContactsData
                );
              } else {
                message.error(
                  "Please Enter Account Terms In Customer Account Profile To Proceed Further"
                );
              }
            } else {
              message.error("Please Select Required Materials");
            }
          } else {
            message.error("Please Select Dropoff Location");
          }
        } else {
          message.error("Please Select Project Information Sheett!");
        }
      } else {
        message.error("Please Select Tax exempt!");
      }
    } else {
      message.error("Please Add Vendor Details To Materials");
    }
  };

  handleInvoiceChange = (e) => {
    const { invoiceNotes } = this.state;
    this.setState({
      ...invoiceNotes,
      invoiceNotes: { ...invoiceNotes, [e.target.name]: e.target.value },
    });
  };

  updateLead = (id, data, type) => {
    this.setState({
      internalLoading: true,
    });
    this.props.actionUpdateJob(id, data);
    if (type !== "addContact") {
      setTimeout(() => {
        this.props.history.push(`/app/jobs/job-profile/${id}`);
      }, 1000);
    }
  };

  onClickReturn = () => {
    this.props.history.goBack();
  };

  onChangeTrucksType = (values) => {
    const { trucksData, recordMaterialData } = this.state;
    const sortedList = values.slice().sort();
    if (Object.values(recordMaterialData)?.length > 0) {
      recordMaterialData["truckType"] = sortedList;
      this.setState({
        recordMaterialData,
      });
    }
    this.setState({
      materialTruckType: values,
      indeterminate: values.length !== trucksData?.length ? true : false,
      checkAll: values.length === trucksData?.length ? true : false,
      bigAll:
        JSON.stringify(sortedList) === JSON.stringify(bigData) ? true : false,
      smallAll:
        JSON.stringify(sortedList) === JSON.stringify(smallData) ? true : false,
    });
  };

  deleteMultipleContacts = (data) => {
    let deletContactsData = {
      entityData: data,
    };
    add(appConfig["entityNames"]["deleteMultipleContacts"], deletContactsData)
      .then()
      .catch((err) => console.log(err));
  };

  onFinishMaterial = (values) => {
    const {
      recordMaterialData,
      materialSelectedData,
      quantityType,
      pickUpLatitude,
      pickupLongitude,
      truckingPriceType,
      selectedPickupAddress,
      initialMaterialCost,
      duration,
      milesData,
      totalPriceType,
      materialCostType,
      truckingCostType,
      vendorDetails,
      topVendorsData,
      materialPriceState,
      quantity,
      materialTruckPrice,
      truckingCost,
      diameter,
      materialTruckType,
      useMarkUp,
      materialTax,
      additionalTaxRate,
      orginalTaxRate,
      exceedHourLimit,
      landedPriceState,
      landedPriceType,
      showLandedPrice,
      landedPriceError,
      showTableModal,
    } = this.state;
    const checkLandedVal =
      showLandedPrice && truckingPriceType !== totalPriceType;
    if (exceedHourLimit) return true;
    if (landedPriceError && truckingPriceType !== totalPriceType) return true;
    if (
      checkLandedVal &&
      (landedPriceState === "" || landedPriceState === "N/A")
    ) {
      this.setState({ landedPriceError: true });
      return true;
    }
    if (showTableModal === "Trucking Job") {
      let truckingData = {
        entityData: {
          id:
            materialSelectedData["id"] === undefined
              ? uuidv4()
              : materialSelectedData["id"],
          truckingPrice:
            parseFloat(values["truckingPrice"]) ||
            recordMaterialData?.truckingPrice,
          truckingPriceType: truckingPriceType,
          materialName: values["materialName"],
          pickUpAddress: selectedPickupAddress,
          pickUpLatLng: {
            lat: pickUpLatitude,
            lng: pickupLongitude,
          },
          truckingCost:
            parseFloat(values["truckingCost"]) ||
            recordMaterialData?.truckingCost,
          truckingCostType: truckingCostType,
          duration: duration,
          milesData: milesData,
          truckType: materialTruckType,
          hourlyCharge: values["hourlyCharge"],
        },
      };
      if (materialTruckType?.length > 0) {
        if (
          truckingData.entityData.pickUpAddress !== undefined &&
          truckingData.entityData.pickUpAddress !== ""
        ) {
          this.addMaterialOrTruckInfo(truckingData);
          this.setState({
            materialPopup: false,
          });
        } else {
          message.error("Please Enter PickUp Location");
        }
      } else {
        message.error("Please Select Trucks");
      }
    } else {
      if (Object.values(recordMaterialData)?.length === 0) {
        this.setState({
          materialPopup: false,
        });
      } else {
        if (topVendorsData?.length === 0) {
          message.error("Please Find Nearest Vendors!");
          return true;
        }
        if (topVendorsData?.length !== 0 && vendorDetails === "") {
          message.error("Please select at least one Vendor!");
          return true;
        }
        if (materialTruckType?.length === 0) {
          message.error("Please Select Trucks");
          return true;
        }

        let materialMinDiameter = 0,
          materialMaxDiameter = 0,
          materialDiameter = 0;
        if (
          (values["materialMinDiameter"] && values["materialMaxDiameter"]) ||
          values["materialDiameter"]
        ) {
          let minD = values["materialMinDiameter"]?.replace('"', "");
          let maxD = values["materialMaxDiameter"]?.replace('"', "");
          let mD = values["materialDiameter"]?.replace('"', "");

          if (diameter) {
            materialMinDiameter =
              minD?.split(" ")?.length !== 2 && String(parseInt(minD)) !== minD
                ? this.DecimalToMixedFraction(minD)
                : minD;

            materialMaxDiameter =
              maxD?.split(" ")?.length !== 2 && String(parseInt(maxD)) !== maxD
                ? this.DecimalToMixedFraction(maxD)
                : maxD;
          } else {
            materialDiameter =
              mD?.split(" ")?.length !== 2 && String(parseInt(mD)) !== mD
                ? this.DecimalToMixedFraction(mD)
                : mD;
          }
        }

        let materialData = {
          entityData: {
            materialId: vendorDetails["materialId"],
            id: vendorDetails["id"],
            label: vendorDetails["label"],
            value: vendorDetails["value"],
            category: vendorDetails["category"],
            categoryKey: vendorDetails["categoryKey"],
            subShortName: vendorDetails["subShortName"],
            materialShortValue:
              vendorDetails["categoryKey"] +
              " - " +
              vendorDetails["subShortName"] +
              " - " +
              vendorDetails["label"].split(" - ")[1],
            truckingPrice: materialTruckPrice,
            truckingPriceType: truckingPriceType,
            truckingCost: truckingCost,
            truckingCostType: truckingCostType,
            quantity: quantity,
            quantityType: quantityType,
            materialTax,
            additionalTaxRate,
            orginalTaxRate,
            vendorId: vendorDetails?.quarryId || vendorDetails?.vendorId,
            vendor: vendorDetails?.quarryName || vendorDetails?.vendor,
            quarryNickName: vendorDetails?.quarryNickName,
            vendorTerms: vendorDetails?.vendorTerms,
            vendorDiscountTerms: vendorDetails?.vendorDiscountTerms,
            vendorDiscount: vendorDetails?.vendorDiscount,
            pickUpAddress:
              vendorDetails?.address || vendorDetails?.pickUpAddress,
            pickUpLatLng: {
              lat: vendorDetails.lat || vendorDetails?.pickUpLatLng?.lat,
              lng: vendorDetails.lng || vendorDetails?.pickUpLatLng?.lng,
            },
            materialCost:
              values["materialCost"] !== undefined
                ? parseFloat(values["materialCost"])
                : parseFloat(initialMaterialCost),
            materialCostType: materialCostType,
            materialName: values["materialName"],
            totalPrice: materialPriceState,
            totalPriceType,
            duration: vendorDetails?.duration || materialSelectedData?.duration,
            milesData:
              vendorDetails?.distance || materialSelectedData?.milesData,
            materialCostAtQuarryPerTon: parseFloat(initialMaterialCost),
            landedPrice: checkLandedVal
              ? landedPriceState
              : totalPriceType === truckingPriceType
              ? parseFloat(materialPriceState) + materialTruckPrice
              : "N/A",
            showLandedPrice,
            landedPriceType: checkLandedVal
              ? landedPriceType
              : totalPriceType || "Ton",
            landedCost:
              materialCostType === truckingCostType &&
              initialMaterialCost &&
              truckingCost
                ? parseFloat(initialMaterialCost + truckingCost)?.toFixed(2)
                : "N/A",
            totalSale:
              materialPriceState && quantity && materialTruckPrice
                ? parseFloat(
                    (materialPriceState + materialTruckPrice) * quantity
                  )?.toFixed(2)
                : "N/A",
            totalExepense:
              initialMaterialCost && quantity && truckingCost
                ? parseFloat(
                    (initialMaterialCost + truckingCost) * quantity
                  )?.toFixed(2)
                : "",
            totalProfit: this.calculateTotalProfit(
              truckingPriceType,
              totalPriceType,
              materialCostType,
              truckingCostType,
              materialPriceState,
              materialTruckPrice,
              quantity,
              initialMaterialCost,
              truckingCost,
              "profit"
            ),
            totalProfitPercentage: this.calculateTotalProfit(
              truckingPriceType,
              totalPriceType,
              materialCostType,
              truckingCostType,
              materialPriceState,
              materialTruckPrice,
              quantity,
              initialMaterialCost,
              truckingCost,
              "profitPercentage"
            ),
            materialDiameter: materialDiameter,
            materialStatus: values["materialStatus"],
            materialSupply: values["materialSupply"],
            materialTest: values["materialTest"],
            materialMaxDiameter: materialMaxDiameter,
            materialMinDiameter: materialMinDiameter,
            diameter,
            truckType: vendorDetails["truckType"],
            useMarkUp,
            hourlyCharge: values["hourlyCharge"],
          },
        };
        this.addMaterialOrTruckInfo(materialData);
        this.setState({
          materialPopup: false,
        });
      }
    }
  };

  addVendorContact = (vendorDetails) => {
    const { leadDetails, quarryContacts } = this.state;
    let contactsData = quarryContacts?.length > 0 ? quarryContacts : [];
    vendorDetails?.contactDetails
      ?.filter((q) => q?.isPrimaryContact)
      ?.map((c) => {
        if (contactsData?.filter((t) => t.id === c.id)?.length > 0) {
        } else {
          contactsData.push({
            id: c["id"],
            materialId: c?.materialId,
            quarryId: vendorDetails?.id,
            jobId: [leadDetails?.id],
            firstName: c["firstName"],
            lastName: c["lastName"],
            phone: c["phone"],
            email: c["email"],
            notes: c["notes"],
            contactRole: c["contactRole"],
          });
        }
        return true;
      });
    this.setState({
      quarryContacts: contactsData,
    });
  };

  addMaterialOrTruckInfo = (data) => {
    const { selectedMaterial, recordUpdate } = this.state;
    this.setState({
      loading: false,
    });
    let materialInfo = [];
    materialInfo = selectedMaterial?.length > 0 ? selectedMaterial : [];
    materialInfo.push(data["entityData"]);
    const isAvailabelMaterial = materialInfo?.filter((e) => e.category);
    if (isAvailabelMaterial?.length > 0) {
      this.setState({
        jobInfo: "Both",
      });
      this.exemptRef.current.setFieldsValue({
        jobType: "Both",
      });
    }

    this.setState({
      showMaterialValues: false,
      pickupDetails: [],
      selectedMaterial: materialInfo,
      loading: false,
      quantityType: "Ton",
      selectedMaterialType: [],
      materialSelectedData: "",
      quantity: "",
      recordMaterialData: {},
      truckingCostType: "Ton",
      totalPriceType: "Ton",
      materialCostType: "Ton",
      truckingPriceType: "Ton",
      selectedPickupAddress: "",
      pickUpLatitude: "",
      pickupLongitude: "",
      finalMaterialData: [],
      filterResultData: [],
      duration: "",
      milesData: "",
      selectVendor: "",
      initialMaterialCost: "",
      materialPriceState: "",
      materialTruckPrice: "",
      materialCostPrice: "",
    });
    if (recordUpdate === true) {
      let leadData = {
        entityData: {
          material: materialInfo,
        },
      };
      this.updateLead(this.props.match.params.id, leadData);
    }
  };

  getVendorDetails = (data) => {
    const { materialCategory } = this.state;
    data["subCategory2"] = [data.id];
    data["subCategory1"] = "";

    this.setState(
      {
        materialTax: data?.materialTax,
        additionalTaxRate: data?.additionalTaxRate,
        orginalTaxRate: data?.orginalTaxRate,
        useMarkUp: data?.useMarkUp,
        diameter: data?.diameter,
        topVendorsData: [data?.vendorId],
        customRate: false,
        materialCustomRate: false,
        vendorDetails: data,
        selectedRowKeys: [data?.materialId],
        milesData: data?.milesData,
        duration: data?.duration,
        materialValue: data?.category,
        materialPopup: true,
        recordMaterialData: data,
        selectedMaterialType: [data?.category],
        materialSelectedData: data,
        quantityType: data?.quantityType ? data?.quantityType : "Ton",
        quantity: data?.quantity,
        truckingCost: data?.truckingCost,
        truckingCostType: data?.truckingCostType
          ? data?.truckingCostType
          : "Ton",
        truckingPriceType: data?.truckingPriceType
          ? data?.truckingPriceType
          : "Ton",
        materialCostType: data?.materialCostType
          ? data?.materialCostType
          : "Ton",
        totalPriceType: data?.totalPriceType ? data?.totalPriceType : "Ton",
        initialMaterialCost: data?.materialCost,
        materialPriceState: data?.totalPrice,
        materialTruckPrice: data?.truckingPrice,
        materialCostPrice: data?.materialCostAtQuarryPerTon,
        subCategory1: [data?.label?.split(" - ")[0]],
        subCategory2: Object.values(
          materialCategory[data?.category]?.filter(
            (e) => e.subcat === data?.label?.split(" - ")[0]
          )
        ),
        showLandedPrice: data.showLandedPrice || false,
        landedPriceState: data.landedPrice,
        landedPriceType: data.landedPriceType,
      },
      () => {
        this.findNearestVendors(data, "edit");
      }
    );
  };

  onCancelMaterial = () => {
    const { recordMaterialData, selectedMaterial } = this.state;
    if (
      recordMaterialData !== "" &&
      recordMaterialData !== undefined &&
      Object.values(recordMaterialData)?.length !== 0
    ) {
      let materialInfo = selectedMaterial?.length > 0 ? selectedMaterial : [];
      materialInfo.push(recordMaterialData);
      this.setState({
        selectedMaterial: materialInfo,
        selectedRowKeys: materialInfo?.map((e) => e.materialId),
        recordMaterialData: {},
        selectedMaterialType: [],
        materialPopup: false,
        filterResultData: [],
        finalMaterialData: [],
        initialMaterialCost: "",
        pickupDetails: [],
        materialPriceState: "",
        duration: "",
        milesData: "",
        selectVendor: "",
        materialCostPrice: "",
        materialSelectedData: "",
        vendorDetails: "",
        quantity: "",
        materialTruckPrice: "",
        truckingCost: "",
        customRate: false,
        materialCustomRate: false,
        subCategory1: [],
        subCategory2: [],
        bigAll: false,
        smallAll: false,
        checkAll: false,
      });
    } else {
      this.setState({
        selectedMaterialType: [],
        materialPopup: false,
      });
    }
  };

  editMaterial = (data, type) => {
    const { showTableModal, trucksData } = this.state;
    this.setState({
      editUpdateMaterial: true,
      materialTruckType: data.truckType,
    });
    // if (leadDetails?.stageId !== "Dispatch Approved") {
    if (type === "recordUpdate") {
      this.setState({ recordUpdate: true });
    }
    if (data !== "") {
      let filteredData = [];
      this.state?.selectedMaterial?.forEach((c) => {
        if (
          c.vendorId === data?.vendorId &&
          c.id === data?.id &&
          c.materialId === data?.materialId
        ) {
        } else {
          filteredData.push(c);
        }
      });
      this.setState({ selectedMaterial: filteredData });
    }
    const sortedList = data.truckType?.slice().sort();
    this.setState({
      indeterminate:
        data.truckType?.length !== trucksData?.length ? true : false,
      checkAll: data.truckType?.length === trucksData?.length ? true : false,
      bigAll:
        JSON.stringify(sortedList) === JSON.stringify(bigData) ? true : false,
      smallAll:
        JSON.stringify(sortedList) === JSON.stringify(smallData) ? true : false,
    });
    if (showTableModal === "Trucking Job") {
      this.setState({
        materialPopup: true,
        recordMaterialData: data,
        selectedMaterialType: [data?.category],
        materialSelectedData: data,
        truckingCostType: data?.truckingCostType
          ? data?.truckingCostType
          : "Ton",
        truckingPriceType: data?.truckingPriceType
          ? data?.truckingPriceType
          : "Ton",
        duration: data?.duration,
        milesData: data?.milesData,
        selectedPickupAddress: data?.pickUpAddress,
        pickUpLatitude: data?.pickUpLatLng?.lat,
        pickupLongitude: data?.pickUpLatLng?.lng,
        customRate: false,
        materialCustomRate: false,
      });
    } else {
      this.setState({
        loading: true,
      });
      this.getVendorDetails(data);
    }
    // }
  };

  deleteMaterial = (record) => {
    const { selectedMaterial, showTableModal } = this.state;
    if (showTableModal === "Trucking Job") {
      let materialInfo = selectedMaterial.filter((c) => c.id !== record.id);
      this.setState({
        selectedMaterial: materialInfo,
      });
      message.info("Trucking Details Deleted Successfully");
    } else {
      let filteredData = [];
      selectedMaterial?.forEach((c) => {
        if (
          c.vendorId === record?.vendorId &&
          c.id === record?.id &&
          c.materialId === record?.materialId
        ) {
        } else {
          filteredData.push(c);
        }
      });
      message.info("Material Deleted Successfully!");
      this.setState({
        selectedMaterial: filteredData,
      });
    }
  };

  onSelectMaterialCat = (e, type) => {
    const { materialList } = this.state;
    if (type === "edit") {
      let materialsData = [];
      materialList?.map((newMat) => {
        if (e === newMat?.category) {
          materialsData.push(newMat);
        }
        return true;
      });
      this.setState({
        selectedMaterialType: [e],
        finalMaterialData: materialsData,
      });
    } else {
      let materialsData = [];
      materialList?.map((newMat) => {
        if (e === newMat?.category) {
          materialsData.push(newMat);
        }
        return true;
      });
      if (e) {
        this.formRef.current.setFieldsValue({ material: undefined, price: "" });
        this.setState({
          materialSelectedData: "",
          topVendorsData: [],
          vendorDetails: "",
          selectedRowKeys: [],
        });
      }
      this.setState({
        selectedMaterialType: [e],
        finalMaterialData: materialsData,
      });
    }
  };

  onSelectMaterial = (e) => {
    const { finalMaterialData } = this.state;
    finalMaterialData?.filter(
      (f) => f.id === e && this.setState({ materialSelectedData: f })
    );
    this.setState({
      topVendorsData: [],
      vendorDetails: "",
      selectedRowKeys: [],
    });
  };

  onFinishContact = (values) => {
    const { leadDetails, jobContactData } = this.state;
    this.setState({ isContactVisible: false });
    let contactsData = {
      entityData: {
        companyId: leadDetails?.companyId,
        firstName: values["fName"],
        lastName: values["lName"],
        phone: values["phone"],
        email: values["email"],
        notes: values["notes"],
        jobId: jobContactData?.jobId
          ? jobContactData?.jobId
          : [leadDetails?.id],
        contactRole:
          values["role"] === "Add New Role"
            ? values["roleOther"]
            : values["role"],
      },
    };
    if (jobContactData !== "") {
      this.updateContact(contactsData);
    } else {
      this.addContact(contactsData);
    }
    if (this.state.roleCheckBox) {
      this.updateRolesData(values["roleOther"]);
    }
  };

  updateRolesData = (values) => {
    let rolesData = this.state.rolesData;
    rolesData.push({
      label: values,
      value: values.charAt(0).toUpperCase(),
      id: rolesData?.length + 1,
    });
    let data = {
      entityData: {
        rolesData,
        typeofDropDown: "roles",
      },
    };
    this.props.actionupdateDropDownOptions(this.props.rolesData[0]?.id, data);
  };

  addContact = (data) => {
    this.props.actionAddContact(data);
  };

  updateContact = (data) => {
    this.props.actionUpdateContact(this.state?.jobContactData?.id, data);
  };

  deleteContact = (data) => {
    this.props?.actionDeleteContact(data.id);
  };

  addContactToJob = (newContact) => {
    this.setState({ selectedContacts: [] });
    const { leadDetails } = this.state;
    let existContactData = leadDetails?.multipleContacts;
    existContactData.push(newContact);
    let data = {
      entityData: {
        multipleContacts: existContactData,
      },
    };
    this.updateLead(leadDetails?.id, data);
  };

  handleSelectContact = (e) => {
    const { contactsData, leadDetails } = this.state;
    contactsData?.map((contact) => {
      if (e?.includes(contact?.id)) {
        if (
          contact["jobId"]?.length > 0 &&
          !contact["jobId"]?.includes(leadDetails?.id)
        ) {
          contact["jobId"].push(leadDetails?.id);
        } else {
          contact["jobId"] =
            contact["jobId"]?.length > 0 ? contact["jobId"] : [leadDetails?.id];
        }
        return contact;
      } else {
        if (contact["jobId"]?.includes(leadDetails?.id)) {
          contact["jobId"] = contact["jobId"]?.filter(
            (ct) => ct !== leadDetails?.id
          );
        }
      }
      return contact;
    });
    this.setState({ contactId: e, updateJobIdContact: contactsData });
  };

  resetvendorValues = () => {
    this.setState({
      topVendorsData: [],
      selectedRowKeys: [],
    });
  };

  DecimalToMixedFraction = (val) => {
    // eslint-disable-next-line no-eval
    const decimal = eval(val);
    // Split the decimal into whole and fractional parts
    const wholeNumber = Math.floor(decimal);
    const fractionalPart = decimal - wholeNumber;
    // Convert the fractional part to a fraction
    const fraction = this.decimalToFraction(fractionalPart);

    // Combine the whole number and fraction
    const mixedFraction =
      wholeNumber !== 0 ? `${wholeNumber} ${fraction}` : `${fraction}`;

    return mixedFraction;
  };

  decimalToFraction = (decimal) => {
    const EPSILON = 1.0e-9;
    let numerator = decimal;
    let denominator = 1;

    while (Math.abs(Math.round(numerator) - numerator) > EPSILON) {
      numerator *= 10;
      denominator *= 10;
    }

    const gcd = this.getGreatestCommonDivisor(numerator, denominator);
    numerator /= gcd;
    denominator /= gcd;

    return `${Math.floor(numerator)}/${Math.floor(denominator)}`;
  };

  getGreatestCommonDivisor = (a, b) => {
    if (b === 0) {
      return a;
    }
    return this.getGreatestCommonDivisor(b, a % b);
  };

  findNearestVendors = (values, type) => {
    const {
      dropoffLatitude,
      dropoffLongitude,
      diameter,
      subCategory2,
      materialCategoryId,
    } = this.state;
    this.setState({
      loading: true,
    });
    let materialMinDiameter = 0,
      materialMaxDiameter = 0,
      materialDiameter = 0;
    if (
      (values["materialMinDiameter"] && values["materialMaxDiameter"]) ||
      values["materialDiameter"]
    ) {
      if (diameter) {
        let minD = values["materialMinDiameter"]?.replace('"', "");
        let maxD = values["materialMaxDiameter"]?.replace('"', "");

        materialMinDiameter =
          minD?.split(" ")?.length !== 2 && String(parseInt(minD)) !== minD
            ? this.DecimalToMixedFraction(minD)
            : minD;

        materialMaxDiameter =
          maxD?.split(" ")?.length !== 2 && String(parseInt(maxD)) !== maxD
            ? this.DecimalToMixedFraction(maxD)
            : maxD;
      } else {
        let mD = values["materialDiameter"]?.replace('"', "");
        materialDiameter =
          mD?.split(" ")?.length !== 2 && String(parseInt(mD)) !== mD
            ? this.DecimalToMixedFraction(mD)
            : mD;
      }
    }
    let mainMaterialId = [];
    if (materialCategoryId?.length > 0) {
      materialCategoryId?.forEach((record) =>
        record.label?.forEach((item) =>
          item?.subTypes?.forEach((e) => mainMaterialId.push(e?.id))
        )
      );
    }
    let data = {
      entityData: {
        materialId:
          values["subCategory1"] === undefined ||
          values["subCategory1"]?.length === 0
            ? mainMaterialId
            : values["subCategory2"] !== undefined &&
              values["subCategory2"]?.length > 0
            ? values["subCategory2"]
            : subCategory2
                ?.map((item) => item?.subTypes?.map((e) => e?.id))
                ?.flat(),
        dropOffLatLng: { lat: dropoffLatitude, long: dropoffLongitude },
        materialDiameter: materialDiameter ? materialDiameter : undefined,
        materialStatus:
          type === "edit"
            ? values["materialStatus"]
              ? values["materialStatus"]
              : undefined
            : values["materialStatus"],
        materialSupply:
          type === "edit"
            ? values["materialSupply"]
              ? values["materialSupply"]
              : undefined
            : values["materialSupply"],
        materialTest:
          type === "edit"
            ? values["materialTest"]
              ? values["materialTest"]
              : undefined
            : values["materialTest"],
        materialMaxDiameter: materialMaxDiameter
          ? materialMaxDiameter
          : undefined,
        materialMinDiameter: materialMinDiameter
          ? materialMinDiameter
          : undefined,
        diameter,
        quarryName:
          values["quarryName"]?.length > 0 ? values["quarryName"] : undefined,
        radius: values["radius"] ? values["radius"] : undefined,
      },
    };

    Object.keys(data["entityData"]).forEach(
      (key) =>
        data["entityData"][key] === undefined && delete data["entityData"][key]
    );
    this.setState({
      materialCharacteristics: data["entityData"],
      loading: type === "edit" && false,
    });
    if (type !== "edit") {
      this.props?.actionFetchTopVendors(data);
    }
  };

  handleCheckStatus = (e) => {
    this.setState({ diameter: e });
    this.formRef.current.setFieldsValue({
      materialDiameter: "",
      materialMinDiameter: "",
      materialMaxDiameter: "",
    });
  };

  handleUploadPic = async (info, type) => {
    this.setState({
      imageLoading: true,
      loading: true,
    });
    switch (info.file.status) {
      case "done":
        if (info.fileList?.length > 0 && info.file.status !== "removed") {
          try {
            if (info.file.size > 0) {
              Storage.put(info.file.uid, info.file.originFileObj, {
                contentType: info.file.type,
              }).then((result) => {
                Storage.get(result.key).then((urlResult) => {
                  if (type === "doc") {
                    this.setState({
                      quoteAttachment: urlResult,
                      imageLoading: false,
                      loading: false,
                      quoteAttachmentId: result.key,
                      fileType: info.file.type,
                    });
                  } else {
                    this.setState({
                      projectAttachment: urlResult,
                      imageLoading: false,
                      loading: false,
                      projectAttachmentId: result.key,
                      fileType: info.file.type,
                    });
                  }
                });
              });
            } else {
              this.setState({
                imageLoading: false,
                loading: false,
              });
            }
          } catch (err) {
            console.log(err, "errr");
          }
        }
        break;
      default:
    }
  };

  addMaterialValues = (values) => {
    const {
      quantityType,
      truckingPriceType,
      initialMaterialCost,
      totalPriceType,
      materialCostType,
      truckingCostType,
      vendorDetails,
      materialPriceState,
      quantity,
      materialTruckPrice,
      truckingCost,
      materialCharacteristics,
      materialTruckType,
      useMarkUp,
      additionalTaxRate,
      orginalTaxRate,
      quarryDetails,
      salesTaxRate,
      exceedHourLimit,
      landedPriceState,
      showLandedPrice,
      landedPriceType,
      landedPriceError,
    } = this.state;
    if (exceedHourLimit) return true;
    const checkLandedVal =
      showLandedPrice && truckingPriceType !== totalPriceType;
    if (landedPriceError && truckingPriceType !== totalPriceType) return true;
    if (
      checkLandedVal &&
      (landedPriceState === "" || landedPriceState === "N/A")
    ) {
      this.setState({ landedPriceError: true });
      return true;
    }
    if (values["materialTax"] !== "Unknown") {
      const quarryData = quarryDetails?.find(
        (e) => e.id === vendorDetails?.quarryId
      );
      quarryData["material"].map((element) => {
        if (element.materialId === vendorDetails["materialId"]) {
          element["materialTax"] = values["materialTax"];
          element["additionalTaxRate"] =
            values["materialTax"] === "Natural" ? 0 : salesTaxRate;
        }
        return element;
      });
      let updateData = {
        entityData: {
          material: quarryData["material"],
        },
      };
      this.props.actionFetchUpdateQuarry(quarryData?.id, updateData);
    }
    if (materialTruckType?.length > 0) {
      let materialSelectedData = vendorDetails;
      let materialData = {
        entityData: {
          hourlyCharge: values["hourlyCharge"],
          truckType: materialTruckType,
          // orderNumber: `${leadDetails?.jobNumber}${selectedMaterial?.length + 1}`,
          materialId: materialSelectedData["materialId"],
          id: materialSelectedData["id"],
          label: materialSelectedData["label"],
          value: materialSelectedData["value"],
          category: materialSelectedData["category"],
          categoryKey: materialSelectedData["categoryKey"],
          subShortName: materialSelectedData["subShortName"],
          materialTax: values["materialTax"],
          additionalTaxRate,
          orginalTaxRate,
          materialShortValue:
            materialSelectedData["categoryKey"] +
            " - " +
            materialSelectedData["subShortName"] +
            " - " +
            materialSelectedData["label"].split(" - ")[1],
          truckingPrice: values["truckingPrice"],
          materialName: values["materialName"],
          truckingPriceType: truckingPriceType,
          truckingCost: values["truckingCost"],
          truckingCostType: truckingCostType,
          quantity: values["quantity"],
          quantityType: quantityType,
          vendorId: materialSelectedData?.quarryId,
          vendor: materialSelectedData?.quarryName,
          quarryNickName: materialSelectedData?.quarryNickName,
          vendorTerms: materialSelectedData?.vendorTerms,
          vendorDiscountTerms: materialSelectedData?.vendorDiscountTerms,
          vendorDiscount: materialSelectedData?.vendorDiscount,
          pickUpAddress: vendorDetails?.address,
          pickUpLatLng: {
            lat: vendorDetails?.lat,
            lng: vendorDetails?.lng,
          },
          // materialCost: parseFloat(initialMaterialCost),
          materialCost:
            values["materialCost"] !== undefined
              ? parseFloat(values["materialCost"])
              : parseFloat(initialMaterialCost),
          materialCostType: materialCostType,
          totalPrice: materialPriceState,
          totalPriceType: totalPriceType,
          duration: materialSelectedData?.duration,
          milesData: materialSelectedData?.milesData,
          materialCostAtQuarryPerTon: parseFloat(initialMaterialCost),
          landedPrice: checkLandedVal
            ? landedPriceState
            : totalPriceType === truckingPriceType
            ? parseFloat(materialPriceState) + materialTruckPrice
            : "N/A",
          showLandedPrice,
          landedPriceType: checkLandedVal
            ? landedPriceType
            : totalPriceType || "Ton",
          landedCost:
            materialCostType === truckingCostType &&
            initialMaterialCost &&
            truckingCost
              ? parseFloat(initialMaterialCost + truckingCost)?.toFixed(2)
              : "N/A",
          totalSale:
            materialPriceState && quantity && materialTruckPrice
              ? parseFloat(
                  (materialPriceState + materialTruckPrice) * quantity
                )?.toFixed(2)
              : "N/A",
          totalExepense:
            initialMaterialCost && quantity && truckingCost
              ? parseFloat(
                  (initialMaterialCost + truckingCost) * quantity
                )?.toFixed(2)
              : "",
          totalProfit: this.calculateTotalProfit(
            truckingPriceType,
            totalPriceType,
            materialCostType,
            truckingCostType,
            materialPriceState,
            materialTruckPrice,
            quantity,
            initialMaterialCost,
            truckingCost,
            "profit"
          ),
          totalProfitPercentage: this.calculateTotalProfit(
            truckingPriceType,
            totalPriceType,
            materialCostType,
            truckingCostType,
            materialPriceState,
            materialTruckPrice,
            quantity,
            initialMaterialCost,
            truckingCost,
            "profitPercentage"
          ),
          materialDiameter: materialCharacteristics["materialDiameter"],
          materialStatus: materialCharacteristics["materialStatus"],
          materialSupply: materialCharacteristics["materialSupply"],
          materialTest: materialCharacteristics["materialTest"],
          materialMaxDiameter: materialCharacteristics["materialMaxDiameter"],
          materialMinDiameter: materialCharacteristics["materialMinDiameter"],
          diameter: materialCharacteristics["diameter"],
          useMarkUp,
        },
      };
      this.addMaterialOrTruckInfo(materialData);
      this.setState({
        materialPopup: false,
      });
    } else {
      message.error("Please Select Trucks");
    }
  };

  onFinishExempt = () => {
    const { fileType, projectAttachment, projectAttachmentId, leadDetails } =
      this.state;
    this.setState({
      exemptVisible: false,
    });
    let data = {
      entityData: {
        date: moment().format("L"),
        leadId: this.props?.match?.params?.id,
        document: "Tax Exemption Certificate",
        docAttachment: projectAttachment,
        docAttachmentId: projectAttachmentId,
        fileType: fileType,
        assignedTo: leadDetails?.jobNumber,
        docType: "Tax Exemption Certificate",
        // sheetStatus: "Pending",
      },
    };
    this.props.actionAddDoc(data, false);
    message.success("Tax Exemption Certificate uploaded successfully!");
  };

  onFinishSheet = () => {
    const { fileType, projectAttachment, projectAttachmentId, leadDetails } =
      this.state;
    this.setState({
      isSheetModalVisible: false,
    });
    let data = {
      entityData: {
        date: moment().format("L"),
        leadId: this.props?.match?.params?.id,
        document: "Project Information Sheet",
        docAttachment: projectAttachment,
        docAttachmentId: projectAttachmentId,
        fileType: fileType,
        assignedTo: leadDetails?.jobNumber,
        docType: "Project Information Sheet",
        sheetStatus: "Pending",
      },
    };
    this.props.actionAddDoc(data, false);
    message.success("Project Information Sheet uploaded successfully!");
  };

  showStates = () => {
    this.setState({
      showTableModal: "Trucking & Material Job",
      materialPopup: true,
      materialSelectedData: "",
      finalMaterialData: [],
      topVendorsData: [],
      vendorDetails: "",
      initialMaterialCost: "",
      materialPriceState: "",
      materialTruckPrice: "",
      duration: "",
      milesData: "",
      selectVendor: "",
      quantityType: "Ton",
      totalPriceType: "Ton",
      materialCostType: "Ton",
      truckingPriceType: "Ton",
      truckingCostType: "Ton",
      customRate: false,
      materialCustomRate: false,
      diameter: false,
      subCategory1: [],
      subCategory2: [],
      selectedMaterialType: [],
      bigAll: false,
      smallAll: false,
      checkAll: false,
      materialTruckType: [],
      editUpdateMaterial: false,
      landedPriceError: false,
      landedPriceState: "",
      landedPriceType: "Ton",
    });
  };

  handleTableChage = (props, type) => {
    const tableSizeRange = JSON.parse(localStorage.getItem("tableSizeRange"));
    localStorage.setItem(
      "tableSizeRange",
      JSON.stringify({ ...tableSizeRange, [type]: props })
    );
  };

  removeVendorsList = (record) => {
    const { topVendorsData } = this.state;
    let removeVendor = topVendorsData?.map((e) => {
      if (e.id === record.quarryId) {
        e.material = e.material.filter(
          (m) => m.materialId !== record.materialId
        );
      }
      return e;
    });
    this.setState({
      topVendorsData: removeVendor,
    });
  };

  onCheckAllChange = (e, recordData, type) => {
    const { trucksData, bigAll, smallAll, recordMaterialData } = this.state;
    let data = recordData;
    if (e.target.checked) {
      if (type === "all") {
        trucksData.map((e) => data.push(e.value));
      } else if ((bigAll && type === "small") || (smallAll && type === "big")) {
        data = [
          "Round Bottom",
          "End Dump",
          "Belly Dump",
          "SuperDump",
          "15+ Ton Dump Truck",
          "10+ Ton Dump Truck",
          "SuperDump",
        ];
      }
      if (Object.values(recordMaterialData)?.length > 0) {
        recordMaterialData["truckType"] = data;
      }
      this.setState({
        recordMaterialData,
        materialTruckType: data,
        indeterminate: type === "all" ? false : true,
        checkAll: type === "all" && e.target.checked,
        smallAll:
          type === "small"
            ? e.target.checked
            : type === "all"
            ? false
            : smallAll,
        bigAll:
          type === "big" ? e.target.checked : type === "all" ? false : bigAll,
      });
    } else {
      if (type === "small") {
        data = ["Round Bottom", "End Dump", "Belly Dump", "SuperDump"];
      } else if (type === "big") {
        data = ["15+ Ton Dump Truck", "10+ Ton Dump Truck", "SuperDump"];
      }
      if (Object.values(recordMaterialData)?.length > 0) {
        recordMaterialData["truckType"] =
          type === "small" && bigAll
            ? data
            : type === "big" && smallAll
            ? data
            : [];
      }

      if (type === "all") {
        this.setState({
          indeterminate: false,
          checkAll: e.target.checked,
          smallAll: false,
          bigAll: false,
        });
      } else if (type === "small") {
        this.setState({
          indeterminate: true,
          smallAll: e.target.checked,
        });
      } else if (type === "big") {
        this.setState({
          indeterminate: true,
          bigAll: e.target.checked,
        });
      }
      this.setState({
        materialTruckType:
          type === "small" && bigAll
            ? data
            : type === "big" && smallAll
            ? data
            : [],
      });
    }
  };

  onChangeTruckingPrice = (e, type) => {
    const { customRate } = this.state;
    if (!customRate) {
      const newTruckingCost = parseFloat(e) - parseFloat(e) * (12.5 / 100);
      this.setState({
        materialTruckPrice: e,
        truckingCost: newTruckingCost,
      });
      if (type) {
        this.formRef.current.setFieldsValue({
          truckingCost: newTruckingCost,
          truckingPrice: e,
        });
      } else {
        this.formRef1.current.setFieldsValue({
          truckingCost: newTruckingCost,
          truckingPrice: e,
        });
      }
    }
  };

  onChangeTruckingCost = (e) => {
    const { customRate } = this.state;

    if (!customRate) {
      // const newTruckingPrice = parseFloat(e) + parseFloat(e) * (12.5 / 100);
      this.setState({
        truckingCost: e,
        // materialTruckPrice: newTruckingPrice,
      });

      this.formRef.current.setFieldsValue({
        // truckingPrice: newTruckingPrice,
        truckingCost: e,
      });
    }
  };

  handleMarkupChange = (e, type) => {
    const { initialMaterialCost } = this.state;
    this.setState({
      useMarkUp: e,
      materialPriceState:
        e === initialMaterialCost && e < initialMaterialCost
          ? 0
          : initialMaterialCost + initialMaterialCost * (e / 100),
    });

    if (type === "formRef") {
      this.formRef.current.setFieldsValue({
        totalPrice:
          e === 0
            ? initialMaterialCost
            : e
            ? initialMaterialCost + initialMaterialCost * (e / 100)
            : "",
      });
    } else if (type === "formRef1") {
      this.formRef1.current.setFieldsValue({
        totalPrice:
          e === 0
            ? initialMaterialCost
            : e
            ? initialMaterialCost + initialMaterialCost * (e / 100)
            : "",
      });
    }
  };

  onChangeMaterialCustomRate = (e) => {
    const { vendorDetails } = this.state;
    this.setState({
      materialCustomRate: e.target.checked,
      materialCostType: !e.target.checked
        ? this.state.totalPriceType
        : this.state.materialCostType,
    });
    if (!e.target.checked) {
      this.formRef.current.setFieldsValue({
        materialCost: vendorDetails?.price,
      });
    }
  };

  onChangeEditMaterialCustomRate = (e) => {
    const { vendorDetails } = this.state;
    this.setState({
      materialCustomRate: e.target.checked,
      materialCostType: !e.target.checked
        ? this.state.totalPriceType
        : this.state.materialCostType,
    });

    if (!e.target.checked) {
      this.formRef1.current.setFieldsValue({
        materialCost: vendorDetails?.materialCost,
      });
    }
  };
  onChangeTruckingCustomRate = (e) => {
    this.setState({
      customRate: e.target.checked,
      truckingCostType: !e.target.checked
        ? this.state.truckingPriceType
        : this.state.truckingCostType,
    });
  };

  handleSelectChange = (value) => {
    if (value === "Manufactured") {
      this.setState({ manufacturedSelected: true });
    } else {
      this.setState({ manufacturedSelected: false });
    }
  };

  showJobProfileData = (
    leadDetails,
    contactsData,
    jobType,
    isContactVisible,
    selectedStatus,
    citySearch,
    documentsData,
    jobInfo,
    invoiceNotes,
    invoiceNote,
    projectInformationSheet
  ) => {
    return (
      <Row gutter={[24, 0]}>
        <Col
          xxl={{ span: 6 }}
          xl={{ span: 6 }}
          lg={{ span: 6 }}
          md={{ span: 12 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <span className="title_changes">Job Name</span>
          <Form.Item
            name={"jobName"}
            initialValue={
              leadDetails?.jobName
                ? leadDetails?.jobName
                : leadDetails?.jobNumber
            }
          >
            <Input
              placeholder="Please Enter Job Name"
              className="form-input"
              maxLength={12}
            />
          </Form.Item>
        </Col>
        <Col
          xxl={{ span: 6 }}
          xl={{ span: 6 }}
          lg={{ span: 6 }}
          md={{ span: 12 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <span className="title_changes">Contact Name</span>
          <div className="contactname-multiple">
            <Form.Item
              name="contactperson"
              rules={[
                {
                  required: true,
                  message: "Please Enter Your Contact Name!",
                },
              ]}
              initialValue={
                leadDetails?.multipleContacts
                  ? leadDetails?.multipleContacts.map((c) => c?.id)
                  : leadDetails?.contactperson
              }
            >
              <Select
                size="large"
                allowClear
                mode="multiple"
                showSearch
                className="select_multiplequarytol"
                style={{
                  height: "auto !important",
                  width: "100% !important",
                }}
                placeholder="Please Select A Contact Name"
                onChange={(e) => this.handleSelectContact(e)}
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                defaultValue={
                  leadDetails?.multipleContacts
                    ? leadDetails?.multipleContacts.map((c) => c?.id)
                    : leadDetails?.contactperson
                }
              >
                {contactsData?.map((eachItem) => (
                  <Option
                    key={eachItem?.id}
                    value={eachItem?.id}
                    className="option_style"
                  >
                    {eachItem.firstName + " " + eachItem.lastName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            {!isContactVisible && (
              <Tooltip
                title="Add Contact"
                overlayStyle={{
                  backGroundColor: "#000",
                  color: "#000",
                }}
              >
                <div
                  style={{
                    fontWeight: "500",
                    fontSize: "20px",
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "auto",
                    padding: "8px",
                    position: "absolute",
                    top: "0px",
                    right: "0px",
                    height: "100%",
                    borderLeft: "1px solid #ddd",
                    zIndex: "+9",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    this.setState({
                      isContactVisible: true,
                      jobContactData: "",
                    })
                  }
                >
                  <UsergroupAddOutlined />
                </div>
              </Tooltip>
            )}
          </div>
        </Col>
        <Col
          xxl={{ span: 6 }}
          xl={{ span: 6 }}
          lg={{ span: 6 }}
          md={{ span: 12 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <span className="title_changes">Job Type</span>
          <Form.Item
            name="jobType"
            rules={[
              {
                required: true,
                message: "Please Select Job Type!",
              },
            ]}
            initialValue={leadDetails?.jobType}
          >
            <Select
              disabled={
                selectedStatus === "Dispatch Approved"
                  ? true
                  : leadDetails?.quoteStatus === "Accepted"
                  ? true
                  : false
              }
              size="large"
              defaultValue={leadDetails?.jobType}
              showSearch
              allowClear
              className="select_Height"
              onChange={(value) =>
                this.setState({
                  jobInfo: value,
                  selectedMaterial: [],
                })
              }
              placeholder="Please Select A Customer Account"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {jobType.map((eachItem) => (
                <Option
                  key={eachItem.value}
                  value={eachItem.value}
                  className="option_style"
                >
                  {eachItem.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col
          xxl={{ span: 6 }}
          xl={{ span: 6 }}
          lg={{ span: 6 }}
          md={{ span: 12 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <span className="title_changes">Delivery Location</span>
          <Form.Item
            name="dropoff"
            rules={[
              {
                required: false,
                errorMessage: true,
                message: "Please Enter Your DropOff Location",
              },
            ]}
          >
            <GooglePlacesAutocomplete
              className="form-input"
              autocompletionRequest={{
                componentRestrictions: {
                  country: ["us"],
                },
              }}
              selectProps={{
                citySearch,
                defaultInputValue: `${
                  leadDetails?.dropoff ? leadDetails?.dropoff : ""
                }`,
                placeholder: "Search Address...",
                onChange: (address) => {
                  this.handleSelect(address, "dropOff");
                  this.getAddress(address, "dropOff");
                  this.setState({
                    dropAddress: address.label,
                  });
                },
              }}
            />
          </Form.Item>
        </Col>

        <Col
          xxl={{ span: 6 }}
          xl={{ span: 6 }}
          lg={{ span: 12 }}
          md={{ span: 12 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <span className="title_changes">Sales Person</span>
          <Form.Item
            name="salesPerson"
            rules={[
              {
                required: true,
                message: "Please Select Sales Person!",
              },
            ]}
            initialValue={leadDetails?.salesPersonId}
          >
            <Select
              size="large"
              defaultValue={leadDetails?.salesPersonId}
              showSearch
              allowClear
              className="select_Height"
              onChange={(val, option) =>
                this.setState({
                  salesContractMinimum: option?.contractMinimum,
                  salesCommission: option?.salesCommission,
                  salesPersonVal: val,
                })
              }
              placeholder="Please Select Sales Person"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {this.props.context.salesPersonsData?.map((eachItem) => (
                <Option
                  key={eachItem.id}
                  value={eachItem.id}
                  salesCommission={eachItem.salesCommission}
                  contractMinimum={eachItem?.contractMinimum || false}
                  className="option_style"
                >
                  {eachItem?.firstName + " " + eachItem?.lastName}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col
          xxl={{ span: 6 }}
          xl={{ span: 6 }}
          lg={{ span: 12 }}
          md={{ span: 12 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <span className="title_changes">Project Information Sheet</span>
          <Form.Item
            name="projectInformationSheet"
            rules={[
              {
                required: false,
                message: "Please Select A Project Information Sheet!",
              },
            ]}
            initialValue={projectInformationSheet}
          >
            <Select
              disabled={
                leadDetails?.projectInformationSheet === "Received" &&
                documentsData?.filter((e) => e.sheetStatus)
              }
              size="large"
              showSearch
              allowClear
              className="select_Height"
              placeholder="Please Select A Project Information Sheet"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              onChange={(e) =>
                this.setState({
                  isSheetModalVisible: e === "Received" ? true : false,
                  projectInformationSheet: e,
                })
              }
            >
              <Option value="Received">Received</Option>
              <Option value="Not Received">Not Received</Option>
            </Select>
          </Form.Item>
        </Col>

        {jobInfo !== "Trucking Job" && (
          <>
            <Col
              xxl={{ span: 6 }}
              xl={{ span: 6 }}
              lg={{ span: 6 }}
              md={{ span: 12 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <span className="title_changes">Tax exempt status</span>
              <Form.Item
                name="taxExempt"
                rules={[
                  {
                    required: true,
                    message: "Please Select Tax exempt!",
                  },
                ]}
                initialValue={
                  leadDetails?.taxExempt ? leadDetails?.taxExempt : "Not Exempt"
                }
              >
                <Select
                  size="large"
                  showSearch
                  allowClear
                  className="select_Height"
                  onChange={(e) =>
                    this.setState({
                      exemptVisible: e === "Exempt" ? true : false,
                    })
                  }
                  placeholder="Please Select A Tax exempt"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  <Option value="Exempt">Exempt</Option>
                  <Option value="Not Exempt">Not Exempt</Option>
                </Select>
              </Form.Item>
            </Col>
          </>
        )}
        <Col
          span={
            selectedStatus === "Build Quote" && jobInfo === "Trucking Job"
              ? 24
              : selectedStatus === "Build Quote" && jobInfo !== "Trucking Job"
              ? 12
              : 6
          }
          xxl={{ span: 6 }}
          xl={{ span: 6 }}
          lg={{ span: 12 }}
          md={{ span: 12 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <span className="title_changes">Notes</span>
          <Form.Item
            name="notes"
            rules={[
              {
                required: false,
                message: "Please Enter Your Notes!",
              },
            ]}
            initialValue={leadDetails?.notes}
          >
            <TextArea
              rows={4}
              style={{
                width: "100%",
                height: "75px",
                borderColor: "#d6d6d6",
              }}
              className="form-input"
              placeholder="Please Enter Notes"
            />
          </Form.Item>
        </Col>
        <Col
          xxl={{ span: 6 }}
          xl={{ span: 6 }}
          lg={{ span: 12 }}
          md={{ span: 12 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
          style={{
            marginTop: jobInfo !== "Trucking Job" ? "-35px" : "0px",
          }}
        >
          <p className="title_changes font">
            Invoice Notes (Ex Job & PO Number)
          </p>
          <Form.Item
            name="invoiceNotes"
            rules={[
              {
                required: false,
                message: "Please Enter Your Notes!",
              },
            ]}
            initialValue={leadDetails?.invoiceNotes}
          >
            <div>
              <div
                style={{
                  minHeight: "150px",
                  borderRadius: "3px",
                  // backgroundColor: "#e3e7ea",
                  display: "flex",
                }}
                className="detail-ans styles"
              >
                <div
                  className="input-side"
                  style={{
                    width: "100%",
                  }}
                >
                  <Input
                    bordered={false}
                    maxLength={25}
                    showCount={true}
                    style={{
                      fontSize: "18px",
                      color: "#000",
                    }}
                    className="form-input"
                    disabled={invoiceNote}
                    name="notes1"
                    onChange={this.handleInvoiceChange}
                    value={invoiceNotes?.notes1}
                  />

                  <Input
                    maxLength={25}
                    showCount={true}
                    bordered={false}
                    style={{
                      fontSize: "18px",
                      color: "#000",
                    }}
                    disabled={invoiceNote}
                    name="notes2"
                    className="form-input"
                    onChange={this.handleInvoiceChange}
                    value={invoiceNotes?.notes2}
                  />
                  <Input
                    maxLength={25}
                    showCount={true}
                    bordered={false}
                    style={{
                      fontSize: "18px",
                      color: "#000",
                    }}
                    className="form-input"
                    disabled={invoiceNote}
                    name="notes3"
                    onChange={this.handleInvoiceChange}
                    value={invoiceNotes?.notes3}
                  />
                </div>
              </div>
              <h4
                style={{
                  fontSize: "9px",
                  fontWeight: 600,
                  color: "red",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                WARNING: WILL DISPLAY ON INVOICE EXACTLY AS TYPED
              </h4>
            </div>
          </Form.Item>
        </Col>
      </Row>
    );
  };

  handleMimimumHours = (value) => {
    if (value && value > 12) {
      this.setState({
        exceedHourLimit: true,
      });
    } else {
      this.setState({
        exceedHourLimit: false,
      });
    }
  };

  handleLandedPrice = (showLandedPrice) => {
    return (
      <div className="switch_customPrice">
        <span
          className="title_changes"
          style={{ marginRight: "10px", fontWeight: "600" }}
        >
          {" "}
          Landed Rate{" "}
        </span>
        <Switch
          unCheckedChildren="No"
          checkedChildren="Yes"
          checked={showLandedPrice}
          onChange={(val) =>
            this.setState({
              showLandedPrice: val,
              landedPriceError: false,
            })
          }
        />
      </div>
    );
  };

  showLandedPrice = (
    showLandedPrice,
    truckingPriceType,
    totalPriceType,
    materialPriceState,
    materialTruckPrice,
    landedPriceType,
    landedPriceState
  ) => {
    const { landedPriceError } = this.state;
    const selectAfterLandedPrice = (
      <Select
        value={landedPriceType}
        className="select-after"
        onChange={(e) => {
          this.setState({
            landedPriceType: e,
          });
        }}
      >
        <Option value="Ton">Ton</Option>
        <Option value="Load">Load</Option>
        <Option value="Yard">Yard</Option>
      </Select>
    );
    if (showLandedPrice && truckingPriceType !== totalPriceType) {
      return (
        <>
          <InputNumber
            prefix={"$"}
            precision={2}
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            decimalSeparator={"."} // value={quantity}
            placeholder="Please Enter Landed Price"
            addonAfter={selectAfterLandedPrice}
            style={{
              height: "40px",
              width: "100%",
            }}
            onChange={(e) => {
              this.setState({
                landedPriceState: e,
                landedPriceError: e === null ? true : e !== "" ? false : true,
              });
            }}
            value={landedPriceState !== "N/A" && landedPriceState}
          />
          {landedPriceError && (
            <div style={{ color: "red" }}>Please Enter Landed Price</div>
          )}
        </>
      );
    } else if (
      truckingPriceType === totalPriceType &&
      materialPriceState &&
      materialTruckPrice
    ) {
      return `$${parseFloat(
        materialPriceState + materialTruckPrice
      )?.toLocaleString("us-EN", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })} / ${truckingPriceType}`;
    }
    return "N/A";
  };

  showLandedCost = (
    materialCostType,
    truckingCostType,
    initialMaterialCost,
    truckingCost
  ) => {
    if (
      materialCostType === truckingCostType &&
      initialMaterialCost &&
      truckingCost
    ) {
      return `$${parseFloat(initialMaterialCost + truckingCost)?.toLocaleString(
        "us-EN",
        {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }
      )}
           / ${materialCostType}`;
    }
    return "N/A";
  };

  showTotalSale = (
    truckingPriceType,
    totalPriceType,
    materialPriceState,
    quantity,
    materialTruckPrice
  ) => {
    if (
      truckingPriceType === totalPriceType &&
      materialPriceState &&
      quantity &&
      materialTruckPrice
    ) {
      return `$${parseFloat(
        (materialPriceState + materialTruckPrice) * quantity
      )?.toLocaleString("us-EN", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`;
    }
    return "N/A";
  };

  showTotalExepense = (
    materialCostType,
    truckingCostType,
    initialMaterialCost,
    quantity,
    truckingCost
  ) => {
    if (
      materialCostType === truckingCostType &&
      initialMaterialCost &&
      quantity &&
      truckingCost
    ) {
      return `$${parseFloat(
        (initialMaterialCost + truckingCost) * quantity
      )?.toLocaleString("us-EN", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`;
    }
    return "N/A";
  };

  showTotalProfit = (
    truckingPriceType,
    totalPriceType,
    materialCostType,
    truckingCostType,
    materialPriceState,
    materialTruckPrice,
    quantity,
    initialMaterialCost,
    truckingCost
  ) => {
    // Helper function to check if a value is defined and not an empty string
    const isValid = (value) => value !== undefined && value !== "";

    if (
      truckingPriceType === totalPriceType &&
      materialCostType === truckingCostType &&
      isValid(materialPriceState) &&
      isValid(materialTruckPrice) &&
      isValid(quantity) &&
      isValid(initialMaterialCost) &&
      isValid(truckingCost)
    ) {
      // Calculate profit and percentage
      const totalIncome = (materialPriceState + materialTruckPrice) * quantity;
      const totalCost = (initialMaterialCost + truckingCost) * quantity;
      const profit = totalIncome - totalCost;
      const profitPercentage = (profit / totalIncome) * 100;

      return `$${parseFloat(profit).toLocaleString("us-EN", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })} (${
        !isNaN(profitPercentage) ? profitPercentage.toFixed(2) : "0.00"
      }%)`;
    }

    return "N/A";
  };

  calculateTotalProfit = (
    truckingPriceType,
    totalPriceType,
    materialCostType,
    truckingCostType,
    materialPriceState,
    materialTruckPrice,
    quantity,
    initialMaterialCost,
    truckingCost,
    type
  ) => {
    const isValid = (value) => value !== undefined && value !== "";
    if (
      truckingPriceType === totalPriceType &&
      materialCostType === truckingCostType &&
      isValid(materialPriceState) &&
      isValid(materialTruckPrice) &&
      isValid(quantity) &&
      isValid(initialMaterialCost) &&
      isValid(truckingCost)
    ) {
      // Calculate profit and percentage
      const totalIncome = (materialPriceState + materialTruckPrice) * quantity;
      const totalCost = (initialMaterialCost + truckingCost) * quantity;
      const profit = totalIncome - totalCost;
      const profitPercentage = (profit / totalIncome) * 100;
      return type === "profit"
        ? parseFloat(profit).toFixed(2)
        : profitPercentage.toFixed(2);
    }
  };

  showBottomValues = () => {
    const {
      materialCostType,
      truckingCostType,
      initialMaterialCost,
      truckingCost,
      vendorDetails,
      quantity,
      truckingPriceType,
      totalPriceType,
      materialPriceState,
      materialTruckPrice,
      landedPriceType,
      showLandedPrice,
      leadDetails,
      landedPriceState,
    } = this.state;
    return (
      <>
        <Divider
          style={{
            width: "100%",
            border: "1px solid #dbdbdb",
          }}
        />
        {truckingPriceType !== totalPriceType &&
          this.handleLandedPrice(showLandedPrice)}
        <Col
          xxl={{ span: 8 }}
          xl={{ span: 8 }}
          lg={{ span: 24 }}
          md={{ span: 24 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <Row justify="space-between">
            <Col span={12}>
              <span className="title_changes">
                <a
                  href={`https://www.google.com/maps/dir/${vendorDetails.address}/${leadDetails?.dropoff}`}
                  alt="mapDirection"
                  rel="noreferrer"
                  target="_blank"
                  style={{ fontSize: "16px" }}
                >
                  Job Map -
                </a>{" "}
              </span>
            </Col>
            <Col span={12}>
              <div style={{ textTransform: "capitalize" }}>
                {vendorDetails?.duration && vendorDetails?.duration}
              </div>
              <div style={{ textTransform: "capitalize" }}>
                {vendorDetails?.milesData + "les "}
              </div>
            </Col>
          </Row>
        </Col>
        <Col
          xxl={{ span: 8 }}
          xl={{ span: 8 }}
          lg={{ span: 24 }}
          md={{ span: 24 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <Row justify="space-between">
            <Col span={12}>
              <span className="title_changes">Landed Cost - </span>
            </Col>
            <Col span={12}>
              {this.showLandedCost(
                materialCostType,
                truckingCostType,
                initialMaterialCost,
                truckingCost
              )}
            </Col>
          </Row>
        </Col>
        <Col
          xxl={{ span: 8 }}
          xl={{ span: 8 }}
          lg={{ span: 24 }}
          md={{ span: 24 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <Row>
            <Col span={12}>
              <span className="title_changes">Landed Price - </span>
            </Col>
            <Col span={12}>
              {this.showLandedPrice(
                showLandedPrice,
                truckingPriceType,
                totalPriceType,
                materialPriceState,
                materialTruckPrice,
                landedPriceType,
                landedPriceState
              )}
            </Col>
          </Row>
        </Col>
        <Divider
          style={{
            width: "100%",
            border: "1px solid #dbdbdb",
          }}
        />
        <Col
          xxl={{ span: 8 }}
          xl={{ span: 8 }}
          lg={{ span: 24 }}
          md={{ span: 24 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <Row>
            <Col span={12}>
              <span className="title_changes">Total Sale - </span>
            </Col>
            <Col span={12}>
              {this.showTotalSale(
                truckingPriceType,
                totalPriceType,
                materialPriceState,
                quantity,
                materialTruckPrice
              )}
            </Col>
          </Row>
        </Col>
        <Col
          xxl={{ span: 8 }}
          xl={{ span: 8 }}
          lg={{ span: 24 }}
          md={{ span: 24 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <Row>
            <Col span={12}>
              <span className="title_changes">Total Expense - </span>
            </Col>
            <Col span={12}>
              {this.showTotalExepense(
                materialCostType,
                truckingCostType,
                initialMaterialCost,
                quantity,
                truckingCost
              )}
            </Col>
          </Row>
        </Col>

        <Col
          xxl={{ span: 8 }}
          xl={{ span: 8 }}
          lg={{ span: 24 }}
          md={{ span: 24 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <Row>
            <Col span={12}>
              <span className="title_changes">Total Profit (%) -</span>
            </Col>
            <Col span={12}>
              {this.showTotalProfit(
                truckingPriceType,
                totalPriceType,
                materialCostType,
                truckingCostType,
                materialPriceState,
                materialTruckPrice,
                quantity,
                initialMaterialCost,
                truckingCost
              )}
            </Col>
          </Row>
        </Col>
      </>
    );
  };

  render() {
    const {
      exceedHourLimit,
      documentsData,
      exemptVisible,
      editUpdateMaterial,
      materialTruckType,
      // truckCustomRate,
      smallAll,
      bigAll,
      checkAll,
      indeterminate,
      quarryDetails,
      showMaterialValues,
      imageLoading,
      isSheetModalVisible,
      newRole,
      rolesData,
      selectedMaterialType,
      subCategory1,
      subCategory2,
      materialCategory,
      customRate,
      materialCustomRate,
      truckingPriceType,
      totalPriceType,
      duration,
      milesData,
      selectedStatus,
      citySearch,
      selectedRowKeys,
      materialCostType,
      truckingCostType,
      internalLoading,
      diameter,
      showJobProfile,
      jobInfo,
      dropAddress,
      selectedMaterial,
      recordMaterialData,
      isContactVisible,
      contactsData,
      materialPopup,
      leadDetails,
      initialMaterialCost,
      trucksData,
      topVendorsData,
      vendorDetails,
      invoiceNotes,
      invoiceNote,
      materialTax,
      projectInformationSheet,
      showTableModal,
    } = this.state;
    const errorMessage = "The Minimum hours must be 12 hours or less";
    const sortedList =
      recordMaterialData?.truckType?.length > 0 &&
      recordMaterialData?.truckType?.slice().sort();
    const areUnitsMatching = totalPriceType === materialCostType;
    const topVendorsColumns = [
      {
        title: "VENDOR NAME",
        dataIndex: "quarryName",
        key: "quarryName",
        sorter: false,
        className: "col_style_quarry",
        render: (quarryName, record) => {
          return (
            <Row
              style={{
                display: "flex",
                alignItems: "center",
                minWidth: "fit-content",
              }}
            >
              {quarryName}
            </Row>
          );
        },
      },
      {
        title: "RUN & RATE INFORMATION",
        dataIndex: "milesData",
        key: "milesData",
        sorter: false,
        className: "col_style_quarry",
        render: (milesData, record) => {
          return (
            <span className="col_style_quarry">
              <div style={{ textTransform: "capitalize" }}>
                {record?.duration}
              </div>
              {milesData !== undefined && (
                <div style={{ textTransform: "capitalize" }}>
                  {milesData + "les"}
                </div>
              )}
            </span>
          );
        },
      },
      {
        title: "Material",
        dataIndex: "category",
        key: "category",
        sorter: false,
        className: "col_style_quarry",
        render: (category, record) => {
          return (
            <Row
              style={{
                display: "flex",
                alignItems: "center",
                minWidth: "fit-content",
              }}
            >
              {category + " - " + record.label}
            </Row>
          );
        },
      },
      {
        title: "COST / Ton",
        dataIndex: "price",
        key: "price",
        sorter: false,
        className: "col_style_quarry",
        render: (price, record) => {
          return (
            <Row
              style={{
                display: "flex",
                alignItems: "center",
                minWidth: "fit-content",
              }}
            >
              {"$" + price?.toFixed(2)}
            </Row>
          );
        },
      },
      {
        title: "Testing",
        dataIndex: "materialTest",
        key: "materialTest",
        sorter: false,
        className: "col_style_quarry",
        render: (materialTest) => {
          return (
            <Row
              style={{
                display: "flex",
                alignItems: "center",
                minWidth: "fit-content",
              }}
            >
              {materialTest}
            </Row>
          );
        },
      },
      {
        title: "Washed",
        dataIndex: "materialStatus",
        key: "materialStatus",
        sorter: false,
        className: "col_style_quarry",
        render: (materialStatus) => {
          return (
            <Row
              style={{
                display: "flex",
                alignItems: "center",
                minWidth: "fit-content",
              }}
            >
              {materialStatus}
            </Row>
          );
        },
      },
      {
        title: "Supply",
        dataIndex: "materialSupply",
        key: "materialSupply",
        sorter: false,
        className: "col_style_quarry",
        render: (materialSupply) => {
          return (
            <Row
              style={{
                display: "flex",
                alignItems: "center",
                minWidth: "fit-content",
              }}
            >
              {materialSupply}
            </Row>
          );
        },
      },
      {
        title: "",
        dataIndex: "materialSupply",
        key: "materialSupply",
        sorter: false,
        className: "col_style_quarry",
        render: (materialSupply, record) => {
          return (
            <Row
              style={{
                display: "flex",
                alignItems: "center",
                minWidth: "fit-content",
              }}
              onClick={() => this.removeVendorsList(record)}
            >
              <CloseOutlined style={{ color: "red" }} />
            </Row>
          );
        },
      },
    ];

    const selectAfter = (
      <Select
        defaultValue={this.state?.quantityType}
        className="select-after"
        onChange={(e) => {
          this.setState({ quantityType: e });
        }}
      >
        <Option value="Ton">Ton</Option>
        <Option value="Load">Load</Option>
        <Option value="Yard">Yard</Option>
      </Select>
    );

    const selectAfterMaterialPrice = (
      <Select
        value={totalPriceType}
        className="select-after"
        onChange={(e) => {
          if (!this?.state?.materialCustomRate) {
            this.setState({
              totalPriceType: e,
              materialCostType: e,
              showLandedPrice: false,
              landedPriceError: false,
            });
          } else {
            this.setState({
              totalPriceType: e,
            });
          }
        }}
        // disabled={!this.state.materialCustomRate}
      >
        <Option value="Ton">Ton</Option>
        <Option value="Load">Load</Option>
        <Option value="Yard">Yard</Option>
      </Select>
    );

    const selectAfterMaterialCost = (
      <Select
        value={materialCostType}
        className="select-after"
        onChange={(e) => {
          this.setState({ materialCostType: e });
          if (!this.state.materialCustomRate) {
            this.setState({ totalPriceType: e });
          }
        }}
        disabled={!this.state.materialCustomRate}
      >
        <Option value="Ton">Ton</Option>
        <Option value="Load">Load</Option>
        <Option value="Yard">Yard</Option>
      </Select>
    );

    const selectAfterTruckCost = (
      <Select
        value={truckingCostType}
        className="select-after"
        onChange={(e) => {
          this.setState({ truckingCostType: e });
          if (!this.state.customRate) {
            this.setState({ truckingPriceType: e });
          }
        }}
        disabled={!this.state.customRate}
      >
        <Option value="Ton">Ton</Option>
        <Option value="Load">Load</Option>
        <Option value="Hour">Hour</Option>
      </Select>
    );

    const selectAfterTruckPrice = (
      <Select
        value={truckingPriceType}
        className="select-after"
        onChange={(e) => {
          this.setState({
            truckingPriceType: e,
            showLandedPrice: false,
            landedPriceError: false,
          });
          if (!this.state.customRate) {
            this.setState({ truckingCostType: e });
          }
        }}
      >
        <Option value="Ton">Ton</Option>
        <Option value="Load">Load</Option>
        <Option value="Hour">Hour</Option>
      </Select>
    );

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const { loading } = this.props;

    return (
      <div>
        <Loading
          enableLoading={
            !loading && !internalLoading && contactsData?.length > 0
              ? false
              : true
          }
        />
        <Row onClick={this.onClickReturn} style={{ cursor: "pointer" }}>
          <BsArrowLeftShort
            style={{ color: "#5f6c74", fontSize: "23px" }}
            className="back-button-return-icon"
          />
          <p
            style={{
              color: "#808080",
              fontSize: "16px",
              fontWeight: "600",
            }}
            className="back-button-return"
          >
            Return
          </p>
        </Row>

        <div>
          <Card title={<span>{"Job Number: #" + leadDetails?.jobNumber}</span>}>
            <Form
              onFinish={(values) => this.onFinishJobForm(values, "autoSave")}
              id="jobForm"
              ref={this.exemptRef}
            >
              {showJobProfile &&
                this.showJobProfileData(
                  leadDetails,
                  contactsData,
                  jobType,
                  isContactVisible,
                  selectedStatus,
                  citySearch,
                  documentsData,
                  jobInfo,
                  invoiceNotes,
                  invoiceNote,
                  projectInformationSheet
                )}
              {!showJobProfile &&
                this.showJobProfileData(
                  leadDetails,
                  contactsData,
                  jobType,
                  isContactVisible,
                  selectedStatus,
                  citySearch,
                  documentsData,
                  jobInfo,
                  invoiceNotes,
                  invoiceNote,
                  projectInformationSheet
                )}
              {jobInfo !== "Trucking Job" && (
                <>
                  {leadDetails?.requiredMaterials && (
                    <Row
                      span={24}
                      justify="center"
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        marginTop: "10px",
                      }}
                    >
                      <Col
                        className="title_changes"
                        style={{
                          fontSize: "17px",
                          fontWeight: "700",
                          textAlign: "center",
                          color: "rgb(88,99,112)",
                        }}
                      >
                        Material Notes:
                      </Col>
                      <Col
                        className="title_changes"
                        style={{
                          fontSize: "17px",
                          fontWeight: "700",
                          marginLeft: "12px",
                        }}
                      >
                        {` ${leadDetails?.requiredMaterials}`}
                      </Col>
                    </Row>
                  )}
                </>
              )}

              {jobInfo !== "Trucking & Material Job" && (
                <Row>
                  <Row
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "10px",
                      marginTop: "15px",
                    }}
                  >
                    <span
                      style={{
                        marginBottom: "10px",
                        display: "flex",
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "rgb(88,99,112)",
                        textTransform: "capitalize",
                      }}
                    >
                      Trucking Details:
                    </span>
                    <span>
                      <Button
                        icon={<PlusOutlined />}
                        style={{
                          backgroundColor: "#586370",
                          color: "#ffffff",
                          borderRadius: "3px",
                        }}
                        onClick={() => {
                          dropAddress !== "" && dropAddress !== undefined
                            ? this.setState({
                                showTableModal: "Trucking Job",
                                materialPopup: true,
                                materialSelectedData: "",
                                finalMaterialData: [],
                                selectVendor: "",
                                duration: "",
                                milesData: "",
                                truckingCostType: "Ton",
                                truckingPriceType: "Ton",
                                customRate: false,
                                materialCustomRate: false,
                                bigAll: false,
                                smallAll: false,
                                checkAll: false,
                                materialTruckType: [],
                              })
                            : message.info(
                                "Please Enter Your Delivery Location!"
                              );
                        }}
                      >
                        Add Trucking Details
                      </Button>
                    </span>
                  </Row>
                  <Col
                    span={24}
                    style={{ overflowX: "auto" }}
                    className="twistedtable"
                  >
                    <table
                      className="lead-details-trucking-details"
                      style={{ width: "100%" }}
                    >
                      <thead>
                        <tr>
                          <th>Pickup Location</th>
                          <th>Material Name</th>
                          <th>Truck Types</th>
                          <th>Trucking Cost</th>
                          <th>Trucking Price</th>
                          {selectedMaterial?.filter(
                            (e) =>
                              !e.category &&
                              (e.truckingCostType === "Hour" ||
                                e.truckingPriceType === "Hour")
                          )?.length > 0 && <th>Minimum Hours</th>}
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedMaterial
                          ?.filter((e) => !e.category)
                          ?.map((data, index) => (
                            <tr
                              onDoubleClick={() => {
                                this.setState(
                                  {
                                    showTableModal: "Trucking Job",
                                  },
                                  () => this.editMaterial(data)
                                );
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <td>{data?.pickUpAddress}</td>
                              <td>{data?.materialName}</td>
                              <td>
                                {data?.truckType?.length > 0 &&
                                  trucksData
                                    ?.flatMap((e) =>
                                      data?.truckType?.map((d) =>
                                        d === e.value ? e.truck_code : null
                                      )
                                    )
                                    ?.filter((code) => code !== null)
                                    .join(", ")}
                              </td>

                              <td>
                                {data?.truckingCost && data?.truckingCostType
                                  ? `$ ${parseFloat(
                                      data?.truckingCost
                                    )?.toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })} / ${data?.truckingCostType}`
                                  : ""}
                              </td>
                              <td>
                                {data?.truckingPrice && data?.truckingPriceType
                                  ? `$ ${parseFloat(
                                      data?.truckingPrice
                                    )?.toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })} / ${data?.truckingPriceType}`
                                  : ""}
                              </td>
                              {selectedMaterial?.filter(
                                (e) =>
                                  !e.category &&
                                  (e.truckingCostType === "Hour" ||
                                    e.truckingPriceType === "Hour")
                              )?.length > 0 && (
                                <td>
                                  {data.hourlyCharge
                                    ? `${data.hourlyCharge} Hours`
                                    : ""}
                                </td>
                              )}
                              <td>
                                <Button
                                  style={{ marginLeft: "6px", width: "80px" }}
                                  onClick={() => {
                                    this.setState(
                                      {
                                        showTableModal: "Trucking Job",
                                      },
                                      () => this.editMaterial(data)
                                    );
                                  }}
                                  type="primary"
                                >
                                  Edit
                                </Button>
                                <Button
                                  style={{ marginLeft: "6px" }}
                                  onClick={() => this.deleteMaterial(data)}
                                  type="primary"
                                >
                                  Delete
                                </Button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </Col>
                </Row>
              )}

              <Row>
                <Row
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "10px",
                    marginTop: "15px",
                  }}
                >
                  <span
                    style={{
                      marginBottom: "10px",
                      display: "flex",
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "rgb(88,99,112)",
                      textTransform: "capitalize",
                    }}
                  >
                    Material Details:
                  </span>
                  <span>
                    <Button
                      icon={<PlusOutlined />}
                      style={{
                        backgroundColor: "#586370",
                        color: "#ffffff",
                        borderRadius: "3px",
                      }}
                      onClick={() => {
                        dropAddress !== "" && dropAddress !== undefined
                          ? this.showStates()
                          : message.info(
                              "Please Enter Your Delivery Location!"
                            );
                      }}
                    >
                      Add Material
                    </Button>
                  </span>
                </Row>
                <Col
                  span={24}
                  style={{ overflowX: "auto" }}
                  className="twistedtable"
                >
                  <table style={{ width: "100%" }}>
                    <thead>
                      <tr>
                        <th>Vendor</th>
                        <th>Material</th>
                        <th>Truck Types</th>
                        <th>Quantity</th>
                        <th>Material Cost</th>
                        <th>Material Price</th>
                        <th>Trucking Cost</th>
                        <th>Trucking Price</th>
                        <th>Landed Price</th>
                        {selectedMaterial?.filter(
                          (e) =>
                            e.category &&
                            (e.truckingCostType === "Hour" ||
                              e.truckingPriceType === "Hour")
                        )?.length > 0 && <th>Minimum Hours</th>}
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedMaterial
                        ?.filter((e) => e.category)
                        ?.map((data, index) => (
                          <tr
                            onDoubleClick={() => {
                              this.setState(
                                {
                                  showTableModal: "Trucking & Material Job",
                                },
                                () => this.editMaterial(data)
                              );
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <td>{data?.vendor}</td>
                            <td>
                              <div>
                                {data.category +
                                  " - " +
                                  data?.label?.split(" - ")[0]}
                              </div>
                              <div>{data?.label?.split(" - ")[1]}</div>
                            </td>
                            <td>
                              {data?.truckType?.length > 0 &&
                                trucksData
                                  ?.flatMap((e) =>
                                    data?.truckType?.map((d) =>
                                      d === e.value ? e.truck_code : null
                                    )
                                  )
                                  .filter((code) => code !== null)
                                  .join(", ")}
                            </td>
                            <td>
                              {data?.quantity && data?.quantityType
                                ? `${data?.quantity} - ${data?.quantityType}`
                                : ""}
                            </td>
                            <td>
                              {data?.materialCost && data?.materialCostType
                                ? `$ ${parseFloat(
                                    data?.materialCost
                                  )?.toLocaleString("us-En", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })} / ${data?.materialCostType}`
                                : ""}
                            </td>
                            <td>
                              {data?.totalPrice && data?.totalPriceType
                                ? `$ ${parseFloat(
                                    data?.totalPrice
                                  )?.toLocaleString("us-En", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })} / ${data?.totalPriceType}`
                                : ""}
                            </td>
                            <td>
                              {data?.truckingCost && data?.truckingCostType
                                ? `$ ${parseFloat(
                                    data?.truckingCost
                                  )?.toLocaleString("us-En", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })} / ${data?.truckingCostType}`
                                : ""}
                            </td>
                            <td>
                              {data?.truckingPrice && data?.truckingPriceType
                                ? `$ ${parseFloat(
                                    data?.truckingPrice
                                  )?.toLocaleString("us-En", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })} / ${data?.truckingPriceType}`
                                : ""}
                            </td>
                            <td>
                              {data?.landedPrice === "N/A"
                                ? data?.landedPrice
                                : `$ ${parseFloat(
                                    data?.landedPrice
                                  )?.toLocaleString("us-En", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })} / ${
                                    data?.landedPriceType ||
                                    data?.totalPriceType
                                  }`}
                            </td>
                            {selectedMaterial?.filter(
                              (e) =>
                                e.category &&
                                (e.truckingCostType === "Hour" ||
                                  e.truckingPriceType === "Hour")
                            )?.length > 0 && (
                              <td>
                                {data.hourlyCharge
                                  ? `${data.hourlyCharge} Hours`
                                  : ""}
                              </td>
                            )}
                            <td>
                              <Popover
                                placement="left"
                                content={
                                  <Row
                                    className="popovergrid"
                                    style={{ maxWidth: "90px" }}
                                  >
                                    <Col span={24}>
                                      <Button
                                        className="btn_edit"
                                        onClick={() => {
                                          this.setState(
                                            {
                                              showTableModal:
                                                "Trucking & Material Job",
                                            },
                                            () => this.editMaterial(data)
                                          );
                                        }}
                                      >
                                        <span className="span_edit">
                                          <MdModeEditOutline
                                            style={{ marginRight: "5px" }}
                                          />
                                          Edit
                                        </span>
                                      </Button>
                                    </Col>
                                    <Col span={24}>
                                      <Button className="btn_edit">
                                        <Popconfirm
                                          title="Are you sure？"
                                          okText="Yes"
                                          cancelText="No"
                                          onConfirm={() =>
                                            this.deleteMaterial(data)
                                          }
                                        >
                                          <span className="span_edit">
                                            <MdDelete
                                              style={{ marginRight: "5px" }}
                                            />{" "}
                                            Delete
                                          </span>
                                        </Popconfirm>
                                      </Button>
                                    </Col>
                                  </Row>
                                }
                              >
                                <EllipsisOutlined
                                  style={{ fontSize: "30px", color: "grey" }}
                                  className="action-ellipsis-button"
                                />
                              </Popover>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </Col>
              </Row>

              <Col
                span={24}
                style={{
                  marginTop: "1vw",
                  display: "flex",
                  justifyContent: "end",
                  borderTop: "1px solid #f0f0f0",
                }}
              >
                <div>
                  <Button
                    style={{
                      height: "40px",
                      width: "100px",
                      borderRadius: "5px",
                      marginRight: "25px",
                      cursor: "pointer",
                      marginTop: "1vw",
                    }}
                    htmlType="reset"
                    onClick={this.onClickReturn}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{
                      height: "40px",
                      width: "100px",
                      borderRadius: "5px",
                      cursor: "pointer",
                      marginTop: "1vw",
                    }}
                    loading={loading}
                    form={"jobForm"}
                  >
                    Submit
                  </Button>
                </div>
              </Col>
            </Form>
          </Card>
        </div>

        {materialPopup && (
          <BaseModal
            title={
              Object.values(recordMaterialData)?.length !== 0 &&
              showTableModal === "Trucking Job"
                ? "Update Trucking Details"
                : Object.values(recordMaterialData)?.length !== 0 &&
                  showTableModal !== "Trucking Job"
                ? "Update Material Details"
                : showTableModal !== "Trucking Job"
                ? "Add Material Details"
                : "Add Trucking Details"
            }
            onCancel={this.onCancelMaterial}
            loading={loading}
            formId="material"
            className="antmodal_grid headermodal"
            submitButton={
              showTableModal === "Trucking & Material Job" &&
              topVendorsData?.length === 0
                ? "Find Nearest Vendors"
                : Object.values(recordMaterialData)?.length !== 0 &&
                  showTableModal !== "Trucking Job"
                ? "Update"
                : "Add"
            }
            btnWidth={"fit-content"}
            width={showTableModal === "Trucking & Material Job" ? "80%" : "35%"}
            height={"60%"}
          >
            <Loading enableLoading={loading} />
            <div
              style={{
                position: "absolute",
                top: "10px",
                right: "50px",
              }}
              className="show-on-small-desktop"
            >
              <Button
                onClick={this.onCancelMaterial}
                style={{
                  height: "35px",
                  borderRadius: "5px",
                  marginRight: "25px",
                  cursor: "pointer",
                }}
                htmlType="reset"
              >
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  height: "35px",
                  cursor: "pointer",
                  backgroundColor: "#FFF",
                  borderRadius: "5px",
                  color: "#586370",
                }}
                loading={loading}
                form={"material"}
              >
                {showTableModal === "Trucking & Material Job" &&
                topVendorsData?.length === 0
                  ? "Find Nearest Vendors"
                  : Object.values(recordMaterialData)?.length !== 0 &&
                    showTableModal !== "Trucking Job"
                  ? "Update"
                  : "Add"}
              </Button>
            </div>
            <Form
              onFinish={
                showTableModal === "Trucking & Material Job" &&
                topVendorsData?.length === 0
                  ? this.findNearestVendors
                  : this.onFinishMaterial
              }
              initialValues={{ remember: true }}
              id="material"
              ref={this.formRef1}
              style={{ width: "100%" }}
            >
              <Row style={{ width: "100%" }} gutter={[24, 0]}>
                {showTableModal !== "Trucking Job" && (
                  <>
                    <Col
                      xxl={{ span: 8 }}
                      xl={{ span: 8 }}
                      lg={{ span: 8 }}
                      md={{ span: 12 }}
                      sm={{ span: 12 }}
                      xs={{ span: 24 }}
                    >
                      <span className="title_changes">Material Categories</span>
                      <Form.Item
                        name="materialCategory"
                        rules={[
                          {
                            required: true,
                            message: "Please Select Your Material Category!",
                          },
                        ]}
                        style={{ marginBottom: "10px" }}
                        initialValue={selectedMaterialType}
                      >
                        <Select
                          mode="multiple"
                          listHeight={750}
                          size="large"
                          showSearch
                          allowClear
                          className="select_multiplequarytol"
                          placeholder="Please Select A Material Category"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          onChange={(e, option) => {
                            this.setState({
                              selectedMaterialType: e,
                              materialCategoryId: option,
                            });
                            if (e) {
                              this.formRef1.current.setFieldsValue({
                                subCategory1: undefined,
                                subCategory2: undefined,
                              });
                              this.setState({
                                subCategory1: [],
                                subCategory2: [],
                                topVendorsData: [],
                                vendorDetails: "",
                                selectedRowKeys: [],
                              });
                            }
                          }}
                        >
                          {Object.keys(materialCategory)?.map((c, i) => (
                            <Option
                              key={i}
                              value={c}
                              label={Object.values(materialCategory[c])}
                            >
                              {c}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col
                      xxl={{ span: 8 }}
                      xl={{ span: 8 }}
                      lg={{ span: 8 }}
                      md={{ span: 12 }}
                      sm={{ span: 12 }}
                      xs={{ span: 24 }}
                    >
                      <span className="title_changes">Material Type</span>
                      <Form.Item
                        name="subCategory1"
                        rules={[
                          {
                            required: false,
                            message: "Please Enter Your Material Type!",
                          },
                        ]}
                        initialValue={
                          recordMaterialData?.label?.split(" - ")[0]
                        }
                        style={{ marginBottom: "10px" }}
                      >
                        <Select
                          mode="multiple"
                          size="large"
                          showSearch
                          listHeight={630}
                          allowClear
                          className="select_multiplequarytol"
                          placeholder="Please Select A Material Type"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          onChange={(e, option) => {
                            this.setState({
                              subCategory1: e,
                              subCategory2: option,
                              categoryKey: option.labelprop,
                              subShortName: option.labelprop2,
                              topVendorsData: [],
                              vendorDetails: "",
                              selectedRowKeys: [],
                            });
                            if (e) {
                              this.formRef1.current.setFieldsValue({
                                subCategory2: undefined,
                              });
                            }
                          }}
                        >
                          {selectedMaterialType !== undefined &&
                            selectedMaterialType?.length > 0 &&
                            selectedMaterialType?.map((e) =>
                              Object.values(materialCategory[e])?.map(
                                (c, i) => (
                                  <Option
                                    key={c.cate + c.subcat}
                                    value={c.cate + c.subcat}
                                    subTypes={c?.subTypes}
                                    labelprop={c?.categoryKey}
                                    labelprop2={c?.subShortName}
                                  >
                                    {selectedMaterialType?.length >= 2
                                      ? c?.subcat + " (" + c.cate + ")"
                                      : c?.subcat}
                                  </Option>
                                )
                              )
                            )}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col
                      xxl={{ span: 8 }}
                      xl={{ span: 8 }}
                      lg={{ span: 8 }}
                      md={{ span: 12 }}
                      sm={{ span: 12 }}
                      xs={{ span: 24 }}
                    >
                      <span
                        className="title_changes"
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        Material Name
                      </span>
                      <Form.Item
                        name="subCategory2"
                        rules={[
                          {
                            required: false,
                            message: "Please Enter Your Material Name!",
                          },
                        ]}
                        initialValue={
                          subCategory1?.length > 0
                            ? recordMaterialData?.id
                            : recordMaterialData?.value?.split(" - ")[2]
                        }
                        style={{ marginBottom: "10px" }}
                      >
                        <Select
                          mode="multiple"
                          size="large"
                          showSearch
                          allowClear
                          className="select_multiplequarytol"
                          placeholder="Please Select A Material Name"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          onChange={this.onSelectMaterial}
                        >
                          {selectedMaterialType &&
                            subCategory1 &&
                            subCategory2?.map((l) =>
                              l.subTypes?.map(
                                (c) =>
                                  c.subType !== "" && (
                                    <Option key={c.id} value={c.id}>
                                      {c.subType}
                                    </Option>
                                  )
                              )
                            )}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col
                      xxl={{ span: 8 }}
                      xl={{ span: 8 }}
                      lg={{ span: 8 }}
                      md={{ span: 12 }}
                      sm={{ span: 12 }}
                      xs={{ span: 24 }}
                      style={{ marginTop: "10px" }}
                    >
                      <span
                        className="title_changes"
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        Quarry Name
                      </span>
                      <Form.Item
                        name="quarryName"
                        rules={[
                          {
                            required: false,
                            message: "Please Enter Your Quarry!",
                          },
                        ]}
                        initialValue={recordMaterialData?.quarryName}
                        style={{ marginBottom: "10px" }}
                      >
                        <Select
                          mode="multiple"
                          size="large"
                          showSearch
                          allowClear
                          className="select_multiplequarytol"
                          placeholder="Please Select A Quarry Name"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          onChange={this.resetvendorValues}
                        >
                          {quarryDetails?.map((q) => (
                            <Option key={q.id} value={q.id}>
                              {q.quarryName}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col
                      xxl={{ span: 8 }}
                      xl={{ span: 8 }}
                      lg={{ span: 8 }}
                      md={{ span: 12 }}
                      sm={{ span: 12 }}
                      xs={{ span: 24 }}
                      style={{ marginTop: "10px" }}
                    >
                      <span
                        className="title_changes"
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        Search Radius
                      </span>
                      <Form.Item
                        name="radius"
                        rules={[
                          {
                            required: subCategory1?.length > 0 ? false : true,
                            message: "Please Enter Your Radius!",
                          },
                        ]}
                        initialValue={recordMaterialData?.radius}
                        style={{ marginBottom: "10px" }}
                      >
                        <InputNumber
                          placeholder="Please Enter Your Radius"
                          addonAfter={"Miles"}
                          style={{
                            height: "40px",
                            width: "100%",
                          }}
                          onChange={this.resetvendorValues}
                        />
                      </Form.Item>
                    </Col>

                    {diameter ? (
                      <Col
                        xxl={{ span: 8 }}
                        xl={{ span: 8 }}
                        lg={{ span: 8 }}
                        md={{ span: 12 }}
                        sm={{ span: 12 }}
                        xs={{ span: 24 }}
                        style={{ marginTop: "10px" }}
                      >
                        <Row gutter={[12, 0]}>
                          <Col span={12}>
                            <span className="title_changes">Min</span>
                            <Form.Item
                              name="materialMinDiameter"
                              rules={[
                                {
                                  required: false,
                                  message: "Please Enter Diameter!",
                                },
                              ]}
                              initialValue={
                                recordMaterialData?.materialMinDiameter
                              }
                              style={{ marginBottom: "10px" }}
                            >
                              <Input
                                style={{
                                  height: "40px",
                                  width: "100%",
                                }}
                                className="form-input"
                                placeholder="Please enter diameter"
                                onChange={this.resetvendorValues}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <span
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <span className="title_changes">Max</span>
                              <Switch
                                checkedChildren={"Diameter"}
                                unCheckedChildren={"Range"}
                                onChange={(e) => this.setState({ diameter: e })}
                                checked={diameter}
                              >
                                Size Range
                              </Switch>
                            </span>
                            <Form.Item
                              name="materialMaxDiameter"
                              rules={[
                                {
                                  required: false,
                                  message: "Please Enter Diameter!",
                                },
                              ]}
                              initialValue={
                                recordMaterialData?.materialMaxDiameter
                              }
                              style={{ marginBottom: "10px" }}
                            >
                              <Input
                                style={{
                                  height: "40px",
                                  width: "100%",
                                }}
                                className="form-input"
                                placeholder="Please enter diameter"
                                onChange={this.resetvendorValues}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                    ) : (
                      <Col
                        xxl={{ span: 8 }}
                        xl={{ span: 8 }}
                        lg={{ span: 8 }}
                        md={{ span: 12 }}
                        sm={{ span: 12 }}
                        xs={{ span: 24 }}
                        style={{ marginTop: "10px" }}
                      >
                        <span
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span className="title_changes">
                            Material Diameter
                          </span>
                          <Switch
                            checkedChildren={"Diameter"}
                            unCheckedChildren={"Range"}
                            onChange={(e) => this.handleCheckStatus(e)}
                          >
                            Size Range
                          </Switch>
                        </span>
                        <Form.Item
                          name="materialDiameter"
                          rules={[
                            {
                              required: false,
                              message: "Please Enter Diameter!",
                            },
                          ]}
                          initialValue={recordMaterialData?.materialDiameter}
                          style={{ marginBottom: "10px" }}
                        >
                          <Input
                            style={{
                              height: "40px",
                              width: "100%",
                            }}
                            className="form-input"
                            placeholder="Please enter diameter"
                            onChange={this.resetvendorValues}
                          />
                        </Form.Item>
                      </Col>
                    )}

                    <Col
                      xxl={{ span: 8 }}
                      xl={{ span: 8 }}
                      lg={{ span: 8 }}
                      md={{ span: 12 }}
                      sm={{ span: 12 }}
                      xs={{ span: 24 }}
                      style={{ marginTop: "10px" }}
                    >
                      <span className="title_changes">Testing Status</span>
                      <Form.Item
                        name="materialTest"
                        rules={[
                          {
                            required: false,
                            message: "Please Enter Testing!",
                          },
                        ]}
                        initialValue={recordMaterialData?.materialTest}
                        style={{ marginBottom: "10px" }}
                      >
                        <Select
                          mode="multiple"
                          size="large"
                          showSearch
                          allowClear
                          className="select_multiplequarytol"
                          placeholder="Please Select Testing Status"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          onChange={this.resetvendorValues}
                        >
                          {QuarryJsonData?.testMaterial?.map((m, i) => (
                            <Option key={i} value={m}>
                              {m}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col
                      xxl={{ span: 8 }}
                      xl={{ span: 8 }}
                      lg={{ span: 8 }}
                      md={{ span: 12 }}
                      sm={{ span: 12 }}
                      xs={{ span: 24 }}
                      style={{ marginTop: "10px" }}
                    >
                      <span className="title_changes">Washed Status</span>
                      <Form.Item
                        name="materialStatus"
                        rules={[
                          {
                            required: false,
                            message: "Please Enter washed status!",
                          },
                        ]}
                        initialValue={recordMaterialData?.materialStatus}
                        style={{ marginBottom: "10px" }}
                      >
                        <Select
                          mode="multiple"
                          size="large"
                          showSearch
                          allowClear
                          className="select_multiplequarytol"
                          placeholder="Please Select Washed Status"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          onChange={this.resetvendorValues}
                        >
                          {QuarryJsonData?.statusMaterial?.map((m, i) => (
                            <Option key={i} value={m}>
                              {m}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col
                      xxl={{ span: 8 }}
                      xl={{ span: 8 }}
                      lg={{ span: 8 }}
                      md={{ span: 12 }}
                      sm={{ span: 12 }}
                      xs={{ span: 24 }}
                      style={{ marginTop: "10px" }}
                    >
                      <span className="title_changes">Supply</span>
                      <Form.Item
                        name="materialSupply"
                        rules={[
                          {
                            required: false,
                            message: "Please Enter supply!",
                          },
                        ]}
                        initialValue={recordMaterialData?.materialSupply}
                        style={{ marginBottom: "10px" }}
                      >
                        <Select
                          size="large"
                          showSearch
                          allowClear
                          className="select_multiplequarytol"
                          mode="multiple"
                          // style={{ height: "40px" }}
                          placeholder="Please Select A Supply"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          onChange={this.resetvendorValues}
                        >
                          {QuarryJsonData?.supplyMaterial?.map((m, i) => (
                            <Option key={i} value={m}>
                              {m}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Loading enableLoading={this.state.loading} />
                    {topVendorsData?.length > 0 && (
                      <Col
                        xxl={{ span: 24 }}
                        xl={{ span: 24 }}
                        lg={{ span: 24 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                        className="tiprotitle"
                        style={{ marginBottom: "0.5vw" }}
                      >
                        {!editUpdateMaterial && (
                          <BaseTable
                            columnsData={topVendorsColumns}
                            source={topVendorsData
                              ?.map((e) => e.material)
                              .flat()}
                            total={
                              topVendorsData?.map((e) => e.material).flat()
                                ?.length
                            }
                            className="table_laptopscreen"
                            rowSelection={{
                              type:
                                // Object.values(recordMaterialData)?.length !== 0
                                "radio",
                              // : "checkbox",
                              ...rowSelection,
                            }}
                            handleTableChage={(e) =>
                              this.handleTableChage(e, "updateJobDetails")
                            }
                            sizeRange={
                              window.screen.width > 1023
                                ? JSON.parse(
                                    localStorage.getItem("tableSizeRange")
                                  )?.updateJobDetails
                                : topVendorsData?.length
                            }
                            pagination={
                              window.screen.width > 1023 ? true : false
                            }
                            rowKey={(record) => record?.materialId}
                          />
                        )}

                        {topVendorsData?.length > 0 &&
                          Object.values(recordMaterialData)?.length !== 0 && (
                            <Row gutter={[24, 0]}>
                              <Col
                                xxl={{ span: 24 }}
                                xl={{ span: 24 }}
                                lg={{ span: 24 }}
                                md={{ span: 24 }}
                                sm={{ span: 24 }}
                                xs={{ span: 24 }}
                              >
                                <div
                                  style={{
                                    borderBottom: "2px solid #9a9a9a",
                                  }}
                                >
                                  <Checkbox
                                    indeterminate={indeterminate}
                                    onChange={(e) =>
                                      this.onCheckAllChange(e, [], "all")
                                    }
                                    checked={
                                      recordMaterialData?.truckType?.length ===
                                      trucksData?.length
                                        ? true
                                        : checkAll
                                    }
                                    style={{
                                      margin: "10px 10px 0px 0px",
                                      // borderBottom: "2px solid #9a9a9a",
                                      paddingBottom: "5px",
                                    }}
                                  >
                                    {/* Edit Job */}
                                    <span className="title_changes">
                                      Requested Trucks
                                    </span>
                                  </Checkbox>

                                  <Checkbox
                                    onChange={(e) =>
                                      this.onCheckAllChange(
                                        e,
                                        [
                                          "Round Bottom",
                                          "End Dump",
                                          "Belly Dump",
                                          "SuperDump",
                                        ],
                                        "big"
                                      )
                                    }
                                    checked={
                                      recordMaterialData?.truckType?.length !==
                                        trucksData?.length &&
                                      JSON.stringify(sortedList) ===
                                        JSON.stringify(bigData)
                                        ? true
                                        : bigAll
                                    }
                                    style={{
                                      margin: "10px 10px 0px 0px",
                                      // borderBottom: "2px solid #9a9a9a",
                                      paddingBottom: "5px",
                                    }}
                                  >
                                    <span className="title_changes">
                                      Big Aggregate
                                    </span>
                                  </Checkbox>

                                  <Checkbox
                                    onChange={(e) =>
                                      this.onCheckAllChange(
                                        e,
                                        [
                                          "15+ Ton Dump Truck",
                                          "10+ Ton Dump Truck",
                                          "SuperDump",
                                        ],
                                        "small"
                                      )
                                    }
                                    checked={
                                      recordMaterialData?.truckType?.length !==
                                        trucksData?.length &&
                                      JSON.stringify(sortedList) ===
                                        JSON.stringify(smallData)
                                        ? true
                                        : smallAll
                                    }
                                    style={{
                                      margin: "10px 10px 0px 0px",
                                      // borderBottom: "2px solid #9a9a9a",
                                      paddingBottom: "5px",
                                    }}
                                  >
                                    <span className="title_changes">
                                      Small Aggregate
                                    </span>
                                  </Checkbox>
                                </div>
                                <Form.Item
                                  name="truckType"
                                  rules={[
                                    {
                                      required: false,
                                      message: "Please Select Trucks",
                                    },
                                  ]}
                                  style={{ marginBottom: "10px" }}
                                >
                                  <div className="chckbox-truck">
                                    <Checkbox.Group
                                      style={{
                                        width: "100%",
                                      }}
                                      onChange={this.onChangeTrucksType}
                                      value={
                                        materialTruckType ||
                                        recordMaterialData?.truckType
                                      }
                                    >
                                      <Row style={{ width: "100%" }}>
                                        {trucksData?.map((t) => (
                                          <Col
                                            xxl={{ span: 8 }}
                                            xl={{ span: 8 }}
                                            lg={{ span: 12 }}
                                            md={{ span: 12 }}
                                            sm={{ span: 24 }}
                                            xs={{ span: 24 }}
                                          >
                                            <Checkbox
                                              value={t.value}
                                              key={t.value}
                                            >
                                              <span
                                                style={{
                                                  fontSize: "15px",
                                                  fontWeight: "700",
                                                  color: "rgb(88,99,112)",
                                                }}
                                              >
                                                {t.value}
                                              </span>
                                            </Checkbox>
                                          </Col>
                                        ))}
                                      </Row>
                                    </Checkbox.Group>
                                  </div>
                                </Form.Item>
                              </Col>

                              <Col
                                xxl={{ span: 8 }}
                                xl={{ span: 8 }}
                                lg={{ span: 24 }}
                                md={{ span: 24 }}
                                sm={{ span: 24 }}
                                xs={{ span: 24 }}
                              >
                                <span className="title_changes">
                                  Use Markup
                                </span>
                                <Form.Item
                                  name="markUp"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please Enter Use Markup",
                                    },
                                  ]}
                                  initialValue={recordMaterialData?.useMarkUp}
                                >
                                  <InputNumber
                                    disabled={!areUnitsMatching}
                                    decimalSeparator="."
                                    precision={2}
                                    placeholder="Use Markup"
                                    addonAfter={"%"}
                                    style={{
                                      height: "40px",
                                      width: "100%",
                                    }}
                                    onChange={(e) =>
                                      this.handleMarkupChange(e, "formRef1")
                                    }
                                  />
                                </Form.Item>
                              </Col>

                              <Col
                                xxl={{ span: 8 }}
                                xl={{ span: 8 }}
                                lg={{ span: 24 }}
                                md={{ span: 24 }}
                                sm={{ span: 24 }}
                                xs={{ span: 24 }}
                              >
                                <span className="title_changes">
                                  Material Price
                                </span>
                                <Form.Item
                                  name="totalPrice"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please Enter Material Price!",
                                    },
                                  ]}
                                  initialValue={recordMaterialData?.totalPrice?.toLocaleString(
                                    2
                                  )}
                                >
                                  <InputNumber
                                    prefix={"$"}
                                    precision={2}
                                    parser={(value) =>
                                      value.replace(/\$\s?|(,*)/g, "")
                                    }
                                    decimalSeparator={"."} // value={quantity}
                                    placeholder="Please Enter Material Price"
                                    addonAfter={selectAfterMaterialPrice}
                                    style={{
                                      height: "40px",
                                      width: "100%",
                                    }}
                                    onChange={(e) => {
                                      this.formRef1.current.setFieldsValue({
                                        markUp:
                                          ((parseFloat(e) -
                                            initialMaterialCost) /
                                            initialMaterialCost) *
                                          100,
                                      });
                                      this.setState({
                                        materialPriceState: e,
                                        useMarkUp:
                                          ((parseFloat(e) -
                                            initialMaterialCost) /
                                            initialMaterialCost) *
                                          100,
                                      });
                                    }}
                                  />
                                </Form.Item>
                              </Col>

                              <Col
                                xxl={{ span: 8 }}
                                xl={{ span: 8 }}
                                lg={{ span: 24 }}
                                md={{ span: 24 }}
                                sm={{ span: 24 }}
                                xs={{ span: 24 }}
                              >
                                <span className="title_changes">
                                  Material Cost
                                </span>
                                <Form.Item
                                  name="materialCost"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please Enter Material Cost!",
                                    },
                                  ]}
                                  initialValue={initialMaterialCost?.toLocaleString(
                                    2
                                  )}
                                  style={{ marginBottom: "0px" }}
                                >
                                  <InputNumber
                                    parser={(value) =>
                                      value.replace(/\$\s?|(,*)/g, "")
                                    }
                                    className="material"
                                    prefix={"$"}
                                    precision={2}
                                    decimalSeparator={"."}
                                    placeholder="Please Enter Material Cost"
                                    addonAfter={selectAfterMaterialCost}
                                    style={{
                                      height: "40px",
                                      width: "100%",
                                    }}
                                    disabled={!this.state.materialCustomRate}
                                    readOnly={!materialCustomRate}
                                    onChange={(e) => {
                                      this.setState({
                                        materialCostPrice: e,
                                        initialMaterialCost: e,
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <div
                                  style={{
                                    marginTop: "2px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <Checkbox
                                    onChange={
                                      this.onChangeEditMaterialCustomRate
                                    }
                                  >
                                    <span>Custom Rate</span>
                                  </Checkbox>
                                </div>
                              </Col>
                              <Col
                                xxl={{ span: 8 }}
                                xl={{ span: 8 }}
                                lg={{ span: 24 }}
                                md={{ span: 24 }}
                                sm={{ span: 24 }}
                                xs={{ span: 24 }}
                              >
                                <span className="title_changes">
                                  Required Quantity
                                </span>
                                <Form.Item
                                  name="quantity"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please Enter Quantity",
                                    },
                                  ]}
                                  initialValue={recordMaterialData?.quantity}
                                >
                                  <InputNumber
                                    decimalSeparator="."
                                    precision={2}
                                    placeholder="Quantity"
                                    addonAfter={selectAfter}
                                    style={{
                                      height: "40px",
                                      width: "100%",
                                    }}
                                    onChange={(e) =>
                                      this.setState({
                                        quantity: e,
                                      })
                                    }
                                  />
                                </Form.Item>
                              </Col>

                              <Col
                                xxl={{
                                  span:
                                    showTableModal === "Trucking Job" ? 12 : 8,
                                }}
                                xl={{
                                  span:
                                    showTableModal === "Trucking Job" ? 12 : 8,
                                }}
                                lg={{
                                  span:
                                    showTableModal === "Trucking Job" ? 12 : 24,
                                }}
                                md={{ span: 24 }}
                                sm={{ span: 24 }}
                                xs={{ span: 24 }}
                              >
                                <span className="title_changes">
                                  Trucking Price
                                </span>
                                <Form.Item
                                  name="truckingPrice"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please Enter Trucking Price!",
                                    },
                                  ]}
                                  initialValue={recordMaterialData?.truckingPrice?.toLocaleString(
                                    2
                                  )}
                                  style={{ marginBottom: "0px" }}
                                >
                                  <InputNumber
                                    prefix={"$"}
                                    precision={2}
                                    decimalSeparator={"."} // value={quantity}
                                    placeholder="Please Enter Trucking Price"
                                    addonAfter={selectAfterTruckPrice}
                                    style={{
                                      height: "40px",
                                      width: "100%",
                                    }}
                                    parser={(value) =>
                                      value.replace(/\$\s?|(,*)/g, "")
                                    }
                                    onChange={(e) =>
                                      this.onChangeTruckingPrice(e, false)
                                    }
                                  />
                                </Form.Item>
                              </Col>

                              <Col
                                xxl={{ span: 8 }}
                                xl={{ span: 8 }}
                                lg={{ span: 24 }}
                                md={{ span: 24 }}
                                sm={{ span: 24 }}
                                xs={{ span: 24 }}
                              >
                                <span className="title_changes">
                                  Trucking Cost
                                </span>
                                <Form.Item
                                  name="truckingCost"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please Enter Trucking Cost!",
                                    },
                                  ]}
                                  initialValue={recordMaterialData?.truckingCost?.toLocaleString(
                                    2
                                  )}
                                  style={{ marginBottom: "0px" }}
                                >
                                  <InputNumber
                                    prefix={"$"}
                                    precision={2}
                                    parser={(value) =>
                                      value.replace(/\$\s?|(,*)/g, "")
                                    }
                                    decimalSeparator={"."} // value={quantity}
                                    placeholder="Please Enter Trucking Cost"
                                    addonAfter={selectAfterTruckCost}
                                    style={{
                                      height: "40px",
                                      width: "100%",
                                    }}
                                    disabled={!this.state.customRate}
                                    readOnly={!customRate}
                                    onChange={this.onChangeTruckingCost}
                                  />
                                </Form.Item>

                                <div
                                  style={{
                                    marginTop: "2px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <Checkbox
                                    onChange={this.onChangeTruckingCustomRate}
                                  >
                                    <span>Custom Rate</span>
                                  </Checkbox>
                                </div>
                              </Col>
                              {(truckingCostType === "Hour" ||
                                truckingPriceType === "Hour") && (
                                <Col
                                  xxl={{
                                    span: 8,
                                    offset: 8,
                                  }}
                                  xl={{
                                    span: 8,
                                    offset: 8,
                                  }}
                                  lg={{ span: 24, offset: 0 }}
                                  md={{ span: 24, offset: 0 }}
                                  sm={{ span: 24, offset: 0 }}
                                  xs={{ span: 24, offset: 0 }}
                                >
                                  {/* Update Material Data */}
                                  <span className="title_changes">
                                    Minimum Hours
                                  </span>
                                  <Form.Item
                                    name="hourlyCharge"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please Enter Minimum Hours!",
                                      },
                                    ]}
                                    initialValue={
                                      recordMaterialData?.hourlyCharge
                                    }
                                    style={{ marginBottom: "0px" }}
                                  >
                                    <InputNumber
                                      placeholder="Please Enter Minimum Hours"
                                      style={{
                                        height: "40px",
                                        width: "100%",
                                      }}
                                      addonAfter={"Hours"}
                                      onChange={this.handleMimimumHours}
                                    />
                                  </Form.Item>
                                  {exceedHourLimit && (
                                    <span style={{ color: "red" }}>
                                      {errorMessage}
                                    </span>
                                  )}
                                </Col>
                              )}
                            </Row>
                          )}
                      </Col>
                    )}
                  </>
                )}

                {showTableModal === "Trucking Job" && (
                  <>
                    <Col
                      xxl={{ span: 24 }}
                      xl={{ span: 24 }}
                      lg={{ span: 24 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <div
                        style={{
                          borderBottom: "2px solid #9a9a9a",
                        }}
                      >
                        <Checkbox
                          indeterminate={indeterminate}
                          onChange={(e) => this.onCheckAllChange(e, [], "all")}
                          checked={
                            recordMaterialData?.truckType?.length ===
                            trucksData?.length
                              ? true
                              : checkAll
                          }
                          style={{
                            margin: "10px 10px 0px 0px",
                            // borderBottom: "2px solid #9a9a9a",
                            paddingBottom: "5px",
                          }}
                        >
                          {/* Trucking Job */}
                          <span className="title_changes">
                            Requested Trucks
                          </span>
                        </Checkbox>

                        <Checkbox
                          onChange={(e) =>
                            this.onCheckAllChange(
                              e,
                              [
                                "Round Bottom",
                                "End Dump",
                                "Belly Dump",
                                "SuperDump",
                              ],
                              "big"
                            )
                          }
                          checked={
                            recordMaterialData?.truckType?.length !==
                              trucksData?.length &&
                            JSON.stringify(sortedList) ===
                              JSON.stringify(bigData)
                              ? true
                              : bigAll
                          }
                          style={{
                            margin: "10px 10px 0px 0px",
                            // borderBottom: "2px solid #9a9a9a",
                            paddingBottom: "5px",
                          }}
                        >
                          <span className="title_changes">Big Aggregate</span>
                        </Checkbox>

                        <Checkbox
                          onChange={(e) =>
                            this.onCheckAllChange(
                              e,
                              [
                                "15+ Ton Dump Truck",
                                "10+ Ton Dump Truck",
                                "SuperDump",
                              ],
                              "small"
                            )
                          }
                          checked={
                            recordMaterialData?.truckType?.length !==
                              trucksData?.length &&
                            JSON.stringify(sortedList) ===
                              JSON.stringify(smallData)
                              ? true
                              : smallAll
                          }
                          style={{
                            margin: "10px 10px 0px 0px",
                            // borderBottom: "2px solid #9a9a9a",
                            paddingBottom: "5px",
                          }}
                        >
                          <span className="title_changes">Small Aggregate</span>
                        </Checkbox>
                      </div>
                      <Form.Item
                        name="truckType"
                        rules={[
                          {
                            required: false,
                            message: "Please Select Trucks",
                          },
                        ]}
                        style={{ marginBottom: "10px" }}
                      >
                        <div className="chckbox-truck">
                          <Checkbox.Group
                            style={{
                              width: "100%",
                            }}
                            onChange={this.onChangeTrucksType}
                            value={
                              materialTruckType || recordMaterialData?.truckType
                            }
                          >
                            <Row style={{ width: "100%" }}>
                              {trucksData?.map((t) => (
                                <Col
                                  xxl={{ span: 8 }}
                                  xl={{ span: 8 }}
                                  lg={{ span: 12 }}
                                  md={{ span: 12 }}
                                  sm={{ span: 24 }}
                                  xs={{ span: 24 }}
                                >
                                  <Checkbox value={t.value} key={t.value}>
                                    <span
                                      style={{
                                        fontSize: "15px",
                                        fontWeight: "700",
                                        color: "rgb(88,99,112)",
                                      }}
                                    >
                                      {t.value}
                                    </span>
                                  </Checkbox>
                                </Col>
                              ))}
                            </Row>
                          </Checkbox.Group>
                        </div>
                      </Form.Item>
                    </Col>
                    <Col
                      xxl={{ span: 24 }}
                      xl={{ span: 24 }}
                      lg={{ span: 24 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <span className="title_changes">Pickup Location</span>
                      <Form.Item
                        name="pickup"
                        rules={[
                          {
                            required: false,
                            errorMessage: true,
                            message: "Please Enter Your Pickup Location",
                          },
                        ]}
                      >
                        <GooglePlacesAutocomplete
                          className="form-input"
                          autocompletionRequest={{
                            componentRestrictions: {
                              country: ["us"],
                            },
                          }}
                          selectProps={{
                            citySearch,
                            defaultInputValue: `${
                              recordMaterialData?.pickUpAddress
                                ? recordMaterialData?.pickUpAddress
                                : ""
                            }`,
                            placeholder: "Search Address...",
                            onChange: (address) => {
                              this.handleSelect(address, "pickup");
                              this.getAddress(address, "pickup");
                              this.setState({
                                pickUpAddress: address.label,
                              });
                            },
                          }}
                        />
                      </Form.Item>
                    </Col>
                    {showTableModal === "Trucking Job" && (
                      <Col
                        xxl={{ span: 24 }}
                        xl={{ span: 24 }}
                        lg={{ span: 24 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                      >
                        <span className="title_changes">Material Name</span>
                        <Form.Item
                          name="materialName"
                          rules={[
                            {
                              required: true,
                              message: "Please Enter Your Material Name",
                            },
                          ]}
                          initialValue={recordMaterialData?.materialName}
                        >
                          <Input
                            placeholder="Please Enter Material Name"
                            className="form-input"
                          />
                        </Form.Item>
                      </Col>
                    )}

                    {((milesData !== "" && milesData !== undefined) ||
                      recordMaterialData?.milesData !== undefined) && (
                      <Col
                        span={24}
                        className="tiprotitle"
                        style={{ marginBottom: "0.5vw" }}
                      >
                        <span className="title_changes">
                          <b
                            style={{
                              marginBottom: "10px",
                              borderBottom: "1px solid gray",
                            }}
                          >
                            Run and Rate information:
                          </b>
                          {milesData?.text !== undefined ? (
                            <div>
                              {milesData?.text + "les, " + duration?.text}
                            </div>
                          ) : (
                            recordMaterialData?.milesData?.text !==
                              undefined && (
                              <div>
                                {recordMaterialData?.milesData?.text +
                                  "les, " +
                                  recordMaterialData?.duration?.text}
                              </div>
                            )
                          )}
                        </span>
                      </Col>
                    )}
                  </>
                )}

                {showTableModal === "Trucking Job" && (
                  <>
                    <Col
                      xxl={{ span: showTableModal === "Trucking Job" ? 12 : 8 }}
                      xl={{ span: showTableModal === "Trucking Job" ? 12 : 8 }}
                      lg={{ span: showTableModal === "Trucking Job" ? 12 : 24 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <span className="title_changes">Trucking Price</span>
                      <Form.Item
                        name="truckingPrice"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Trucking Price!",
                          },
                        ]}
                        initialValue={recordMaterialData?.truckingPrice?.toLocaleString(
                          2
                        )}
                        style={{ marginBottom: "0px" }}
                      >
                        <InputNumber
                          prefix={"$"}
                          precision={2}
                          decimalSeparator={"."} // value={quantity}
                          placeholder="Please Enter Trucking Price"
                          addonAfter={selectAfterTruckPrice}
                          style={{
                            height: "40px",
                            width: "100%",
                          }}
                          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                          onChange={(e) => this.onChangeTruckingPrice(e, false)}
                        />
                      </Form.Item>
                    </Col>

                    <Col
                      xxl={{ span: showTableModal === "Trucking Job" ? 12 : 8 }}
                      xl={{ span: showTableModal === "Trucking Job" ? 12 : 8 }}
                      lg={{ span: showTableModal === "Trucking Job" ? 12 : 24 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <span className="title_changes">Trucking Cost</span>
                      <Form.Item
                        name="truckingCost"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Trucking Cost!",
                          },
                        ]}
                        initialValue={recordMaterialData?.truckingCost?.toLocaleString(
                          2
                        )}
                        style={{ marginBottom: "0px" }}
                      >
                        <InputNumber
                          prefix={"$"}
                          precision={2}
                          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                          decimalSeparator={"."} // value={quantity}
                          placeholder="Please Enter Trucking Cost"
                          addonAfter={selectAfterTruckCost}
                          style={{
                            height: "40px",
                            width: "100%",
                          }}
                          disabled={!this.state.customRate}
                          readOnly={!customRate}
                          onChange={this.onChangeTruckingCost}
                        />
                      </Form.Item>

                      <div style={{ marginTop: "2px", marginBottom: "5px" }}>
                        <Checkbox onChange={this.onChangeTruckingCustomRate}>
                          <span>Custom Rate</span>
                        </Checkbox>
                      </div>
                    </Col>
                    {(truckingCostType === "Hour" ||
                      truckingPriceType === "Hour") && (
                      <Col
                        xxl={{
                          span: showTableModal === "Trucking Job" ? 24 : 8,
                        }}
                        xl={{
                          span: showTableModal === "Trucking Job" ? 24 : 8,
                        }}
                        lg={{
                          span: showTableModal === "Trucking Job" ? 24 : 24,
                        }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                      >
                        <span className="title_changes">Minimum Hours</span>
                        <Form.Item
                          name="hourlyCharge"
                          rules={[
                            {
                              required: true,
                              message: "Please Enter Minimum Hours!",
                            },
                          ]}
                          initialValue={recordMaterialData?.hourlyCharge}
                          style={{ marginBottom: "0px" }}
                        >
                          <InputNumber
                            placeholder="Please Enter Minimum Hours"
                            style={{
                              height: "40px",
                              width: "100%",
                            }}
                            addonAfter={"Hours"}
                            onChange={this.handleMimimumHours}
                          />
                        </Form.Item>
                        {exceedHourLimit && (
                          <span style={{ color: "red" }}>{errorMessage}</span>
                        )}
                      </Col>
                    )}
                  </>
                )}

                {showTableModal === "Trucking & Material Job" &&
                  topVendorsData?.length > 0 &&
                  Object.values(recordMaterialData)?.length !== 0 && (
                    <>
                      <Col
                        xxl={{ span: 8 }}
                        xl={{ span: 8 }}
                        lg={{ span: 24 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                      ></Col>
                      {this.showBottomValues()}
                    </>
                  )}
              </Row>
            </Form>
          </BaseModal>
        )}

        {showMaterialValues && (
          <BaseModal
            title={`${vendorDetails.quarryName}`}
            visible={showMaterialValues}
            onCancel={() => {
              this.setState({
                customRate: false,
                truckingPriceType: "Ton",
                truckingCostType: "Ton",
                totalPriceType: "Ton",
                materialCostType: "Ton",
                materialCustomRate: false,
                showMaterialValues: false,
                selectedRowKeys: selectedRowKeys.slice(
                  0,
                  selectedRowKeys?.length - 1
                ),
                bigAll: false,
                smallAll: false,
                checkAll: false,
                materialTruckType: [],
              });
              this.formRef.current.setFieldsValue({
                truckType: [],
              });
            }}
            loading={loading}
            disabled={imageLoading ? true : false}
            formId="addMaterial"
            width={
              window.screen.width > "501" && window.screen.width <= "991"
                ? "80%"
                : "60%"
            }
            className="antmodal_grid headermodal"
          >
            <Form
              onFinish={this.addMaterialValues}
              id="addMaterial"
              ref={this.formRef}
            >
              <Row gutter={[24, 0]}>
                <Col
                  xxl={{ span: 24 }}
                  xl={{ span: 24 }}
                  lg={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <div
                    style={{
                      borderBottom: "2px solid #9a9a9a",
                    }}
                  >
                    <Checkbox
                      indeterminate={indeterminate}
                      onChange={(e) => this.onCheckAllChange(e, [], "all")}
                      checked={
                        leadDetails?.truckType?.length === trucksData?.length
                          ? true
                          : checkAll
                      }
                      style={{
                        margin: "10px 10px 0px 0px",
                        paddingBottom: "5px",
                      }}
                    >
                      {/* Add Material */}
                      <span className="title_changes">Requested Trucks</span>
                    </Checkbox>

                    <Checkbox
                      onChange={(e) =>
                        this.onCheckAllChange(
                          e,
                          [
                            "Round Bottom",
                            "End Dump",
                            "Belly Dump",
                            "SuperDump",
                          ],
                          "big"
                        )
                      }
                      checked={
                        recordMaterialData?.truckType?.length !==
                          trucksData?.length &&
                        JSON.stringify(sortedList) === JSON.stringify(bigData)
                          ? true
                          : bigAll
                      }
                      style={{
                        margin: "10px 10px 0px 0px",
                        // borderBottom: "2px solid #9a9a9a",
                        paddingBottom: "5px",
                      }}
                    >
                      <span className="title_changes">Big Aggregate</span>
                    </Checkbox>

                    <Checkbox
                      onChange={(e) =>
                        this.onCheckAllChange(
                          e,
                          [
                            "15+ Ton Dump Truck",
                            "10+ Ton Dump Truck",
                            "SuperDump",
                          ],
                          "small"
                        )
                      }
                      checked={
                        recordMaterialData?.truckType?.length !==
                          trucksData?.length &&
                        JSON.stringify(sortedList) === JSON.stringify(smallData)
                          ? true
                          : smallAll
                      }
                      style={{
                        margin: "10px 10px 0px 0px",
                        // borderBottom: "2px solid #9a9a9a",
                        paddingBottom: "5px",
                      }}
                    >
                      <span className="title_changes">Small Aggregate</span>
                    </Checkbox>
                  </div>
                  <Form.Item
                    name="truckType"
                    rules={[
                      {
                        required: false,
                        message: "Please Select Trucks",
                      },
                    ]}
                    style={{ marginBottom: "10px" }}
                    initialValue={
                      materialTruckType || recordMaterialData?.truckType
                    }
                  >
                    <div className="chckbox-truck">
                      <Checkbox.Group
                        style={{
                          width: "100%",
                        }}
                        onChange={this.onChangeTrucksType}
                        value={
                          materialTruckType || recordMaterialData?.truckType
                        }
                      >
                        <Row style={{ width: "100%" }}>
                          {trucksData?.map((t) => (
                            <Col
                              xxl={{ span: 8 }}
                              xl={{ span: 8 }}
                              lg={{ span: 12 }}
                              md={{ span: 12 }}
                              sm={{ span: 24 }}
                              xs={{ span: 24 }}
                            >
                              <Checkbox value={t.value} key={t.value}>
                                <span
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "700",
                                    color: "rgb(88,99,112)",
                                  }}
                                >
                                  {t.value}
                                </span>
                              </Checkbox>
                            </Col>
                          ))}
                        </Row>
                      </Checkbox.Group>
                    </div>
                  </Form.Item>
                </Col>
                <Col
                  xxl={{ span: 8 }}
                  xl={{ span: 8 }}
                  lg={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span className="title_changes">Use Markup</span>
                  <Form.Item
                    name="markUp"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Use Markup",
                      },
                    ]}
                    initialValue={recordMaterialData?.useMarkUp}
                  >
                    <InputNumber
                      disabled={!areUnitsMatching}
                      decimalSeparator="."
                      precision={2}
                      placeholder="Use Markup"
                      addonAfter={"%"}
                      style={{
                        height: "40px",
                        width: "100%",
                      }}
                      onChange={(e) => this.handleMarkupChange(e, "formRef")}
                    />
                  </Form.Item>
                </Col>

                <Col
                  xxl={{ span: 8 }}
                  xl={{ span: 8 }}
                  lg={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span className="title_changes">Material Price</span>
                  <Form.Item
                    name="totalPrice"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Material Price!",
                      },
                    ]}
                    initialValue={recordMaterialData?.totalPrice?.toLocaleString(
                      2
                    )}
                  >
                    <InputNumber
                      prefix={"$"}
                      precision={2}
                      parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                      decimalSeparator={"."} // value={quantity}
                      placeholder="Please Enter Material Price"
                      addonAfter={selectAfterMaterialPrice}
                      style={{
                        height: "40px",
                        width: "100%",
                      }}
                      onChange={(e) => {
                        this.formRef.current.setFieldsValue({
                          markUp:
                            ((parseFloat(e) - initialMaterialCost) /
                              initialMaterialCost) *
                            100,
                        });
                        this.setState({
                          materialPriceState: e,
                          useMarkUp:
                            ((parseFloat(e) -
                              initialMaterialCost) /
                              initialMaterialCost) *
                            100,
                        });
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col
                  xxl={{ span: 8 }}
                  xl={{ span: 8 }}
                  lg={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span className="title_changes">Material Cost</span>
                  <Form.Item
                    name="materialCost"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Material Cost!",
                      },
                    ]}
                    initialValue={initialMaterialCost?.toLocaleString(2)}
                    style={{ marginBottom: "0px" }}
                  >
                    <InputNumber
                      parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                      className="material"
                      prefix={"$"}
                      precision={2}
                      decimalSeparator={"."}
                      placeholder="Please Enter Material Cost"
                      addonAfter={selectAfterMaterialCost}
                      style={{
                        height: "40px",
                        width: "100%",
                      }}
                      disabled={!this.state.materialCustomRate}
                      readOnly={!materialCustomRate}
                      onChange={(e) => {
                        this.setState({
                          materialCostPrice: e,
                          initialMaterialCost: e,
                        });
                      }}
                    />
                  </Form.Item>
                  <div
                    style={{
                      marginTop: "2px",
                      marginBottom: "5px",
                    }}
                  >
                    <Checkbox onChange={this.onChangeMaterialCustomRate}>
                      <span>Custom Rate</span>
                    </Checkbox>
                  </div>
                </Col>

                <Col
                  xxl={{ span: 8 }}
                  xl={{ span: 8 }}
                  lg={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span className="title_changes">Required Quantity</span>
                  <Form.Item
                    name="quantity"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Quantity",
                      },
                    ]}
                    initialValue={recordMaterialData?.quantity}
                  >
                    <InputNumber
                      decimalSeparator="."
                      precision={2}
                      placeholder="Quantity"
                      addonAfter={selectAfter}
                      style={{
                        height: "40px",
                        width: "100%",
                      }}
                      onChange={(e) =>
                        this.setState({
                          quantity: e,
                        })
                      }
                    />
                  </Form.Item>
                </Col>

                <Col
                  xxl={{ span: showTableModal === "Trucking Job" ? 12 : 8 }}
                  xl={{ span: showTableModal === "Trucking Job" ? 12 : 8 }}
                  lg={{ span: showTableModal === "Trucking Job" ? 12 : 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span className="title_changes">Trucking Price</span>
                  <Form.Item
                    name="truckingPrice"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Trucking Price!",
                      },
                    ]}
                    initialValue={recordMaterialData?.truckingPrice?.toLocaleString(
                      2
                    )}
                    style={{ marginBottom: "0px" }}
                  >
                    <InputNumber
                      prefix={"$"}
                      precision={2}
                      decimalSeparator={"."} // value={quantity}
                      placeholder="Please Enter Trucking Price"
                      addonAfter={selectAfterTruckPrice}
                      style={{
                        height: "40px",
                        width: "100%",
                      }}
                      parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                      onChange={(e) => this.onChangeTruckingPrice(e, true)}
                    />
                  </Form.Item>
                </Col>

                <Col
                  xxl={{ span: showTableModal === "Trucking Job" ? 12 : 8 }}
                  xl={{ span: showTableModal === "Trucking Job" ? 12 : 8 }}
                  lg={{ span: showTableModal === "Trucking Job" ? 12 : 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span className="title_changes">Trucking Cost</span>
                  <Form.Item
                    name="truckingCost"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Trucking Cost!",
                      },
                    ]}
                    initialValue={recordMaterialData?.truckingCost?.toLocaleString(
                      2
                    )}
                    style={{ marginBottom: "0px" }}
                  >
                    <InputNumber
                      prefix={"$"}
                      precision={2}
                      parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                      decimalSeparator={"."} // value={quantity}
                      placeholder="Please Enter Trucking Cost"
                      addonAfter={selectAfterTruckCost}
                      style={{
                        height: "40px",
                        width: "100%",
                      }}
                      readOnly={!customRate}
                      disabled={!this.state.customRate}
                      onChange={this.onChangeTruckingCost}
                    />
                  </Form.Item>

                  <div style={{ marginTop: "2px", marginBottom: "5px" }}>
                    <Checkbox onChange={this.onChangeTruckingCustomRate}>
                      <span>Custom Rate</span>
                    </Checkbox>
                  </div>
                </Col>
                {materialTax !== "Natural" &&
                  materialTax !== "Manufactured" && (
                    <Col
                      xxl={{ span: 8 }}
                      xl={{ span: 8 }}
                      lg={{ span: 24 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <span className="title_changes">Material Tax</span>
                      <Form.Item
                        name="materialTax"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Your Material Tax!",
                          },
                        ]}
                        initialValue={
                          materialTax !== "Unknown" ? materialTax : undefined
                        }
                        style={{ marginBottom: "10px" }}
                      >
                        <Select
                          size="large"
                          showSearch
                          allowClear
                          className="select_Height"
                          placeholder="Please Select A Material"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          onChange={this.handleSelectChange}
                        >
                          <Option value="Natural">Natural</Option>
                          <Option value="Manufactured">Manufactured</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  )}
                {(truckingCostType === "Hour" ||
                  truckingPriceType === "Hour") && (
                  <Col
                    xxl={{
                      span: 8,
                      offset:
                        materialTax !== "Natural" &&
                        materialTax !== "Manufactured"
                          ? 0
                          : 8,
                    }}
                    xl={{
                      span: 8,
                      offset:
                        materialTax !== "Natural" &&
                        materialTax !== "Manufactured"
                          ? 0
                          : 8,
                    }}
                    lg={{ span: 24, offset: 0 }}
                    md={{ span: 24, offset: 0 }}
                    sm={{ span: 24, offset: 0 }}
                    xs={{ span: 24, offset: 0 }}
                  >
                    <span className="title_changes">Minimum Hours</span>
                    <Form.Item
                      name="hourlyCharge"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Minimum Hours!",
                        },
                      ]}
                      initialValue={recordMaterialData?.hourlyCharge}
                      style={{ marginBottom: "0px" }}
                    >
                      <InputNumber
                        placeholder="Please Enter Minimum Hours"
                        style={{
                          height: "40px",
                          width: "100%",
                        }}
                        addonAfter={"Hours"}
                        onChange={this.handleMimimumHours}
                      />
                    </Form.Item>
                    {exceedHourLimit && (
                      <span style={{ color: "red" }}>{errorMessage}</span>
                    )}
                  </Col>
                )}
                {this.showBottomValues()}
              </Row>
            </Form>
          </BaseModal>
        )}

        {isSheetModalVisible && (
          <BaseModal
            title="Project Information Sheet"
            visible={isSheetModalVisible}
            onCancel={() => {
              this.setState({
                isSheetModalVisible: false,
                projectAttachment: "",
              });
              this.exemptRef.current.setFieldsValue({
                projectInformationSheet: "Not Received",
              });
            }}
            loading={loading}
            disabled={imageLoading ? true : false}
            formId="addDocumentSheet"
            width={
              window.screen.width > "501" && window.screen.width <= "991"
                ? "80%"
                : "520px"
            }
            className="antmodal_grid headermodal"
          >
            <Form onFinish={this.onFinishSheet} id="addDocumentSheet">
              <Row gutter={[32, 0]}>
                <Col span={24}>
                  <Form.Item
                    name="projectSheet"
                    rules={[
                      {
                        required: false,
                        message: "Please Attach Your Project Sheet",
                      },
                    ]}
                  >
                    <Dragger
                      onChange={this.handleUploadPic}
                      customRequest={dummyRequest}
                      listType="picture"
                      multiple={false}
                      className="drag-ticket"
                      maxCount={1}
                    >
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-hint">
                        Click or drag file to this area to upload
                      </p>
                    </Dragger>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </BaseModal>
        )}

        {exemptVisible && (
          <BaseModal
            title="Tax Exemption Certificate"
            visible={exemptVisible}
            onCancel={() => {
              this.setState({
                exemptVisible: false,
                projectAttachment: "",
              });
              this.exemptRef.current.setFieldsValue({
                taxExempt: "Not Exempt",
              });
            }}
            loading={loading}
            disabled={imageLoading ? true : false}
            formId="addDocumentSheet"
            width={
              window.screen.width > "501" && window.screen.width <= "991"
                ? "80%"
                : "520px"
            }
            className="antmodal_grid headermodal"
          >
            <Form onFinish={this.onFinishExempt} id="addDocumentSheet">
              <Row gutter={[32, 0]}>
                <Col span={24}>
                  {/* <span className="title_changes">
                    Tax Exemption Certificate
                  </span> */}
                  <Form.Item
                    name="taxExemptCertificate"
                    rules={[
                      {
                        required: false,
                        message: "Please Attach Tax Exemption Certificate",
                      },
                    ]}
                  >
                    <Dragger
                      onChange={this.handleUploadPic}
                      customRequest={dummyRequest}
                      listType="picture"
                      multiple={false}
                      className="drag-ticket"
                      maxCount={1}
                    >
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-hint">
                        Click or drag file to this area to upload
                      </p>
                    </Dragger>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </BaseModal>
        )}

        {isContactVisible && (
          <BaseModal
            title={"Add Contact"}
            onCancel={() => {
              this.setState({
                isContactVisible: false,
                isCheckBox: false,
                newRole: "",
                roleCheckBox: false,
              });
            }}
            loading={loading}
            formId="contact"
            width={
              window.screen.width > "501" && window.screen.width <= "991"
                ? "80%"
                : "520px"
            }
            className="antmodal_grid headermodal"
          >
            <Form
              style={{ width: "100%" }}
              onFinish={this.onFinishContact}
              id="contact"
            >
              <Row gutter={[12, 0]}>
                <Col span={12}>
                  <span className="title_changes">First Name</span>
                  <Form.Item
                    name="fName"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Your First Name!",
                      },
                    ]}
                  >
                    <Input
                      onChange={(e) =>
                        this.setState({ firstName: e.target.value })
                      }
                      className="form-input"
                      placeholder="Please Enter Your First Name"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <span className="title_changes">Last Name</span>
                  <Form.Item
                    name="lName"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter your Last Name!",
                      },
                    ]}
                  >
                    <Input
                      onChange={(e) =>
                        this.setState({ lastName: e.target.value })
                      }
                      className="form-input"
                      placeholder="Please Enter your Last Name"
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <span className="title_changes">Phone</span>
                  <Form.Item
                    name="phone"
                    rules={[
                      {
                        required: false,
                        message: "Please Enter Your Phone!",
                      },
                    ]}
                  >
                    <NumberFormat
                      onChange={(e) => this.setState({ phone: e.target.value })}
                      className="ant-input-number numberictest"
                      format="(###) ###-####"
                      style={{ width: "100%", height: "40px" }}
                      placeholder="Please Enter Your Phone"
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <span className="title_changes">Email</span>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: false,
                        message: "Please Enter Your Email!",
                      },
                      {
                        type: "email",
                        message: "The Input Is Not Valid Email",
                      },
                    ]}
                  >
                    <Input
                      onChange={(e) => this.setState({ email: e.target.value })}
                      className="form-input"
                      placeholder="Please Enter Your Email"
                    />
                  </Form.Item>
                </Col>
                <Col
                  xxl={{
                    span: 24,
                  }}
                  xl={{
                    span: 24,
                  }}
                  lg={{
                    span: 24,
                  }}
                  md={{
                    span: 24,
                  }}
                  sm={{
                    span: 24,
                  }}
                  xs={{
                    span: 24,
                  }}
                >
                  <span className="title_changes">Role</span>
                  <Form.Item
                    name="role"
                    rules={[
                      {
                        required: false,
                        message: "Please Enter Your Role!",
                      },
                    ]}
                  >
                    <Select
                      size="large"
                      showSearch
                      allowClear
                      className="select_Height"
                      placeholder="Please Select A Role"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onChange={(value) => {
                        this.setState({ newRole: value });
                        value === "Others" && message.info("Please Enter Role");
                      }}
                    >
                      {rolesData?.map((role) => (
                        <Option key={role.id} value={role.label}>
                          {role.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                {newRole === "Add New Role" && (
                  <>
                    <Col span={24}>
                      <span className="title_changes">Add New Role</span>
                      <Form.Item
                        name="roleOther"
                        rules={[
                          {
                            required: false,
                            message: "Please Enter New Role!",
                          },
                        ]}
                      >
                        <Input
                          onChange={(e) =>
                            this.setState({ roleOther: e?.target?.value })
                          }
                          className="form-input"
                          placeholder="Add New Role"
                        />
                      </Form.Item>

                      <Checkbox
                        checked={this.state.roleCheckBox}
                        onChange={(e) =>
                          this.setState({
                            roleCheckBox: e.target.checked,
                          })
                        }
                        style={{ marginBottom: "18px" }}
                      >
                        <span
                          className="checkBox_size"
                          style={{ fontSize: "14px" }}
                        >
                          Add this new role to default list
                        </span>
                      </Checkbox>
                    </Col>
                  </>
                )}
                <Col span={24}>
                  <span className="title_changes">Notes</span>
                  <Form.Item
                    name="notes"
                    rules={[
                      {
                        required: false,
                        message: "Please Enter Your Notes!",
                      },
                    ]}
                  >
                    <TextArea
                      rows={4}
                      style={{
                        width: "100%",
                        height: "75px",
                        borderColor: "#d6d6d6",
                      }}
                      className="form-input"
                      placeholder="Please Enter Your Notes"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </BaseModal>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  totalResult: state.reducer?.leadData,
  errorLoading: state?.reducer?.leadData?.error,
  loading: state?.reducer?.leadData?.loading,
  leadUpdateInfo: state?.reducer?.leadUpdateInfo,
  contactsInfo: state.reducer?.contactsInfo,
  contactData: state.reducer?.contactData,
  companyResult: state?.reducer?.companyResult,
  docResult: state.reducer?.docResult,
  documentsData: state.reducer?.docsData,
  notesResult: state.reducer?.notesResult,
  notesInfo: state.reducer?.notesInfo,
  rolesData: state.reducer?.dropDownOptions?.totalData?.filter(
    (t) => t.typeofDropDown === "roles"
  ),
  trucksData: state.reducer?.dropDownOptions?.totalData?.filter(
    (t) => t.typeofDropDown === "trucksType"
  ),
  materialList: state.reducer?.dropDownOptions?.totalData?.filter(
    (q) => q?.typeofDropDown === "material"
  ),
  dropDownOptionsInfo: state.reducer?.dropDownOptionsInfo,
  dropDownOptions: state.reducer?.dropDownOptions,
  quarryResult: state.reducer?.quarriesResult,
  topVendorsData: state.reducer.vendorsData,
});

const mapDispatchToProps = (dispatch) => ({
  actionFetchgetLead: (id) => {
    dispatch(getLead(id));
  },
  actionUpdateJob: (id, data) => {
    dispatch(updateLead(id, data));
  },
  actionAddContact: (data) => {
    dispatch(addContact(data));
  },
  actionUpdateContact: (id, data) => {
    dispatch(updateContacts(id, data));
  },
  actionDeleteContact: (id) => {
    dispatch(deleteContactandJob(id));
  },
  actionFetchCompany: (id) => {
    dispatch(getCompany(id));
  },
  actionAddDoc: (data, updateStatus) => {
    dispatch(addDocument(data, updateStatus));
  },
  actionUpdateDoc: (id, data) => {
    dispatch(updateDocument(id, data));
  },
  actiongetDocuments: (id) => {
    dispatch(getDocuments(id));
  },
  actiongetNotes: (id) => {
    dispatch(getNotes(id));
  },
  actiongetDropDownOptions: () => {
    dispatch(getDropDownOptions());
  },
  actionupdateDropDownOptions: (id, data) => {
    dispatch(updateDropDownOptions(id, data));
  },
  actionFetchQuarries: () => {
    dispatch(getQuarryLocations());
  },
  actionFetchTopVendors: (data) => {
    dispatch(getTopVendors(data));
  },
  actionFetchUpdateQuarry: (id, data) => {
    dispatch(updateQuarry(id, data));
  },
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAppContext(withRouter(UpdateJobDetails)));
